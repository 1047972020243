/* eslint-disable import/prefer-default-export */
import { apiUrl } from 'config/config';
import { DEFAULT_PAGE_LIMIT } from 'utils/constants';
import http from 'utils/http';

/**
 * send referral
 *
 * @param {Object} referral to send with the request
 *
 * @return {Promise<Object>} response.
 */
export const sendReferral = async (referral) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/referrals/send`, referral);
  return data;
};

/**
 * Service that delete comment
 *
 */
export const deleteReferralComment = async (id, creatorUser) => {
  const {
    data: { data },
  } = await http.delete(
    `${apiUrl}/referrals/comments/${id}?creatorUser=${creatorUser}`
  );

  return data;
};

/**
 * Service that update comment..
 *
 * @return {Object} object for the process.
 */
export const updateReferralComments = async (body, creatorUser) => {
  const {
    data: { data },
  } = await http.patch(
    `${apiUrl}/referrals/comments?creatorUser=${creatorUser}`,
    body
  );

  return data;
};

/**
 * get  Transaction Comment by Id.
 *
 * @returns {Array} array of referrals
 */
export const getReferralCommentById = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/referrals/comments/get/${id}`);

  return data;
};

/**
 * Add referral
 *
 * @param {Object} referral to add new referral
 *
 * @return {Promise<Object>} response.
 */
export const addReferral = async (referral) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/referrals/`, referral);
  return data;
};

/**
 * Edit referral
 *
 * @param {Object} referral to edit referral
 *
 * @return {Promise<Object>} response.
 */
export const editReferral = async (referral) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/referrals/${referral.id}`, referral);
  return data;
};

/**
 * Service that get received referral by name.
 *
 * @param {String} name referral name.
 * @param {Number} {page} page number.
 * @param {Number} {limit} limit number.
 * @param {boolean} preapproved referral attachment check.
 *
 * @return {Object[]} Array of referrals.
 */
export const getAllReceivedReferrals = async (
  page = 1,
  limit = 9,
  preapproved = false,
  name = ''
) => {
  const {
    data: { data, count },
  } = await http.get(
    `${apiUrl}/referrals/received?name=${name}&page=${page}&limit=${limit}&&preapproved=${preapproved}`
  );
  return { count, data };
};

/**
 * Service that get sent referral by name.
 *
 * @param {String} name referral name.
 * @param {Number} {page} page number.
 * @param {Number} {limit} limit number.
 * @param {boolean} preapproved referral attachment check.
 *
 * @return {Object[]} Array of referrals.
 */
export const getAllSentReferrals = async (
  page = 1,
  limit = 9,
  preapproved = false,
  name = ''
) => {
  const {
    data: { data, count },
  } = await http.get(
    `${apiUrl}/referrals/sent?name=${name}&page=${page}&limit=${limit}&&preapproved=${preapproved}`
  );
  return { count, data };
};

/**
 * Service that add comment on referrals processes
 */
export const addReferralsComment = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/referrals/add/comments`, body);

  return data;
};

/**
 * get all user referral
 * @param {Number} page page
 * @param {Number} limit limit
 * @param {Boolean} isSent is sent or received
 * @param {String} referralName referral name
 *
 * @return {Promise<Array>} response.
 */
export const getPaginatedUserReferrals = async (
  page,
  limit,
  referralName,
  isSent
) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/user_referrals?page=${page || 1}&limit=${
      limit || DEFAULT_PAGE_LIMIT
    }&name=${referralName || ''}&isSent=${isSent}`
  );
  return data;
};
