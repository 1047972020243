const colors = {
  alabaster: '#fafafa',
  azure: '#0084fc',
  babyBlue: '#97D3D9',
  black: '#000000',
  blue: '#006aff',
  charcoal: '#302C2C',
  coolGrey: '#8C94AD',
  cornflowerBlue: '#3377ff',
  cyanBlue: '#4d8eff',
  darkBlue: '#0000FF',
  darkGray: '#3C454D',
  descGray: '#353842',
  dustyGray: '#979797',
  error: '#f43232',
  gray: '#6f7076',
  green: '#b2d235',
  honeydew: '#fafbfa',
  lightGray: '#ebebeb',
  lightGreen: '#B7CF53',
  lightOrange: '#FFC45B',
  lightPink: '#FFE6E6',
  liver: '#524d4d',
  matterhorn: '#545454',
  mercury: '#e4e4e4',
  midGray: '#d8d8d8',
  mineShaft: '#222222',
  orange: '#fe5d26',
  osloGray: '#8c8b8b',
  paleGrey: '#333333',
  red: '#ff0000',
  skyBlue: '#A6DAE8',
  sonicSilver: '#757575',
  veryLightGray: '#fbfbfb',
  veryLightGreen: '#F4F8E5',
  white: '#ffffff',
  whiteGrey: '#DFDFDF',
  whiteSand: '#f5f5f5',
  wildSand: '#f4f4f4',
};

export default colors;
