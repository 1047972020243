import { usePointsNotifications } from 'contexts/PointsNotificationContext/PointsNotificationContext';
import { useUser } from 'contexts/UserContext';
import { useState } from 'react';
import { addUserActionType } from 'services/points-service';

/**
 * use add action
 */

const useAddAction = () => {
  const [registrationPoints, setRegistrationPoints] = useState(null);
  const { isLoggedIn, userInfo, setUserInfo } = useUser();
  const { usePointsNotification, setAchievementItem } =
    usePointsNotifications();
  usePointsNotification(registrationPoints, !!registrationPoints);

  const add = async (action, option) => {
    setRegistrationPoints(null);
    if (isLoggedIn) {
      const addedUserAction = await addUserActionType({
        actionTypeName: action,
        ...option,
      });
      if (addedUserAction?.points) {
        if (userInfo)
          setUserInfo({
            ...userInfo,
            points: userInfo?.points + addedUserAction?.points,
          });
        setRegistrationPoints(addedUserAction?.points);
      }
      if (addedUserAction?.achievement) {
        setAchievementItem(addedUserAction?.achievement);
      }
    }
  };

  return [add];
};

export default useAddAction;
