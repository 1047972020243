import useOutsideClick from 'hooks/use-outside-click';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DivComponent, StyledBurger } from './hamburger-menu.styles';
import RightNav from './RightNav';

/**
 * HamburgerMenu component.
 *
 * @param {bool} open is open hamburger menu
 * @param {Function} setIsOpenUserMenu setIsOpenUserMenu  function
 * @param {Function} setOpen setOpen  function
 * @param {bool} isUserLoggedIn check if user is logged in
 *
 * @return {JSX.Element}
 */
function HamburgerMenu({ open, setOpen, setIsOpenUserMenu, isUserLoggedIn }) {
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <>
      <StyledBurger
        ref={ref}
        open={open}
        onClick={() => {
          setOpen(!open);
          setIsOpenUserMenu(false);
        }}
      >
        <DivComponent isUserLoggedIn={isUserLoggedIn} />
        <DivComponent isUserLoggedIn={isUserLoggedIn} />
        <DivComponent isUserLoggedIn={isUserLoggedIn} />
      </StyledBurger>
      <RightNav open={open} isUserLoggedIn={isUserLoggedIn} />
    </>
  );
}

HamburgerMenu.defaultProps = {
  isUserLoggedIn: false,
  open: false,
  setIsOpenUserMenu: () => {},
  setOpen: () => {},
};

HamburgerMenu.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  open: PropTypes.bool,
  setIsOpenUserMenu: PropTypes.func,
  setOpen: PropTypes.func,
};

export default HamburgerMenu;
