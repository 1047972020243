import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as house } from 'assets/equal-housing-opportunity.svg';
import colors from 'styles/colors';
import mq from 'styles/media-query';

export const SocialMediaIcons = styled.div`
  display: flex;
  background: ${colors.whiteSand};
  padding: 0.9375rem 0rem 0rem 0.9375rem;
  position: relative;

  svg {
    cursor: pointer;
    margin: 1.438rem 0.625rem 0 0;
  }

  ${mq.tablet`
      justify-content: center;
  `}
`;

export const House = styled(house)`
  width: 2.25rem;
  height: 2.25rem;
  margin-left: 0.8rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.whiteSand};
  padding: 2.25rem 1.6875rem;
  position: relative;

  ${mq.tablet`
    flex-direction:row;
    flex-wrap: wrap;
    row-gap: 1.25rem;
    justify-content: space-evenly;
    padding-bottom: 3.0625rem;
    column-gap: 3rem;
  `};
`;

export const ContactInfo = styled.div`
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  color: ${colors.green};
`;

export const NewsLetter = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
  }

  div {
    width: 99%;

    input {
      width: 60%;
    }
  }
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.87rem;
  padding-bottom: 1.8rem;
`;

export const LogoWrapper = styled.div`
  width: 7rem;

  ${mq.tablet`
      margin-top: -1rem;
  `}
`;

export const SubscribingText = styled.p`
  color: ${colors.mineShaft};
  margin-top: 1rem;
  font-size: 0.8rem;
`;

export const InfoGroupHeader = styled.div`
  color: ${colors.green};
  font-size: 1.125rem;
  font-weight: 700;
  width: max-content;

  ${mq.tablet`
    margin-bottom: 1.875rem;
  `};
`;

export const InfoGroupItem = styled(Link)`
  font-size: 0.875rem;
  color: ${colors.gray};
  width: max-content;
  text-decoration: underline;
  transition: color ease-in-out 150ms;

  &:hover {
    color: ${colors.green};
  }
`;

export const EmailLink = styled.a`
  font-size: 0.875rem;
  color: ${colors.gray};
  text-decoration: underline;
  transition: color ease-in-out 150ms;

  &:hover {
    color: ${colors.green};
  }
`;

export const SubscribeWrapper = styled.div`
  color: ${colors.green};
`;

export const LogoContainer = styled.div`
  svg {
    height: 5rem;
    margin: 0;
    width: 11rem;
  }
`;

export const LogoAndSocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubscribeInputWrapper = styled.div`
  margin-top: 1rem;

  input {
    &::placeholder {
      padding-left: 0.5rem;
      font-size: 0.75rem;
      font-family: inherit;
    }
  }

  ${mq.tablet`
    margin-top: 0rem;
  `};

  ${mq.desktop`
     min-width: 20rem;
  `}
`;

export const FooterWrapper = styled.div`
  background-color: ${colors.mineShaft};
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.125rem;

  p {
    font-size: 0.75rem;
  }
`;
