import { ReactComponent as RawLogo } from 'assets/header-logo-R.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock-icon.svg';
import { ReactComponent as Notification1 } from 'assets/icons/notification1.svg';
import { ReactComponent as ChevronDown } from 'assets/images/chevron-down.svg';
import { ReactComponent as Oval } from 'assets/icons/Oval.svg';
import { ReactComponent as oldLogo } from 'assets/icons/old-logo.svg';
import { Menu } from 'react-feather';
import CloseIcon from 'react-feather/dist/icons/x';
import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';
import Button from '../Button';
import { MenuLink } from '../Sidebar/sidebar.styles';

export const BroadNavContainer = styled.div`
  background-color: ${colors.mineShaft};
  color: ${colors.white};
  display: flex;
  font-size: 0.875rem;
  height: 5rem;
  padding: 0 0 0 1rem;

  ${mq.tablet`
    align-items: stretch;
    justify-content: start;
    padding: 0 1rem;
  `}
`;

export const NarrowNavContainer = styled(BroadNavContainer)`
  ${mq.tablet`
    height: 5rem;
  `}
`;

export const IconContainer = styled.div`
  display: flex;

  > svg {
    margin: auto;
  }
`;

export const Logo = styled(RawLogo)`
  cursor: pointer;
  width: 5rem;
  margin-left: 0.5rem;

  ${mq.tablet`
    margin: 0 3rem 0 3rem;
    align-self: center;
    width: 7rem;
  `}
`;

export const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;

  ${mq.desktopWide`
    position:static;

    ${Logo} {
      display: none;
    }
  `}

  ${mq.desktopWide`
    flex-grow: 0;
  `}
`;

export const LogoCenterContainer = styled.div`
  flex-grow: 0;
  display: flex;
  align-items: center;
  position: relative;

  ${mq.desktopWide`
    > svg {
      margin-left: 4.375rem;
      margin-right: 4.375rem;
    }
  `}
`;

export const NavItemsContainer = styled.div`
  display: flex;

  ${mq.desktopWide`
    flex-grow: 1;
  `}
`;

const fadeOut = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  
  to {  
    max-height: 42rem;
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    max-height: 42rem;
    opacity: 1;
  }
  
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const Nav = styled.nav`
  position: sticky;
  z-index: 19000;
  top: 0;
`;

export const NavLinksContainer = styled.div`
  display: none;
  font-size: 0.67rem;

  ${mq.desktopWide`
    align-items: center;
    display: flex;
    gap: 1.0625rem;
    justify-content: center;
    text-align: center;
    width: fit-content;
    flex-grow: 1;
  `}

  ${mq.smallLaptop`
    font-size: 0.875rem;
    gap: 1.625rem;
  `}

  ${mq.desktopExtraWide`
    gap: 2.2rem;
  `}
`;

export const DisplayMenu = styled.div`
  display: none;
`;

export const HoverOnLink = styled.div`
  &:hover {
    ${DisplayMenu} {
      display: block;
    }
  }
`;

export const HoveringWrapper = styled.div`
  padding-top: 2.2rem;
  height: 5rem;

  ${mq.smallLaptop`
    padding-top: 2.05rem;
  `}
`;

export const NavLink = styled.a`
  font-weight: 600;
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    color: ${colors.green};
    cursor: pointer;
  }

  span:hover {
    color: ${colors.green};
  }

  span {
    font-weight: 500;
  }

  ${({ isMenuOpen }) => isMenuOpen && `color: ${colors.green};`}
`;

export const ExpandedMenuItem = styled.div`
  font-weight: 600;
  margin-left: 1rem;
`;

export const UserNavRegContainer = styled.div`
  align-items: center;
  display: flex;

  ${mq.tablet`
    width: 15.625rem;
  `}
`;

export const UserNavControlContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const UserInfoWrapper = styled.div`
  display: none;

  ${mq.tablet`
    display: flex;
    height: 100%;
  `}
`;

export const MenuIcon = styled(Menu)`
  color: ${colors.white};
  height: 2rem;
  width: 2rem;

  ${mq.tablet`
    display: none
  `}
`;

export const CloseMenuIcon = styled(CloseIcon)`
  color: ${colors.white};
  height: 2rem;
  width: 2rem;

  ${mq.tablet`
    display: none
  `}
`;

export const NavControlSection = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 0.2rem;
  max-width: 20rem;
  padding: 0 1.43rem;
`;

export const UserControlSectionWrapper = styled(NavControlSection)`
  cursor: pointer;
  gap: 0.7rem;

  span {
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
    margin-right: 0.375rem;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  max-width: 10rem;
  gap: 0.1rem;
`;

export const UserNameAndPoints = styled.div`
  flex-direction: column;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  margin-right: 0.375rem;
  max-width: 7rem;
`;

export const DownArrow = styled(ChevronDown)`
  margin-top: 0.1rem;
`;

export const OldLogo = styled(oldLogo)`
  width: 1.75rem;
`;

export const UserPoints = styled.p`
  color: ${colors.green};
  font-size: 0.75rem;
  padding-top: 0.3rem;
  margin: 0;
`;

export const UserPointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
`;

export const SignInButton = styled(Button)`
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 0.5rem;
  background-color: transparent;

  ${mq.tablet`
  min-width: 6.5625rem;
  `}
`;

export const SignUpButton = styled(Button)`
  display: block;
  border: 0.06rem solid ${colors.green};
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0 0.8rem;
  &:hover {
    background-color: ${colors.mineShaft};
    color: ${colors.green};
    border: 0.06rem solid ${colors.green};
  }
  font-weight: 600;

  ${mq.tablet`
    font-size: 0.9rem;
    padding: 0 1.69rem;

`}
`;

export const StyledInputGroup = styled.div`
  align-items: center;
  border-radius: 2.1875rem;
  box-shadow: 0rem 0.06rem 0.18rem ${colors.mineShaft}29;
  display: flex;
  height: 100%;

  svg {
    width: 2rem;
    height: 1.3rem;
  }
`;

export const SearchIconContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  color: ${colors.white};
  flex: 1;
  font-size: 0.9rem;
  height: 2.5rem;
  outline: none;
  font-family: inherit;

  ::placeholder {
    font-size: 1rem;
    font-family: inherit;
  }

  ${mq.desktopMax`
    font-size: 1.2rem;
  `}
`;

export const UserImage = styled.img`
  border-radius: 50%;
  height: 3.2rem;
  object-fit: ${({ defaultImage }) => (defaultImage ? 'fill' : 'cover')};
  width: 3.2rem;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: 10.75rem;
  width: 10.75rem;
  object-fit: cover;
  display: inline-block;
`;

export const ProfileImageWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.white};
  text-transform: capitalize;

  div {
    margin: 1rem;
  }

  span {
    color: ${colors.green};
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const UserName = styled.p`
  color: ${colors.white};
`;

export const UserNameContainer = styled.div`
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const MenuWrapper = styled.div`
  background: ${colors.white};
  border-radius: 0.37rem;
  box-shadow: 0 0.06rem 0.3rem ${colors.midGray};
  color: ${colors.mineShaft};
  font-size: 0.87rem;
  margin-top: 0.5rem;
  opacity: 1;
  width: 13rem;
`;

export const MenuTopWrapper = styled.div`
  padding: 0.5rem;

  svg {
    width: 1rem;
    height: 1.2rem;
    margin-right: 0.6rem;
  }
`;

export const PointsWrapper = styled.div`
  align-items: center;
  border-radius: 0rem;
  box-shadow: 0rem 0.0625rem 0.625rem 0.0625rem rgb(34 34 34 / 6%);
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.625rem;

  div {
    padding-top: 0.25rem;
    display: flex;
    row-gap: 0.4rem;
    flex-direction: column;
    align-items: center;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  span {
    color: ${colors.green};
    span {
      color: ${colors.mineShaft};
    }
  }
`;

export const MenuItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  margin: 0.5rem 0 1rem 0.5rem;

  svg path {
    fill: ${colors.mineShaft};
    ${({ isFavoriteMenuItem }) =>
      isFavoriteMenuItem && `stroke:${colors.mineShaft};`}
  }

  &:hover {
    color: ${colors.green};
    svg path {
      ${({ filled }) => filled && `fill: ${colors.green};`}
      ${({ stroke }) => stroke && `stroke: ${colors.green};`}
    }
  }
`;

export const ExpandableContainer = styled.div`
  animation: ${({ isExpanded }) => (isExpanded ? fadeOut : fadeIn)} ease-in-out;

  animation-duration: 0.4s;
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '0')};
  min-height: 12rem;
  overscroll-behavior: contain;
  width: 100%;
  background-color: ${colors.mineShaft};
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;

  ${mq.tablet`
    display:none;
  `}
`;

export const ExpandableMenuItem = styled.div`
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  margin-top: ${({ padding }) => (padding ? '0.3rem' : '0.7rem')};
  justify-content: start;
  align-items: flex-start;

  span {
    margin-left: 1rem;
  }

  a {
    font-weight: 600;
    color: ${colors.white};
    text-decoration: none;
  }

  h4 {
    color: ${colors.white};
    margin-top: 0.15rem;
    font-weight: 600;
  }

  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;

    path {
      fill: ${colors.white};
    }
  }

  svg g {
    max-width: 1.5rem;
    max-height: 1.5rem;

    path {
      fill: ${colors.white};
    }
  }

  ${({ isMyREOIcon }) =>
    isMyREOIcon &&
    `
      g {
        stroke: white;
        stroke-width: 5.5rem;
      }
   `}
`;

export const Divider = styled.hr`
  border: 0.0625rem solid ${colors.midGray};
  padding-top: 5.5rem;
  position: relative;
  bottom: 1rem;
`;

export const NotificationControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  cursor: pointer;
  border: none;
  border-left: 0.13rem ${colors.dustyGray} solid;
  position: relative;

  ${mq.tablet`
    border-right: 0.13rem ${colors.dustyGray} solid;
  `}
`;

export const MessageWrapper = styled(NotificationControlWrapper)`
  svg {
    margin-bottom: 0.4rem;
  }
`;

export const NotificationCounter = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1.5rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: ${({ notificationsCount }) =>
    notificationsCount > 99
      ? '0.3rem 0.2rem'
      : notificationsCount >= 20
      ? '0.3rem 0.3rem'
      : notificationsCount >= 10
      ? '0.35rem 0.4rem'
      : '0.25rem 0.5rem'};
  border-radius: 50%;
  background-color: ${colors.red};
  color: ${colors.white};
`;

export const NotificationWrapper = styled.div`
  background: ${colors.white};
  border-radius: 0.37rem;
  box-shadow: 0 0.06rem 0.3rem ${colors.midGray};
  opacity: 1;
  width: 19rem;
  padding: 1.125rem 0rem;
  margin-top: 0.5rem;
  color: black;

  ${mq.tablet`
    width: 23.75rem;
  `}
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 2rem;
`;

export const NotificationTypography = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${colors.mineShaft};
  opacity: 0.5;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`;

export const MarkAsReadTypography = styled.span`
  font-weight: 400;
  font-size: 0.813rem;
  text-decoration-line: underline;
  color: ${colors.azure};
  padding-left: 2rem;
  margin: 0;
  cursor: pointer;
`;

export const NewNotification = styled(Oval)`
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.375rem;
  position: absolute;
`;

export const NotificationsImage = styled(Notification1)`
  width: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
`;

export const NotificationsBody = styled.div`
  max-height: 27.5rem;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  max-height: 5.5rem;
  padding: 0 2rem;
  background-color: ${({ unReadNotification }) =>
    unReadNotification && colors.lightGray};
`;

export const NotificationItemBody = styled.div`
  display: flex;
  margin: 0rem 0 0rem 0.8125rem;
  box-shadow: inset 0rem -0.0625rem 0rem ${colors.lightGray};
  min-height: 5.5rem;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const NotificationTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`;

export const NotificationText = styled.span`
  font-weight: 500;
  font-size: 0.8125rem;
  color: ${colors.mineShaft};
  max-width: 15.5rem;
  max-height: 2.5rem;
  min-height: 3.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.063rem;
`;

export const NotificationTime = styled.span`
  font-weight: 400;
  font-size: 0.8125rem;
  color: ${colors.mineShaft};
  opacity: 0.5;
`;

export const ExpandableMenuLink = styled(MenuLink)`
  padding: 0;
  margin-left: 2rem;
`;

export const Container = styled.div`
  padding: 0;
  margin-left: 2rem;
`;

export const WhiteLockIcon = styled(LockIcon)`
  path {
    fill: ${colors.white};
  }
  width: 2rem;
  height: 1.5rem;
  margin-left: -0.3rem;
`;

export const HiddenUnreadNotificationCount = styled.span`
  padding-left: 0.219rem;
  font-weight: 400;
  font-size: 0.813rem;
  text-decoration-line: underline;
  color: ${colors.azure};
`;

export const MessagesCounter = styled.span`
  position: relative;
  right: 0.375rem;
  font-size: 0.6rem;
  font-weight: bold;
  padding: ${({ messageCount }) =>
    messageCount > 99
      ? '0.3rem 0.2rem'
      : messageCount >= 20
      ? '0.3rem 0.3rem'
      : messageCount >= 10
      ? '0.35rem 0.4rem'
      : '0.25rem 0.5rem'};
  border-radius: 50%;
  background-color: ${colors.red};
  color: ${colors.white};
`;
