import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { removeLastSegment } from 'utils/helpers';
import {
  Container,
  Title,
  CloseBanner,
  VerifyText,
  RedeemButton,
  Fire,
} from './redemption-banner.styles';

/**
 * Verify phone wrapper component.
 *
 * @param {Array} roles array of user roles.
 * @param {number} points number of user points.
 * @param {Array} activePlans array of active plans.
 *
 * @return {JSX.Element}
 */
function RedemptionBanner({ roles, points, activePlans }) {
  const navigate = useNavigate();
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const pathName = window.location.pathname;
  const pathWithoutLastSegment = removeLastSegment(pathName);
  if (pathWithoutLastSegment === '/checkout') {
    return null;
  }

  const activeUserPlans = activePlans?.some((plan) => plan === 3 || plan === 5);

  const handleClose = () => {
    setIsBannerOpen(false);
  };

  return (
    <Container
      showBanner={
        isBannerOpen &&
        ((!activePlans?.length && points < 20000) ||
          (points >= 20000 && !activePlans?.length) ||
          (points >= 20000 && activePlans?.length > 0 && !activeUserPlans))
      }
      redeemColoredBackground={points >= 20000}
    >
      {points < 20000 && !activePlans?.length && (
        <>
          <VerifyText />
          <Title>Become a Verified Member?</Title>
          <>
            <RedeemButton
              onClick={() => {
                const isAgent = roles?.some(
                  (item) => item === 'Real Estate Agent'
                );
                let redirectUrl = '/subscriptions/lender';
                if (isAgent) {
                  redirectUrl = '/subscriptions/agent';
                }
                navigate(redirectUrl);
                handleClose();
              }}
            >
              Upgrade Now
            </RedeemButton>
            <CloseBanner
              onClick={() => {
                handleClose();
              }}
            />
          </>
        </>
      )}

      {((points >= 20000 && !activePlans?.length) ||
        (points >= 20000 && activePlans?.length > 0 && !activeUserPlans)) && (
        <>
          <Fire />
          <Title>You have accumulated 20K points!</Title>

          <RedeemButton
            redeem
            onClick={() => {
              if (roles?.some((item) => item === 'Real Estate Agent')) {
                window.location.assign('/checkout?pid=3&rid=1');
                handleClose();
              } else {
                window.location.assign('/checkout?pid=5&rid=2');
                handleClose();
              }
            }}
          >
            Redeem
          </RedeemButton>
        </>
      )}
    </Container>
  );
}

RedemptionBanner.propTypes = {
  activePlans: PropTypes.arrayOf(PropTypes.number).isRequired,
  points: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RedemptionBanner;
