// import { ReactComponent as Achievements } from 'assets/icons/achievements.svg';
// import { ReactComponent as ScheduledVisitsIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClaimAddressIcon } from 'assets/icons/claim-address.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as EventIcon } from 'assets/icons/event-icon-light.svg';
// import { ReactComponent as InvitationIcon } from 'assets/icons/InvitationIcon.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
// import { ReactComponent as Logout } from 'assets/icons/logout.svg';
// import { ReactComponent as MessagesIcon } from 'assets/icons/message.svg';
// import { ReactComponent as MyClientsIcon } from 'assets/icons/my-clients.svg';
// import { ReactComponent as OfferIcon } from 'assets/icons/OfferIcon.svg';
// import { ReactComponent as MyReferralsIcon } from 'assets/icons/refer.svg';
// import { ReactComponent as Saved } from 'assets/icons/saved.svg';
import { ReactComponent as Transaction } from 'assets/icons/transaction.svg';
import { listingPaths } from 'utils/appPaths';
import {
  // LogoutLink,
  MenuItem,
  // MessagesContainer,
  // MessagesCounter,
  // MyClientsWrapper,
  // MyMessagesWrapper,
  MyREOWrapper,
} from 'components/shared/Sidebar/sidebar.styles';
import { useIsAgent } from 'contexts/IsAgentContext';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  //   isBuyerSeller,
  isCreditSpecialistOrFinacialAdvisor,
} from 'utils/helpers';
import {
  LinkTitle,
  MenuLink,
  Ul,
  TopMenu,
  BottomMenu,
  LineSeparator,
  DownArrow,
  UpArrow,
} from './hamburger-menu.styles';
import { ExpandableMenuItem, NavLink } from '../Navbar/navbar.styles';

/**
 * RightNav component.
 *
 * @param {bool} open is open hamburger menu
 * @param {bool} isVerifyPhoneBanner is phone number verified
 * @param {bool} isUserLoggedIn check if user is logged in
 *
 * @return {JSX.Element}
 */
function RightNav({ open, isUserLoggedIn }) {
  const {
    userInfo,
    //  logout
  } = useUser();
  const { checkIsAgent } = useIsAgent();
  // const navigate = useNavigate();
  const [isAgent, setIsAgent] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [expandREPros, setExpandREPros] = useState(false);
  const [isSelected, setIsSelected] = useState();

  const checkAgent = async () => {
    const agent = await checkIsAgent(userInfo?.id);
    setIsAgent(agent);
  };

  useEffect(() => {
    checkAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.id]);

  return (
    <Ul open={isOpen && isSelected === false ? isOpen : open}>
      <TopMenu isUserLoggedIn={isUserLoggedIn}>
        <MenuLink to="/dashboard" needStrokeColor>
          <MenuItem type="button">
            <Dashboard />
          </MenuItem>
          <LinkTitle>My Dashboard</LinkTitle>
        </MenuLink>

        {!isCreditSpecialistOrFinacialAdvisor(userInfo?.roles) && (
          <MenuLink to="/transaction">
            <MenuItem type="button">
              <Transaction />
            </MenuItem>
            <LinkTitle>My Transactions</LinkTitle>
          </MenuLink>
        )}

        {/* <MenuLink to="/my-messages">
        <MyMessagesWrapper type="button">
          <MessagesIcon />
        </MyMessagesWrapper>
        <MessagesContainer>
          <LinkTitle>My Messages</LinkTitle>
          {unreadMessagesCount > 0 && (
            <MessagesCounter messageCount={unreadMessagesCount}>
              {unreadMessagesCount > 99 ? '+99' : unreadMessagesCount}
            </MessagesCounter>
          )}
        </MessagesContainer>
      </MenuLink>
      <MenuLink to="/my-invitations">
        <MenuItem type="button">
          <InvitationIcon />
        </MenuItem>
        <LinkTitle>My Invitations</LinkTitle>
      </MenuLink> */}

        {/* {!isCreditSpecialistOrFinacialAdvisor(userInfo?.roles) && (
        <MenuLink to="/my-offers">
          <MenuItem type="button">
            <OfferIcon />
          </MenuItem>
          <LinkTitle>My Offers</LinkTitle>
        </MenuLink>
      )}

      {!isBuyerSeller(userInfo?.roles) && (
        <MenuLink to="/my-clients">
          <MyClientsWrapper type="button">
            <MyClientsIcon />
          </MyClientsWrapper>
          <LinkTitle>My Clients</LinkTitle>
        </MenuLink>
      )} */}

        {/* <MenuLink to="/my-referals">
        <MyClientsWrapper type="button">
          <MyReferralsIcon />
        </MyClientsWrapper>
        <LinkTitle>My Referrals</LinkTitle>
      </MenuLink> */}

        {isAgent && (
          <MenuLink to="/my-listings">
            <MenuItem type="button">
              <List />
            </MenuItem>
            <LinkTitle>My Listings</LinkTitle>
          </MenuLink>
        )}

        <MenuLink to="/my-REO" needStrokeColor>
          <MyREOWrapper type="button">
            <ClaimAddressIcon />
          </MyREOWrapper>
          <LinkTitle>My REO</LinkTitle>
        </MenuLink>

        {/* <MenuLink to="/my-favorites" needStrokeColor>
        <MenuItem type="button">
          <Saved />
        </MenuItem>
        <LinkTitle>My Favorites</LinkTitle>
      </MenuLink> */}

        <MenuLink to="/my-events" needStrokeColor>
          <MenuItem type="button">
            <EventIcon />
          </MenuItem>
          <LinkTitle>My Events</LinkTitle>
        </MenuLink>
      </TopMenu>

      <BottomMenu>
        <LineSeparator isUserLoggedIn={isUserLoggedIn} />

        <MenuLink
          to="/member-directory"
          onClick={() => {
            setExpandREPros(false);
            setIsSelected(false);
            setIsOpen(false);
          }}
        >
          <LinkTitle>Member directory</LinkTitle>
        </MenuLink>

        <MenuLink
          to={listingPaths.search}
          onClick={() => {
            setExpandREPros(false);
            setIsSelected(false);
            setIsOpen(false);
          }}
        >
          <LinkTitle>Find Listing</LinkTitle>
        </MenuLink>

        <MenuLink
          to="/events"
          onClick={() => {
            setExpandREPros(false);
            setIsSelected(false);
            setIsOpen(false);
          }}
        >
          <LinkTitle>Events</LinkTitle>
        </MenuLink>
        <MenuLink
          to="/knowledge-center"
          onClick={() => {
            setExpandREPros(false);
            setIsSelected(false);
            setIsOpen(false);
          }}
        >
          <LinkTitle>Knowledge Center</LinkTitle>
        </MenuLink>

        <MenuLink
          isCollapsedSubMenu={expandREPros}
          to="#"
          onClick={() => {
            setExpandREPros(!expandREPros);
            setIsOpen(true);
            setIsSelected(false);
          }}
        >
          <LinkTitle type="button">Pros</LinkTitle>
          <DownArrow isCollapsedSubMenu={expandREPros} />
          <UpArrow isCollapsedSubMenu={expandREPros} />
        </MenuLink>

        {expandREPros === true && (
          <div>
            <MenuLink
              isCollapsed={expandREPros}
              to="/subscriptions/agent"
              onClick={() => {
                setExpandREPros(true);
                setIsOpen(false);
                setIsSelected(true);
              }}
            >
              <ExpandableMenuItem type="button">
                <h4>
                  <NavLink>
                    <span>Real Estate Agent</span>
                  </NavLink>
                </h4>
              </ExpandableMenuItem>
            </MenuLink>

            <MenuLink
              isCollapsed={expandREPros}
              to="/subscriptions/lender"
              onClick={() => {
                setExpandREPros(true);
                setIsOpen(false);
                setIsSelected(true);
              }}
            >
              <ExpandableMenuItem type="button">
                <h4>
                  <NavLink>
                    <span>Lender</span>
                  </NavLink>
                </h4>
              </ExpandableMenuItem>
            </MenuLink>
          </div>
        )}

        <MenuLink
          to="/leaderboard"
          onClick={() => {
            setExpandREPros(false);
            setIsSelected(false);
            setIsOpen(false);
          }}
        >
          <LinkTitle>Leaderboard</LinkTitle>
        </MenuLink>
      </BottomMenu>

      {/* {(isAgent || isBuyerSeller(userInfo?.roles)) && (
        <MenuLink to="/my-visits">
          <MenuItem type="button">
            <ScheduledVisitsIcon />
          </MenuItem>
          <LinkTitle>Scheduled Visits</LinkTitle>
        </MenuLink>
      )} */}

      {/* <MenuLink to="/achievements">
        <MenuItem type="button">
          <Achievements />
        </MenuItem>
        <LinkTitle>Achievements</LinkTitle>
      </MenuLink>

      <LogoutLink
        hover
        onClick={() => {
          logout();
          navigate('/');
        }}
      >
        <MenuItem type="button">
          <Logout />
        </MenuItem>
        <LinkTitle>Log Out</LinkTitle>
      </LogoutLink> */}
    </Ul>
  );
}

RightNav.defaultProps = {
  isUserLoggedIn: false,
};

RightNav.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

export default RightNav;
