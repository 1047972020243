import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  padding: 2rem 4rem;
  text-align: center;

  svg {
    width: 9.5rem;
  }

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.5rem;
  }

  h3 {
    color: ${colors.gray};
    font-weight: 400;
    font-size: 1.125rem;
    margin-bottom: 2rem;
  }
`;
