import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as RowUploadIcon } from 'assets/icons/upload-icon.svg';

export const UploadInputWrapper = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  position: relative;

  > div:first-child {
    margin-top: 1rem;
    flex: auto;
    height: auto;
    margin-bottom: 1rem;
    span {
      font-size: 0.75rem;
    }
    p {
      font-size: 0.75rem;
    }
  }

  svg:first-child {
    width: 3.5rem;
  }
`;

export const Attachment = styled.span`
  font-size: 0.93rem;
  color: ${colors.mineShaft};
  margin-bottom: 1rem;

  ${({ chatBotStyle }) => chatBotStyle && `margin-top: 0.8rem;`}
`;

export const InputInterface = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  border: 0.15rem dashed ${colors.green};
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: center;
  min-width: 100%;
  padding: 1.5rem 0;

  > span {
    font-size: 0.75rem;
    font-weight: 600;
  }
`;

export const UploadIcon = styled(RowUploadIcon)`
  width: 4.6875rem;
`;

export const BrowseSpan = styled.span`
  color: ${colors.cyanBlue};
`;
