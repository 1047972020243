import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as Instagram } from 'assets/images/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter.svg';
import useAddAction from 'hooks/use-add-action';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import addEmailToMailChimp from 'services/mail-chimp';
import { sendNotification } from 'services/notifications';
import { actionTypes, notificationTypes } from 'utils/constants';
import Button from '../Button';
import FormInput from '../FormInput';
import { Error } from '../FormInput/form-input.styles';
import newsLetterSchema from './contact-agent-schema';
import {
  EmailLink,
  FooterWrapper,
  InfoGroup,
  InfoGroupHeader,
  InfoGroupItem,
  InfoWrapper,
  NewsLetter,
  SocialMediaIcons,
  SubscribeInputWrapper,
  SubscribeWrapper,
  SubscribingText,
  House,
} from './footer.styles';
import SupportInfo from './SupportInfo';

const instagram = 'https://instagram.com/reverifi_?utm_medium=copy_link';
const linkedin = 'https://www.linkedin.com/company/reverifi/';
const twitter = 'https://twitter.com/r_e_v_e_r_i_f_i';
/**
 * Home page footer section.
 *
 * @return {JSX.Element}
 */
function Footer() {
  const [inputData, setInputData] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState('');
  const { pathname } = useLocation();
  const [add] = useAddAction();

  const {
    clearErrors,
    handleSubmit,
    register,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newsLetterSchema),
  });

  const submit = async ({ email }) => {
    setIsSubscribed(false);
    setIsEmailExist('');

    try {
      await addEmailToMailChimp({ email });
      setIsSubscribed(true);

      // send notification
      await sendNotification(notificationTypes.newsletterSubscribe, {
        content: {},
        recipientEmail: email,
      });
      add(actionTypes.newsletterSignUp);
    } catch (error) {
      setIsEmailExist('You are already subscribed to our newsletter.');
    }
  };

  useEffect(() => {
    setValue('email', inputData);
  }, [inputData, setValue]);

  const removeErrors = useCallback(() => {
    clearErrors();
    setIsSubscribed(false);
    setIsEmailExist('');
  }, [clearErrors]);

  useEffect(() => {
    resetField('email');
    removeErrors();
  }, [pathname, removeErrors, resetField]);

  return (
    <>
      <SocialMediaIcons>
        <EmailLink href={instagram} target="_blank">
          <Instagram />
        </EmailLink>
        <EmailLink href={linkedin} target="_blank">
          <Linkedin />
        </EmailLink>
        <EmailLink href={twitter} target="_blank">
          <Twitter />
        </EmailLink>
      </SocialMediaIcons>
      <InfoWrapper>
        <SupportInfo />

        <InfoGroup>
          <InfoGroupHeader>Company</InfoGroupHeader>
          <InfoGroupItem to="/FAQs">FAQs</InfoGroupItem>
          <InfoGroupItem to="/privacy-policy">Privacy Policy</InfoGroupItem>
          <InfoGroupItem to="/dashboard/how-we-work">How we work</InfoGroupItem>
          <InfoGroupItem to="/closed-deals">Closed Deals</InfoGroupItem>
        </InfoGroup>
        <InfoGroup>
          <InfoGroupHeader>Contact</InfoGroupHeader>
          <EmailLink href="mailto:support@reverifi.com">
            support@reverifi.com
          </EmailLink>
          <EmailLink href="mailto:sales@reverifi.com">
            sales@reverifi.com
          </EmailLink>
        </InfoGroup>
        <SubscribeWrapper>
          <InfoGroupHeader>Subscribe to our newsletter</InfoGroupHeader>
          <SubscribeInputWrapper>
            <form onSubmit={handleSubmit(submit)}>
              <NewsLetter>
                <FormInput
                  error={errors.email?.message}
                  name="email"
                  register={register}
                  placeholder="Enter email address"
                  onChange={(e) => {
                    setInputData(e.target.value);
                    removeErrors();
                  }}
                />
                <Button type="submit" ariaLabel="Subscribe">
                  Subscribe
                </Button>
              </NewsLetter>
              {isEmailExist && <Error>{isEmailExist}</Error>}

              {isSubscribed && (
                <SubscribingText>
                  Thanks for subscribing to our newsletter
                </SubscribingText>
              )}
            </form>
          </SubscribeInputWrapper>
        </SubscribeWrapper>
      </InfoWrapper>
      <FooterWrapper>
        <p>
          Copyright © {new Date().getFullYear()} reverifi holdings inc. All rights reserved.
        </p>
        <House />
      </FooterWrapper>
    </>
  );
}

export default Footer;
