import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as AboutIcon } from 'assets/icons/profile-about.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/profile-email.svg';
import { ReactComponent as NameIcon } from 'assets/icons/profile-name.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/profile-phone.svg';
import Button from 'components/shared/Button';
import FormInput from 'components/shared/FormInput';
import { Error } from 'components/shared/FormInput/form-input.styles';
import FormTextArea from 'components/shared/FormTextArea';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import TransactionSelectInput from 'components/shared/TransactionSelectInput';
import UploadOneFile from 'components/shared/UploadOneFile';
import { useShowToast } from 'contexts/showToastContext';
import { useUser } from 'contexts/UserContext';
import useAddAction from 'hooks/use-add-action';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addReferralMessage } from 'services/messages';
import { sendNotification } from 'services/notifications';
import { addReferral } from 'services/referrals';
import singleFileUpload from 'services/single-file-upload';
import { getUserData, getUsersWithLimit } from 'services/user';
import { actionTypes, clientsStatus, notificationTypes } from 'utils/constants';
import {
  formatPhoneNumber,
  formatUserPhoneNumber,
  handleNumberInput,
} from 'utils/helpers';
import addReferralFormSchema from 'components/my-referrals/NewReferralModal/new-referral-modal-schema';
import {
  AddButton,
  ButtonsWrapper,
  FormContainer,
  FormTitle,
  InputWrapper,
  ReferralForm,
  SearchInputWrapper,
  EmailAndPhoneWrapper,
} from './referrals.style';

/**
 * NewReferral component.
 *
 * @param {Function} setUpdatedsentReferrals updates the sent referrals list
 *
 * @return {JSX.Element}
 */
function NewReferral({
  updatedSentReferrals,
  setUpdatedSentReferrals,
  updatedReceivedReferrals,
  setUpdatedReceivedReferrals,
}) {
  const [submitError, setSubmitError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [receivedUserId, setReceivedUserId] = useState('');
  const { userInfo } = useUser();
  const { setShowToast } = useShowToast();
  const [namesList, setNamesList] = useState([]);
  const [refereeError, setRefereeError] = useState(false);
  const [receivedEmail, setReceivedEmail] = useState({});
  const [receivedPhone, setReceivedPhone] = useState();
  const [file, setFile] = useState([]);
  const [showInviteButton, setShowInviteButton] = useState(true);
  const [invited, setInvited] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(addReferralFormSchema),
  });
  const [status, setStatus] = useState(clientsStatus.guest);
  const [add] = useAddAction();

  const addComment = async (note, referralId) => {
    await addReferralMessage({
      content: note,
      referralId,
    });
  };
  const handleInputChange = async (userName) => {
    if (userName) {
      const users = await getUsersWithLimit(10, userName);
      setValue('name', userName);
      setNamesList(users);
    } else {
      setNamesList([]);
    }
  };

  const handleInputRefereeChange = async (userName) => {
    if (userName) {
      setStatus(clientsStatus.guest);
      setShowInviteButton(true);
      const users = await getUsersWithLimit(10, userName);
      setValue('name', userName);
      setNamesList(users);
    } else {
      setNamesList([]);
    }
  };
  const onSelect = async (value) => {
    if (value?.id) {
      const userData = await getUserData(value.id);
      setValue('name', userData?.user?.name);

      setReceivedEmail({
        email: userData?.user?.email,
        notifyPrimaryEmail: userData?.user?.notifyPrimaryEmail,
        notifySecondEmail: userData?.user?.notifySecondEmail,
        secondEmail: userData?.user?.secondEmail,
      });
      setReceivedPhone(userData?.user?.phone);
      setReceivedUserId(userData?.user?.id);
      setIsVerified(userData?.user?.isVerified);
    }
  };

  /**
   * Handle form submit.
   *
   * @param {Object} data data collected from the form inputs
   *
   */
  const submit = async ({
    username,
    note,
    referee,
    email,
    phone,
    isPreApproved,
  }) => {
    if (!email && !phone) {
      setRefereeError(
        'Please insert either email address or phone number to send the referral'
      );
    } else {
      setLoading(true);

      let fileUrl = '';
      if (file.length !== 0) {
        fileUrl = await singleFileUpload({ file });
      }

      const variables = {
        attachment: fileUrl,
        email,
        isPreApproved,
        name: username?.name,
        note,
        phone: phone ? `+1${formatUserPhoneNumber(phone)}` : null,
        receivedUserId,
        refereeId: status === clientsStatus.registered ? referee?.id : null,
        refereeName: referee?.id,
        referralSource: userInfo?.name,
        status,
      };

      try {
        const referralData = await addReferral(variables);
        await sendNotification(notificationTypes.receiveReferral, {
          content: {
            note,
            recipientName: username?.name,
            referee: referee?.name,
            referral: userInfo?.name,
          },
          recipientEmail: receivedEmail?.notifyPrimaryEmail
            ? receivedEmail?.email
            : undefined,
          recipientId: receivedUserId,
          recipientPhone:
            isVerified && receivedPhone ? receivedPhone : undefined,
          recipientSecondEmail: receivedEmail?.notifySecondEmail
            ? receivedEmail?.secondEmail
            : undefined,
        });

        add(actionTypes.sendReferral);

        if (invited) {
          await sendNotification(notificationTypes.clientInvitation, {
            content: {
              name: variables?.referee,
              phone,
              recipientEmail: email || undefined,
              userName: userInfo?.name,
            },
            recipientEmail: email || undefined,
            recipientPhone: `+1${formatUserPhoneNumber(phone)}`,
          });
          add(actionTypes.inviteNewUser);
        }

        setLoading(false);
        setUpdatedSentReferrals(!updatedSentReferrals);
        setUpdatedReceivedReferrals(!updatedReceivedReferrals);
        if (referralData?.note)
          addComment(referralData?.note, referralData?.id);

        setValue('referee', null);
        setValue('username', null);
        setRefereeError(false);
        setSubmitError(false);
        setShowToast({
          isActive: true,
          status: 'success',
          text: 'your referral has been sent',
        });
      } catch (err) {
        setSubmitError(err?.response?.data?.message);
      }
    }
    setInvited(false);
  };

  const handleAddFile = (handleFile) => {
    setFile(handleFile);
  };
  const onRefereeSelect = async (value) => {
    if (value?.id) {
      const userData = await getUserData(value?.id);
      setValue('name', userData?.user?.name);
      setValue('email', userData?.user?.secondEmail || userData?.user?.email);
      setValue(
        'phone',
        formatPhoneNumber(`+1${userData?.user?.phone?.slice(2)}`) || null
      );
      setStatus(clientsStatus.registered);
      setShowInviteButton(false);
    }
  };
  const handleDeleteImage = () => {
    setFile([]);
  };

  return (
    <FormContainer>
      <FormTitle>Send Referral</FormTitle>
      <ReferralForm onSubmit={handleSubmit(submit)}>
        <SearchInputWrapper>
          <TransactionSelectInput
            options={namesList}
            control={control}
            label="Refer to reverifi member"
            labelIcon={<NameIcon />}
            name="username"
            placeholder="First Name Last Name"
            handleInputChange={handleInputChange}
            onChangeValue={onSelect}
            error={errors?.username?.message}
            hideInvite
            required
          />
        </SearchInputWrapper>
        <InputWrapper>
          <TransactionSelectInput
            options={namesList}
            control={control}
            label="Name of New Referral"
            placeholder="First Name Last Name"
            labelIcon={<NameIcon />}
            name="referee"
            setValue={setValue}
            handleInputChange={handleInputRefereeChange}
            onChangeValue={onRefereeSelect}
            error={errors?.referee?.message}
            hideInvite
            required
          />
        </InputWrapper>
        <EmailAndPhoneWrapper>
          <InputWrapper>
            <FormInput
              register={register}
              error={refereeError}
              type="email"
              label="Email of Referral"
              placeholder="E-mail"
              labelIconElement={<EmailIcon />}
              disabled={status === clientsStatus.registered}
              name="email"
              onChange={() => setFocus('email')}
            />
          </InputWrapper>
          <InputWrapper>
            <FormInput
              error={refereeError ? 'required' : null}
              register={register}
              type="phone"
              label="Phone # of Referral"
              labelIconElement={<PhoneIcon />}
              placeholder="(201) 555 - 0123"
              name="phone"
              maxLength="10"
              disabled={status === clientsStatus.registered}
              onChange={() => setFocus('phone')}
              onFocus={(e) => {
                handleNumberInput(e);
              }}
              onBlur={(e) => {
                if (!e.target.value.startsWith('('))
                  setValue('phone', formatPhoneNumber(`+1${e.target.value}`));
              }}
            />
          </InputWrapper>
        </EmailAndPhoneWrapper>
        <UploadOneFile
          handleAddFile={handleAddFile}
          file={file}
          handleDeleteImage={handleDeleteImage}
          chatBotStyle
        />
        <InputWrapper>
          <FormTextArea
            rounded={false}
            register={register}
            label="Message"
            labelIconElement={<AboutIcon />}
            placeholder="Enter Text Here"
            name="note"
            onChange={() => setFocus('note')}
          />
        </InputWrapper>
        {submitError && <Error>{submitError}</Error>}

        {loading && !submitError ? (
          <LoadingSpinner loading={loading} size={50} align="center" />
        ) : (
          <ButtonsWrapper>
            <AddButton type="submit" value="add">
              Send
            </AddButton>
            {showInviteButton && (
              <Button
                type="submit"
                value="save-invite"
                onClick={() => {
                  setInvited(true);
                  setStatus('pending');
                }}
              >
                Send & Invite
              </Button>
            )}
          </ButtonsWrapper>
        )}
      </ReferralForm>
    </FormContainer>
  );
}

NewReferral.propTypes = {
  setUpdatedReceivedReferrals: PropTypes.func,
  setUpdatedSentReferrals: PropTypes.func,
  updatedReceivedReferrals: PropTypes.bool,
  updatedSentReferrals: PropTypes.bool,
};

NewReferral.defaultProps = {
  setUpdatedReceivedReferrals: () => {},
  setUpdatedSentReferrals: () => {},
  updatedReceivedReferrals: false,
  updatedSentReferrals: false,
};
export default NewReferral;
