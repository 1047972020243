import { ReactComponent as VerifyLeft } from 'assets/icons/verify-left.svg';
import { ReactComponent as VerifyRight } from 'assets/icons/verify-right.svg';
import { ReactComponent as VerifyText } from 'assets/icons/verify-text.svg';
import Button from 'components/shared/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Title } from './verify-phone-banner.style';

/**
 * Verify phone wrapper component.
 *
 * @return {JSX.Element}
 */
function VerifyPhoneBanner() {
  const navigate = useNavigate();

  return (
    <Container>
      <VerifyLeft />
      <Title>
        <VerifyText />
        Please verify your phone number.
      </Title>
      <Button
        onClick={() => {
          navigate('/verify-phone');
        }}
      >
        Verify Now
      </Button>
      <VerifyRight />
    </Container>
  );
}

export default VerifyPhoneBanner;
