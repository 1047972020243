import { useAchievementsNotifications } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import { useUser } from 'contexts/UserContext';
import React, { useEffect, useState } from 'react';
import { removeLastSegment } from 'utils/helpers';
// eslint-disable-next-line import/no-named-as-default
import AchievementsNotification from './achievements-notification';
import { Wrapper } from './styles';

export default function AchievementsNotifications() {
  const { notificationItems } = useAchievementsNotifications([]);
  const [notificationItem, setNotificationItem] = useState([]);
  const { isLoggedIn } = useUser();
  const pathName = window.location.pathname;

  const pathWithoutLastSegment = removeLastSegment(pathName);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!notificationItems) {
      setNotificationItem(null);

      setTimeout(() => {
        setNotificationItem(notificationItems[0]);
      }, 3000);
    }
  }, [notificationItems]);

  return (
    pathName !== '/my-roles' &&
    pathName !== '/verify-phone' &&
    pathWithoutLastSegment !== '/checkout' &&
    pathWithoutLastSegment !== '/subscriptions/agent' &&
    pathWithoutLastSegment !== '/subscriptions/lender' && (
      <Wrapper name={notificationItem?.name}>
        {notificationItem && isLoggedIn && (
          <AchievementsNotification
            name={notificationItem.name}
            link={notificationItem?.link}
            text={notificationItem?.text}
            points={notificationItem?.points}
            id={notificationItem?.id}
            buttonText={notificationItem?.buttonText}
            fontSize={notificationItem?.fontSize}
          />
        )}
      </Wrapper>
    )
  );
}
