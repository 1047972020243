import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  position: sticky;
  background-color: ${colors.white};
  padding: 0.5rem 0.5rem;

  ${mq.tablet`
    gap: 3rem;
  `}

  svg {
    display: none;

    ${mq.tablet`
      display: block;
  `}
  }

  button {
    padding: 0 0.5rem;
    min-width: 7.5625rem;
  }
`;

export const Title = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;

  ${mq.tablet`
    font-size: 1.4rem;
  `}
`;
