import styled from 'styled-components';
import mq from 'styles/media-query';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  position: fixed;
  z-index: 9999999;
  background-color: ${colors.alabaster};
  box-shadow: 0rem 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  justify-content: center;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'translateY(88%)' : 'translateY(0)'};
  transition: transform 0.3s ease-in-out;

  ${mq.tablet`
    right: 25rem;
    width: 22rem;
    height: auto;
    bottom: 1rem;
    border-radius: 2.25rem;
  `}
`;

export const SendForm = styled.form`
  padding: 0.2rem 1rem 1rem 1rem;
  display: flex;
  background-color: ${colors.alabaster};
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: 100%;

  textarea {
    font-size: 1rem;
    min-height: 2.5rem;
    height: 2.5rem;
    display: flex;
    border-radius: 2.25rem;
    padding-right: 2.6rem;

    ::placeholder {
      color: ${colors.dustyGray};
      font-weight: 500;
      font-size: 0.7rem;
    }
  }

  ${mq.tablet`
    border-radius: 0rem 0rem 2.25rem 2.25rem;
  `}
`;

export const ChatBotSendButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  margin-bottom: 1.1rem;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${colors.lightGreen};
  border: none;

  svg {
    height: 1rem;
    width: 1rem;
    margin-top: 0.3rem;
    margin-right: 0.1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  border-bottom: 0.06rem solid ${colors.midGray};
  padding-bottom: 1rem;
  width: 92%;
  background-color: transparent;
  min-height: 5.7rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-left: 0.7rem;
  padding-right: 0.7rem;

  ${mq.tablet`
    height: 19.8rem;
  `}
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  height: 1.25rem;
  justify-self: flex-end;
  margin-left: auto;
  width: 1.25rem;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.15rem;
  }
`;

export const StyledImg = styled.img`
  border-radius: 50%;
  object-fit: ${({ defaultImg }) => (defaultImg ? 'fit-content' : 'cover')};
  height: 2.75rem;
  width: 2.75rem;
  min-height: 2.75rem;
  min-width: 2.75rem;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Username = styled.p`
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  width: fit-content;

  ${({ isClickable }) =>
    isClickable &&
    `cursor: pointer;

    &:hover {
      color: ${colors.green};
    }
  `};
`;

export const MessageTime = styled.span`
  color: ${colors.gray};
  font-size: 0.8rem;

  ${({ alignStart }) => alignStart && `text-align: start;font-size: 0.65rem;`}
  ${({ alignStart }) => !alignStart && `text-align: end;font-size: 0.65rem;`}
`;

export const CompanyName = styled.p`
  font-size: 0.9rem;
  margin: 0;
  color: ${colors.dustyGray};
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
  word-break: break-all;
`;

export const ChatBotMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ alignStart }) => alignStart && `align-items: flex-start;`}
  ${({ alignStart }) => !alignStart && `align-items: flex-end;`}
`;

export const MessageWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  border-bottom: 0.0625rem solid ${colors.mercury};
  max-width: 12rem;
  width: fit-content;
  padding: 0.8rem;

  ${({ alignStart }) =>
    alignStart &&
    `align-items: flex-start;background: ${colors.midGray};
    border-radius: 0.94rem 0.94rem 0.94rem 0;`};
  ${({ alignStart }) =>
    !alignStart &&
    `align-items: flex-end;background: ${colors.lightGreen};color: ${colors.white};
    border-radius: 0.94rem 0.94rem 0rem 0.94rem;`};
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.8rem;
    line-height: 1.225rem;
  }
`;

export const NoMessages = styled.p`
  color: ${colors.dustyGray};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 5rem 0;
  text-align: center;
`;
