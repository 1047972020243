import moment from 'moment';
import colors from 'styles/colors';
import { allAgentRoles, OFFER_STATUS } from './constants';

/**
 * function used to format notes in a proper format to display.
 *
 * @param {String} data notes from text field.
 *
 * @returns {String} formatted notes.
 */
export const replaceNotes = (data, regex) => {
  const note = data.replace(regex, '_');
  let notes;

  if (/@_[ ]{2,}/i.test(note)) {
    notes = note
      .replace(/@_[ ]{2,}/g, '@_ ')
      .replace(/\s+/g, ' ')
      ?.trim();
  } else {
    const match = /\r|\n/.exec(note.replace(/@_/g, '@_ '));
    if (match)
      notes = match.input.replace(/@_/g, '@_ ').replaceAll('\n', ' \n ');
    else {
      notes = note.replace(/@_/g, ' @_ ');
    }
  }
  return notes;
};

/**
 * function that remove duplicates from array of objects.
 *
 * @param {Array} array original array.
 * @param {String} key key to search inside array of object.
 *
 * @returns unique data array.
 */
export const removeDuplicates = (array, key) => {
  const lookup = {};
  array.forEach((element) => {
    lookup[element[key]] = element;
  });
  return Object.keys(lookup).map((key2) => lookup[key2]);
};

/**
 * function that sort mentioned users in order of mention.
 *
 * @param {Object} users mentioned user without sort.
 *
 * @returns {Array} sortedArray sorted mentioned users.
 */
export const orderMention = (users, squareMentionRegex, state) => {
  const sortedArray = [];
  const originalText = [];

  state.match(squareMentionRegex)?.forEach((el) => {
    originalText.push(el.replace(/\[|\]/g, ''));
  });
  originalText?.forEach((el) =>
    sortedArray.push(users.find((e) => e.name === el))
  );
  return sortedArray;
};

/**
 * get dates difference function
 *
 * @param {String} date date (ex: 2022-01-24T12:47:07.098Z)
 * @param {Number} period period to compare if the dates difference exceed it
 *
 * @return {Number} difference between 2 dates
 */
export const getDatesDifference = (date, period) => {
  const cleanedDate = date?.slice(0, 10);
  const today = new Date().toISOString().slice(0, 10);

  const diffInMs = new Date(today) - new Date(cleanedDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  if (period) {
    return diffInDays < period;
  }

  return diffInDays || 1;
};

/**
 * get dates difference function
 *
 * @param {date} firstDate date (ex: 2022-01-24T12:47:07.098Z)
 * @param {date} secondDate date (ex: 2022-01-24T12:47:07.098Z)
 * @param {Number} period the period to calculate the difference
 *
 * @return {Number} will return the difference between the two dates
 */
export const getDifferenceBetweenTwoDates = (firstDate, secondDate, period) => {
  const diffInMs = new Date(secondDate) - new Date(firstDate);
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24) + 1);

  if (period) {
    return diffInDays < period;
  }

  return diffInDays;
};

/**
 * generate Label Value Pairs function
 *
 * @param {Array} data array of data
 *
 * @returns {Array} array or reshaped data
 */
export const generateLabelValuePairs = (data) =>
  data?.map((item) => ({ label: item, value: item }));

/**
 * capitalize First Letter function
 *
 * @param {String} string text to be capitalize first letter
 *
 * @return {String} capitalized string
 */
export const toUpperCaseFirstLetter = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1);

/**
 * split camel case string
 *
 * @param {String} string text with camel case
 *
 * @return {String} normal text after split
 */
export const splitCamelCaseString = (string) =>
  toUpperCaseFirstLetter(string?.replace(/([a-z])([A-Z])/g, '$1 $2'));

/**
 * format phone number
 *
 * @param {String} phoneNumber to format
 *
 * @return {String} format phone number (000) 000 - 0000
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;

  const phoneNumberString = phoneNumber?.toString().slice(2);

  if (phoneNumberString.length < 3) return null;

  let reg;
  let lastPartLength;

  if (phoneNumberString?.length > 6) {
    lastPartLength = phoneNumberString?.length - 6;
    reg = `^(\\d{3})(\\d{3})(\\d{${lastPartLength}})$`;
  } else {
    lastPartLength = phoneNumberString?.length - 3;
    reg = `^(\\d{3})(\\d{${lastPartLength}})$`;
  }

  const regex = new RegExp(reg);
  const cleaned = ` ${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(regex);

  const countryCode = match?.[1];
  const areaCode = match?.[2];
  const lineNumber = match?.[3];

  if (match && phoneNumberString?.length > 6) {
    return `(${countryCode}) ${areaCode} - ${lineNumber}`;
  }
  if (match && phoneNumberString?.length <= 6) {
    return areaCode ? `(${countryCode}) - ${areaCode}` : `(${countryCode})`;
  }

  return null;
};

/**
 * get Last Characters function
 *
 * @param {String} string text to return last characters of
 * @param {Number} numOfCharacters number of characters to return
 *
 * @return {String} capitalized string
 */
export const getLastCharacters = (string, numOfCharacters = 2) =>
  string?.slice(-numOfCharacters);

/**
 * Handle number input function prevent enter other types than numbers
 *
 * @param {object} target input target object
 *
 */
export const handleNumberInput = ({ target }) => {
  target.value = target.value.replace(
    // eslint-disable-next-line no-useless-escape
    /[-._!"`'#%&,:;<>=@{} ~\$\(\)\*\+\/\\\?\[\]\^\|a-zA-Z]/g,
    ''
  );
};

/**
 * Handle bath room number input
 *
 * @param {object} target input target object
 *
 */
export const handleBathroomNumberInput = ({ target }) => {
  // eslint-disable-next-line prefer-regex-literals
  const validNumber = new RegExp(/^\d{0,9}(\.\d{0,1})?$/);

  if (!validNumber.test(target.value)) target.value = target.value.slice(0, -1);
};

/**
 * Format user phone number function change format for phone number
 *
 * @param {String} value phone number
 *
 * @returns phone number with new format
 */
export const formatUserPhoneNumber = (value) =>
  // eslint-disable-next-line no-useless-escape
  value?.replace(/[-._!"`'#%&,:;<>=@{} ~\$\(\)\*\+\/\\\?\[\]\^\|a-zA-Z]/g, '');

/**
 * Handle text input function prevent enter other types than letters
 *
 * @param {object} target input target object
 *
 */
export const handleTextInput = ({ target }) => {
  target.value = target.value.replace(
    // eslint-disable-next-line no-useless-escape
    /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|0-9]/g,
    ''
  );
};

/**
 * separateBy function that return an array as a separated string
 *
 * @param {Array} data array of data you want to separate
 * @param {String} separator separator between data array (ex: ',' '/')
 *
 * @return {String} separated string
 */
export const separateBy = (data, separator = ' ') =>
  data?.map((item, i, array) =>
    i + 1 !== array.length ? `${item}${separator} ` : `${item}`
  );

/**
 *  get City Country Zip Code from react autocomplete
 *
 * @param {Array} results autocomplete search results
 *
 * @returns {Array} array of city, country, zipCode
 */
export const getCityCountryZipCode = (results) =>
  results
    ?.filter(
      (item) =>
        item.types.includes('postal_code') ||
        item.types.includes('administrative_area_level_1') ||
        item.types.includes('country')
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        // eslint-disable-next-line no-nested-ternary
        [item.types.includes('postal_code')
          ? 'zipCode'
          : item.types.includes('administrative_area_level_1')
          ? 'city'
          : 'country']: `${item.long_name}, ${item.short_name}`,
      }),
      {}
    );

/**
 * change date format
 *
 * @param {String} date date
 *
 * @returns {String} date in new format
 */
export const formatDate = (date) => new Date(date).toLocaleDateString();

export const formatEventDate = (eventDate, eventEndDate) =>
  `${moment(eventDate).format('dddd MMMM D, yyyy hh:mmA')}${
    eventEndDate ? `-${moment(eventEndDate).format('hh:mmA')}` : ''
  }`;

/**
 * join Date And Time function
 */
export const joinDateAndTime = (date, time) => {
  if (date && time) {
    // check if the time is iso string
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const isoDate = `${year}-${month <= 9 ? `0${month}` : month}-${
      day <= 9 ? `0${day}` : day
    }`;
    const isoTime = typeof time === 'object' ? time.toISOString() : time;
    const dateTime = new Date(`${isoDate}T${isoTime.split('T')[1]}`);

    return dateTime;
  }

  return '';
};

/**
 * get color by offer status function
 *
 * @param {String} status offer status
 *
 * @returns {String} color
 */
export const statusColor = (status) => {
  switch (status) {
    case OFFER_STATUS.accepted:
      return colors.green;
    case OFFER_STATUS.rejected:
      return colors.orange;
    default:
      return colors.dustyGray;
  }
};

/**
 * remove Spaces And Characters function
 *
 * @param {String} string string to remove spaces and characters from
 * @param {String} character specified character to remove
 *
 * @returns {String} string without spaces and specified character
 */
export const removeSpacesAndCharacters = (string, character) => {
  if (!string) return null;

  let newString = string.replaceAll(/\s/g, '');

  if (character) {
    newString = newString.replaceAll(character, '');
  }

  return newString;
};

export const fetchUserRolesAndListings = (userRoles = []) => {
  if (
    userRoles?.find(({ role }) => role?.toLowerCase() === 'real estate agent')
  ) {
    return true;
  }
  return false;
};

/**
 * check Transaction Allowed Access User function
 */
export const checkTransactionAllowedAccessUser = (id, assignees) => {
  if (
    !assignees?.filter(
      ({ userId, role, assignedTransaction }) =>
        userId === id &&
        (role?.toLowerCase()?.includes('agent') ||
          role === 'Buyer' ||
          role === 'Seller' ||
          role === 'Creator' ||
          role === 'Listing Agent' ||
          role === 'Transaction Coordinator' ||
          id === assignedTransaction?.createdBy)
    )?.length
  ) {
    return true;
  }
  return false;
};

/**
 * check Transaction Allowed to upload
 */
export const checkTransactionAllowedUpload = (id, assignees) =>
  assignees?.filter(({ userId }) => userId === id)?.length;

/**
 * add Space After Specific Character function
 *
 * @param {String} text string to add space to it
 * @param {Number} index index of the added space
 *
 * @returns text with space in index
 */
export const addSpaceAfterSpecificCharacter = (string, index) => {
  if (string) {
    return `${string?.substr(0, index)} ${string?.substr(
      index,
      string?.length
    )}`;
  }
  return null;
};

/**
 * custom select theme function to change select default colors
 *
 * @param {Object} theme theme object from the select component
 * @param {Object} error input useForm error
 * @param {Object} themeColors theme colors
 *
 */
export const customSelectTheme = (theme, themeColors, error) => ({
  ...theme,
  colors: {
    ...theme?.colors,
    dangerLight: themeColors?.dangerLight,
    primary: error ? themeColors.error : themeColors?.primary,
    primary25: themeColors?.primary25,
  },
});

/**
 * format amounts function
 *
 * @param {Number} amount the amount to format
 *
 */
export const amountFormatter = (amount) =>
  `$${new Intl.NumberFormat().format(amount)}`;

/**
 * format numbers function
 *
 * @param {Number} number the number to format
 *
 */
export const formatNumbers = (number) =>
  number?.toLocaleString({
    minimumFractionDigits: 0,
  });

/**
 * check user's role if it is Seller or Buyer
 *
 * @param {Object} userRoles user's roles
 *
 * @returns {Boolean} if the user Seller or Buyer
 */
export const isBuyerSeller = (userRoles = []) =>
  userRoles?.filter(
    ({ role }) => role !== allAgentRoles.seller && role !== allAgentRoles.buyer
  ).length === 0;

/**
 * check user's role if it is Agent or Lender
 *
 * @param {Object} userRoles user's roles
 *
 * @returns {Boolean} if the user Agent or Lender
 */
export const isAgentOrLender = (userRoles = []) =>
  userRoles?.some(
    ({ role }) =>
      role === allAgentRoles.mortgageLenderBroker ||
      role === allAgentRoles.realEstateAgent
  );

/**
 * check user's role if it is Seller or Buyer or agent
 *
 * @param {Array} userRoles user's roles
 *
 * @returns {Boolean} if the user Seller or Buyer
 */
export const isAgentOrBuyerOrSeller = (userRoles = []) =>
  userRoles?.some(
    ({ role }) =>
      role === allAgentRoles.buyer ||
      role === allAgentRoles.seller ||
      role === allAgentRoles.realEstateAgent ||
      role === allAgentRoles.mortgageLenderBroker ||
      role === allAgentRoles.transactionCoordinator
  );

/**
 * check user's role if it is Agent
 *
 * @param {Array} userRoles user's roles
 *
 * @returns {Boolean} if the user Agent
 */
export const isAgent = (userRoles = []) =>
  userRoles?.some(({ role }) => role === allAgentRoles.realEstateAgent);

/**
 * check user's role if it is Lender
 *
 * @param {Object} userRoles user's roles
 *
 * @returns {Boolean} if the user Lender
 */
export const isLender = (userRoles = []) =>
  userRoles?.some(({ role }) => role === allAgentRoles.mortgageLenderBroker);

/**
 * check user's if subscribed vip plan.
 *
 * @param {Array} activePlansId user's active plans id
 *
 * @returns {Boolean} if the user vip
 */
export const isVip = (activePlansId = []) =>
  activePlansId?.some((id) => id === 3 || id === 5 || id === 6 || id === 7);

/**
 * check user's if subscribed vip plan.
 *
 * @param {Array} activePlansId user's active plans id
 *
 * @returns {Boolean} if the user vip
 */
export const isVipByPlanIdObj = (activePlansId = []) =>
  activePlansId?.some(
    ({ planId }) => planId === 3 || planId === 5 || planId === 6 || planId === 7
  );

/**
 * check user's role if it is Credit Repair Specialist or Financial Advisor
 *
 * @param {Array} userRoles user's roles
 *
 * @returns {Boolean} if the user Credit Repair Specialist or Financial Advisor
 */
export const isCreditSpecialistOrFinacialAdvisor = (userRoles = []) =>
  userRoles?.filter(
    ({ role }) =>
      role !== allAgentRoles.creditRepairSpecialist &&
      role !== allAgentRoles.financialAdvisor &&
      role !== allAgentRoles.photographer
  ).length === 0;

/**
 * get time interval.
 *
 * @param {String} date created at date.
 */
export const getTimeInterval = (date) =>
  `${moment.duration(moment().diff(moment(Date.parse(date)))).humanize()} ago`;

/**
 * get time & date interval.
 *
 * @param {String} date created at date.
 */
export const getTimeAndDateInterval = (date) =>
  moment(date).calendar(null, {
    lastDay: '[Yesterday], hh:mm A',
    lastWeek: 'MMM DD, hh:mm A',
    sameDay: 'hh:mm A',
    sameElse() {
      if (new Date(date).getFullYear() === moment().year()) {
        return 'MMM DD, hh:mm A';
      }
      return 'MM/DD/YYYY, hh:mm A';
    },
  });

export const diffMinutes = (dt2, dt1) =>
  (dt2.getTime() - dt1.getTime()) / 1000 / 60;

/**
 * Formats an array of objects by extracting a specified property and separating them with commas and spaces.
 *
 * @param {Array<Object>} arr The input array of objects to format.
 * @param {string} propertyName The name of the property.
 * @returns {string} The formatted string with commas and spaces.
 */
export const formatArrayWithCommas = (arr, propertyName) => {
  if (arr.length === 0) {
    return '';
  }

  const formattedArray = arr.map((element) => {
    const name = element[propertyName];

    if (arr.length === 1) {
      return name;
    }
    return `${name}, `;
  });

  return formattedArray.join('');
};

/**
 * Converts a plan period string to its equivalent representation.
 *
 * @param {string} period The plan period.
 * @returns {string} The equivalent representation of the plan period.
 */
export const planPeriodString = (period) => {
  switch (period) {
    case 12:
      return 'Annual';
    case 1:
      return 'Monthly';
    default:
      return period;
  }
};

/**
 * Removes the last segment (trailing slash or part of a path) from a given path string.
 *
 * @param {string} path The input path from which to remove the last segment.
 * @returns {string} The modified path with the last segment removed.
 */
export const removeLastSegment = (path) =>
  path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path;

/**
 * Returns the time left until a given date.
 *
 * @param {String} date The date to calculate the time left until.
 * @param {boolean} digital return output format 00:00:00.
 * @param {Function} setTime The function to set the time left.
 *
 * @returns {string} The time left until the given date.
 */

export const timeLeftUntilDate = (date, setTime, digital = true) => {
  let timeInterval;
  if (date) {
    timeInterval = setInterval(() => {
      const timeLeftInHrs = moment(date).diff(moment(), 'hours');
      const timeLeftInMins =
        +moment(date).diff(moment(), 'minutes') - timeLeftInHrs * 60;
      const timeLeftInSecs =
        +moment(date).diff(moment(), 'seconds') -
        timeLeftInMins * 60 -
        timeLeftInHrs * 60 * 60;

      const timeLeft = digital
        ? `${timeLeftInHrs.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${timeLeftInMins.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${timeLeftInSecs.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        : `${timeLeftInHrs}h ${timeLeftInMins}m`;
      setTime(timeLeft);
    }, 1000);
  }
  return () => clearInterval(timeInterval);
};

/**
 * Format a given time in the format 'hh:mm:ss A'.
 *
 * @param {string} time - The time to be formatted (e.g., '2023-10-04 20:34:58.894 +0300').
 * @returns {string} The formatted time (e.g., '08:34:58 PM').
 */
export const formatTime = (time) => {
  const parsedTime = moment(time).format('hh:mm:ss A');

  return parsedTime;
};

/**
 * Formats a date as "15 March 2021".
 *
 * @param {string} date - The date to be formatted (e.g., '2023-10-04 20:34:58.894 +0300').
 * @returns {string} The formatted date in "DD MMMM YYYY" format.
 */
export const formatDateWithTextMonth = (date) => {
  const formattedDate = moment(date).format('D MMMM YYYY');

  return formattedDate;
};

/**
 * Display the remaining time as an HH:mm:ss formatted string after subtracting seconds from the specified end time.
 *
 * @param {string|Date} endTime - The end time to calculate the remaining time to.
 * @param {number} secondsToSubtract - The number of seconds to subtract from the current time.
 * @param {Function} onDone - A callback function to execute when the timer reaches 00:00:00.
 *
 * @returns {string} The remaining time in HH:mm:ss format.
 */
export const displayTimeFromSeconds = (
  startDate,
  endTime,
  secondsToSubtract,
  onDone
) => {
  const currentTime = startDate ? moment(startDate) : moment();
  const endData = moment(endTime);
  if (secondsToSubtract) {
    currentTime.subtract(secondsToSubtract, 'seconds');
  }

  const duration = moment.duration(endData.diff(currentTime));

  if (onDone) {
    if (!duration.seconds() && !duration.hours() && !duration.minutes()) {
      onDone();
    }
  }
  const formattedTime = moment
    .utc(duration.as('milliseconds'))
    .format('HH:mm:ss');

  return formattedTime;
};

export const statusText = (statusId) => {
  switch (statusId) {
    case 2:
    case 3:
      return 'Winner';
    case 1:
      return 'Active';
    default:
      return 'Outbid';
  }
};

export const paymentText = (statusId) => {
  switch (statusId) {
    case 3:
      return 'Success';
    case 2:
      return 'Failed';
    default:
      return 'N/A';
  }
};

/**
 * Formats a number with a "k" suffix if it's greater than or equal to 1000.
 *
 * @param {number} number - The number to be formatted.
 *
 * @returns {string} The formatted number with a "k" suffix if applicable.
 */
export const formatNumberWithK = (number) => {
  if (number >= 1000) {
    return `${number / 1000}k`;
  }
  return number.toString();
};

/**
 * Generates a random alphanumeric string of a specified length.
 *
 * @param {number} length - The desired length of the random string.
 * @returns {string} A random alphanumeric string of the specified length.
 */
export const generateRandomString = (length) => {
  let randomString = '';

  // ASCII values for alphanumeric characters
  const alphanumericChars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  while (randomString.length < length) {
    const randomCharCode = Math.floor(Math.random() * alphanumericChars.length);
    randomString += alphanumericChars.charAt(randomCharCode);
  }

  return randomString;
};
