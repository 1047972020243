import { AchievementsNotificationProvider } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import ConfettiProvider from 'contexts/ConfettiContext';
import IsAgentProvider from 'contexts/IsAgentContext';
import IsLocationSelected from 'contexts/IsLocationSelectedFromDropDownContext';
import Messages from 'contexts/MessagesContext';
import NotificationSocket from 'contexts/NotificationSocketContext';
import { PointsNotificationProvider } from 'contexts/PointsNotificationContext/PointsNotificationContext';
import ShowToastProvider from 'contexts/showToastContext';
import { UserProvider } from 'contexts/UserContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import GlobalStyles from 'styles/global-styles';
import App from './App';
import ScrollToTop from './components/scroll-to-top';

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <GlobalStyles />
    <BrowserRouter>
      <UserProvider>
        <AchievementsNotificationProvider>
          <ShowToastProvider>
            <IsAgentProvider>
              <ScrollToTop />
              <ConfettiProvider>
                <PointsNotificationProvider>
                  <IsLocationSelected>
                    <NotificationSocket>
                      <Messages>
                        <App />
                      </Messages>
                    </NotificationSocket>
                  </IsLocationSelected>
                </PointsNotificationProvider>
              </ConfettiProvider>
            </IsAgentProvider>
          </ShowToastProvider>
        </AchievementsNotificationProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
