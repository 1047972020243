import { ReactComponent as OfferIcon } from 'assets/icons/OfferIcon.svg';
import { ReactComponent as LeftArrow } from 'assets/visit-left-arrow.svg';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

const fadeIn = keyframes`
  from {
    max-width: 15rem;
  }
  
  to {
    max-width: 5rem;
  }
`;

const fadeOut = keyframes`
  from {
    max-width: 5rem;
  }
  
  to {  
    max-width: 15rem;
  }
`;

export const MenuItemsContainer = styled.div`
  display: none;

  ${mq.tablet`
     animation: ${({ isCollapsed }) => {
       if (isCollapsed === true) return fadeOut;
       if (isCollapsed === false) return fadeIn;
       return '';
     }}
    ease-in-out forwards;

  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
  background-color: ${colors.mineShaft};
  margin-top: 0.1rem;
  padding: 5rem 0 0 1rem;
  max-width: 15rem;
  width: 100%;
  `}
`;

export const MenuLink = styled(NavLink)`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 3rem;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;

  &.active {
    ${({ isCollapsed }) =>
      !isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ isCollapsedSubMenu }) =>
      isCollapsedSubMenu &&
      `border-right: 0.35rem ${colors.mineShaft} solid; color: ${colors.green};`}
    ${({ isCollapsedSubMenu }) =>
      !isCollapsedSubMenu &&
      `border-right: 0.35rem ${colors.mineShaft} solid; color: ${colors.white};`}
    ${({ isCollapsed }) => !isCollapsed && `border-radius: 0;`}
    ${({ isCollapsed }) => isCollapsed && `background-color: ${colors.green};`}
    ${({ isCollapsed }) => isCollapsed && `margin-right:1rem;`}
  }
`;

export const LinkTitle = styled.p`
  margin-left: 1.8rem;
  overflow: hidden;
  font-size: 1rem;
`;

export const LeftArrowIcon = styled(LeftArrow)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${colors.mineShaft};
  }
`;

export const RightArrowIcon = styled(LeftArrowIcon)`
  transform: rotate(180deg);
  margin-left: 0.1rem;
`;

export const ItemsContainer = styled.p`
  margin-left: 2rem;
  overflow: hidden;
`;

export const MenuItem = styled.span`
  cursor: pointer;
  background-color: inherit;
  padding: 0;
  border: none;
  position: relative;
`;

export const MyClientsWrapper = styled(MenuItem)`
  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;

    path {
      fill: ${colors.white};
    }
  }
`;

export const MyMessagesWrapper = styled(MenuItem)`
  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;

    path {
      fill: ${colors.white};
    }
  }
`;

export const MyREOWrapper = styled(MenuItem)`
  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;

    g {
      stroke: white;
      stroke-width: 5.5rem;
    }

    path {
      fill: ${colors.white};
    }
  }
`;

export const MenuArrow = styled.span`
  cursor: pointer;
  padding: 0;
  border: none;
  position: relative;
`;

export const MenuIconContainer = styled.div`
  animation: ease-in-out forwards;

  animation-duration: 0.4s;
  background-color: ${colors.white};
  border-radius: 20rem;
  border: 0.15rem ${colors.midGray} solid;
  box-shadow: none;
  display: flex;
  padding: 0.5rem;
  position: absolute;
  top: -4rem;
  right: -1rem;

  &:hover {
    background-color: ${colors.green};
    border: 0.15rem ${colors.green} solid;

    > svg {
      path {
        fill: ${colors.white};
      }
    }
  }
`;

export const LogoutLink = styled.div`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 3rem;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &.active {
    ${({ isCollapsed }) =>
      !isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ isCollapsed }) => !isCollapsed && `border-radius: 0;`}
    ${({ isCollapsed }) => isCollapsed && `background-color: ${colors.green};`}
    ${({ isCollapsed }) => isCollapsed && `margin-right:1rem;`}
  }
  ${({ hover }) =>
    hover &&
    `&:hover {
    color: ${colors.green};
    svg path {
      fill: ${colors.green};
    }
  }`}
`;

export const StyledOfferIcon = styled(OfferIcon)`
  margin-left: 0.7rem;
`;

export const MessagesCounter = styled.span`
  font-size: 0.6rem;
  font-weight: bold;
  padding: ${({ messageCount }) =>
    messageCount > 99
      ? '0.3rem 0.2rem'
      : messageCount >= 20
      ? '0.3rem 0.3rem'
      : messageCount >= 10
      ? '0.35rem 0.4rem'
      : '0.25rem 0.5rem'};
  border-radius: 50%;
  background-color: ${colors.red};
  color: ${colors.white};
`;

export const MessagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Skeleton = styled.div`
  span {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  padding-right: 0.5rem;
`;
