import { usePointsNotifications } from 'contexts/PointsNotificationContext/PointsNotificationContext';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import PointsNotification from './points-notification';
import { Wrapper } from './styles';

/**
 * @param {Boolean} isVerifyPhoneBanner  is verify phone banner
 *
 * @return {JSX.Element}
 */
export default function Notifications({ isVerifyPhoneBanner }) {
  const { notificationItems } = usePointsNotifications();
  const [scrollPosition, setScrollPosition] = useState(6);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollPosition(document.documentElement.scrollTop / 16 + 6);
    });
  });

  return (
    <Wrapper isBanner={isVerifyPhoneBanner} scrollPosition={scrollPosition}>
      {notificationItems.map(({ points, id }) => (
        <PointsNotification key={id} points={points} id={id} />
      ))}
    </Wrapper>
  );
}

Notifications.propTypes = {
  isVerifyPhoneBanner: PropTypes.bool,
};

Notifications.defaultProps = {
  isVerifyPhoneBanner: false,
};
