import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Login user.
 *
 * @param {string} email User email.
 * @param {string} password User password.
 *
 * @return {Promise<Object>} The login response.
 */
const login = async (email, password) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/auth/login`, { email, password });

  return data;
};

/**
 * Social Login user.
 *
 * @param {string} idToken User idToken.
 * @param {string} invitedBy User invited by.
 * @param {string} eventId User event id.
 *
 * @return {Promise<Object>} The login response.
 */
const googleLogin = async (idToken, invitedBy, eventId) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/auth/google-login`, {
    eventId,
    idToken,
    invitedBy,
  });

  return data;
};

/**
 * Social Login user.
 * @param {string} accessToken User accessToken.
 * @param {string} invitedBy User invited by.
 * @param {string} eventId User event id.
 *
 * @return {Promise<Object>} The login response.
 */
const facebookLogin = async (accessToken, invitedBy, eventId) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/auth/facebook-login`, {
    accessToken,
    eventId,
    invitedBy,
  });
  return data;
};

/**
 * Sign up new user.
 *
 * @param {string} name User name.
 * @param {string} email User email.
 * @param {string} password User password.
 * @param {string} phone User phone number.
 * @param {string} invitedBy User invited by.
 * @param {string} eventId User event id.
 *
 * @return {Promise<Object>} The sign up response
 */
const signUp = async (name, email, password, phone, invitedBy, eventId) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/auth/signup`, {
    email,
    eventId,
    invitedBy,
    name,
    password,
    phone,
  });

  return data;
};

/**
 * reset password for a user with modals.
 *
 * @param {string} value User email.
 * @param {string} setShowModal handle showing a modal.
 * @param {string} setError set error.
 *
 * @return {Promise<Object>} The reset link response
 */
const values = {};
let errors = null;
let response = null;
const resetPassword = async (value) => {
  await http
    .post(`${apiUrl}/auth/send-reset-email`, {
      email: value.toLowerCase(),
    })
    .then((res) => {
      values.nestedModel = true;
      response = res;
      errors = null;
    })
    .catch((err) => {
      values.nestedModel = false;
      if (err?.response?.data?.message === 'Bad request')
        errors = err?.response?.data?.errors[0].message;
      else errors = err?.response?.data?.message;
    });
  return { errors, response, values };
};

/**
 * reset password for a user with page.
 *
 * @param {string} password User password.
 * @param {string} confirmPassword User Confirm Password.
 * @param {string} setErrorResult handle error from backend.
 * @param {string} navigate navigate to url.
 *
 * @return {Promise<Object>} The reset password response
 */
const resetPasswordPage = async ({ confirmPassword, password, token }) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/auth/reset-password`, {
    confirmPassword,
    password,
    token,
  });
  return data;
};

/**
 * check validation of the link
 */
const checkValidation = async (navigate) => {
  await http
    .post(`${apiUrl}/auth/check-token`, {
      token: window.location.href.split('=')[1].split('/')[6],
    })
    .then(() => {})
    .catch((err) => {
      if (
        err?.response?.data?.message === 'invalid or expired link' ||
        err?.response?.data?.message === 'invalid text'
      ) {
        navigate('/expired-link');
      }
    });
};

/** Change User Password.
 *
 * @param {string} currentPassword User current password.
 * @param {string} id User id.
 * @param {string} newPassword User new password.
 *
 * @return {Promise<Object>} The change password response
 */
const changeUserPassword = async ({ currentPassword, id, newPassword }) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/auth/change-password`, {
    currentPassword,
    id,
    newPassword,
  });

  return data;
};

/**
 * Update token.
 *
 * @return {Promise<Object>} The reset password response
 */
const updateToken = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/auth/update-token`);
  return data;
};

export default {
  changeUserPassword,
  checkValidation,
  facebookLogin,
  googleLogin,
  login,
  resetPassword,
  resetPasswordPage,
  signUp,
  updateToken,
};
