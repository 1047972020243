import { lazy } from 'react';

/**
 * Objects of routes to determine correct component to load based on URL.
 *
 * @property {boolean} adminLayout Wrap the page with admin layout.
 * @property {JSX.Element} component React component to render.
 * @property {bool} disableFooter Whether footer disabled.
 * @property {bool} disableNavBar Whether navbar disabled.
 * @property {boolean} exact When true, will only match if the path matches the location.pathname exactly.
 * @property {string} label A name used for react `key` prop.
 * @property {string} path Any valid URL path.
 */
const routes = [
  {
    adminLayout: true,
    component: lazy(() => import('pages/Transaction/index')),
    label: 'transaction',
    path: '/transaction/:listingId/*',
    title: 'Transaction',
  },
  {
    component: lazy(() => import('pages/Home')),
    exact: true,
    label: 'home',
    path: '/',
    title: 'Home',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyInvitations')),
    label: 'my-invitations',
    path: '/my-invitations',
    title: 'My invitations',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyOffers')),
    label: 'my-offers',
    path: '/my-offers',
    title: 'My Offers',
  },
  {
    component: lazy(() => import('pages/Login')),
    disableFooter: true,
    disableNavbar: true,
    label: 'login',
    path: '/login',
    title: 'Login',
  },
  {
    component: lazy(() => import('pages/SignUp')),
    disableFooter: true,
    disableNavbar: true,
    label: 'sign-up',
    path: '/sign-up',
    title: 'SignUp',
  },
  {
    component: lazy(() => import('pages/SignUp')),
    disableFooter: true,
    disableNavbar: true,
    label: 'sign-up',
    path: '/sign-up/:email',
    title: 'SignUp',
  },
  {
    component: lazy(() => import('pages/VerifyPhone')),
    disableFooter: true,
    label: 'verify-phone',
    path: '/verify-phone',
    title: 'Verify Phone',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Listing/Create')),
    label: 'create-listing',
    path: '/my-listings/create',
    title: 'Create Listing',
  },
  {
    component: lazy(() => import('pages/Listing/Search')),
    label: 'listing-search',
    path: '/listing/search',
    title: 'Search Listing',
  },
  {
    component: lazy(() => import('pages/Listing/ListingPageProvider')),
    label: 'listing',
    path: '/listing/:id',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Listing/Edit')),
    label: 'create-listing',
    path: '/my-listings/edit/:id',
    title: 'Create Listing',
  },
  {
    component: lazy(() => import('pages/NotFound')),
    label: 'not-found',
    path: '*',
    title: 'Not Found',
  },
  {
    component: lazy(() => import('pages/Listing/ListingPageProvider')),
    label: 'listing-page',
    path: '/listing-page/:id',
    title: 'Listing Page',
  },
  {
    component: lazy(() => import('pages/AgentList')),
    label: 'member-directory',
    path: '/member-directory',
    title: 'Member Directory',
  },
  {
    component: lazy(() => import('pages/MyRoles')),
    label: 'my-roles',
    path: '/my-roles',
    title: 'My Roles',
  },
  {
    component: lazy(() => import('pages/AgentDetails')),
    label: 'member-details',
    path: '/member-details/:id',
    title: 'Member Details',
  },
  {
    component: lazy(() => import('pages/ChangePassword/ChangePassword')),
    label: 'change-password',
    path: '/change-password',
    title: 'Change Password',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Listing/Edit')),
    label: 'edit-listing-schedule',
    path: '/my-listings/listing-schedule/edit/:id',
    title: 'Edit Listing Schedule',
  },
  {
    component: lazy(() => import('pages/NotFound')),
    label: 'not-found',
    path: '*',
    title: 'Not Found',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Dashboard')),
    label: 'dashboard',
    path: '/dashboard',
    title: 'Dashboard',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyProfile')),
    label: 'my-profile',
    path: '/my-profile',
    title: 'My Profile',
  },
  {
    component: lazy(() => import('pages/HowWeWork')),
    label: 'how-we-work',
    path: '/dashboard/how-we-work',
    title: 'How We Work',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyListings')),
    label: 'my-listings',
    path: '/my-listings',
    title: 'My listings',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyVisits')),
    label: 'my-visits',
    path: '/my-visits',
    title: 'My visits',
  },

  {
    adminLayout: true,
    component: lazy(() => import('pages/Transaction')),
    label: 'transaction',
    path: '/transaction/:listingId',
    title: 'Transaction',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/SavedAgentsListings')),
    label: 'my-favorites',
    path: '/my-favorites',
    title: 'My Favorites',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/TransactionInfo')),
    label: 'transaction-info',
    path: '/transaction',
    title: 'Transaction Info',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Achievements/Achievements')),
    label: 'achievements',
    path: '/achievements',
    title: 'Achievements Page',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/LenderBidsPage')),
    label: 'bids',
    path: '/lender-bids',
    title: 'Bids Page',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/AgentBids')),
    label: 'agent-bids',
    path: '/agent-bids',
    title: 'Agent Bids Page',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyExclusiveAds')),
    label: 'my-exclusive-ads',
    path: '/my-exclusive-ads',
    title: 'Exclusive Ads Page',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/ExclusiveLenders')),
    label: 'exclusive-lenders',
    path: '/exclusive-lenders',
    title: 'Exclusive Lenders',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyEarnings')),
    label: 'my-earnings',
    path: '/my-earnings',
    title: 'My Earnings',
  },
  {
    component: lazy(() => import('pages/LinkExpired')),
    disableFooter: true,
    disableNavbar: true,
    label: 'expired-link',
    path: '/expired-link',
    title: 'Expired Link',
  },
  {
    component: lazy(() => import('pages/ResetPassword')),
    disableFooter: true,
    disableNavbar: true,
    label: 'reset Password',
    path: '/reset-password',
    title: 'Reset Password',
  },
  {
    component: lazy(() => import('pages/PrivacyPolicy')),
    label: 'privacy-policy',
    path: '/privacy-policy',
    title: 'Privacy Policy Page',
  },
  {
    component: lazy(() => import('pages/Notifications')),
    disableNavbar: true,
    label: 'notifications',
    path: '/notifications',
    title: 'Notifications Page',
  },
  {
    component: lazy(() => import('pages/KnowledgeCenter')),
    label: 'knowledge-center',
    path: '/knowledge-center',
    title: 'Knowledge Center Page',
  },
  {
    component: lazy(() => import('pages/CategoryPosts')),
    label: 'knowledge-center',
    path: '/knowledge-center/:category',
    title: 'Knowledge Center',
  },
  {
    component: lazy(() => import('pages/Post')),
    label: 'knowledge-center',
    path: '/knowledge-center/:category/:id',
    title: 'Knowledge Center',
  },
  {
    component: lazy(() => import('pages/faqs')),
    label: 'faqs',
    path: '/FAQs',
    title: 'FAQs',
  },
  {
    component: lazy(() => import('pages/Events')),
    label: 'Events',
    path: '/events',
    title: 'Events Page',
  },
  {
    component: lazy(() => import('pages/EventDetails')),
    label: 'Events Details',
    path: '/event-details/:id',
    title: 'Events Details Page',
  },
  {
    component: lazy(() => import('pages/EventUsers')),
    label: 'Events Users',
    path: '/attended-users/:eventId',
    title: 'Events Users Page',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyClients')),
    label: 'my-clients',
    path: '/my-clients',
    title: 'My Clients',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyReferrals')),
    label: 'my-referrals',
    path: '/my-referals',
    title: 'My Referrals',
  },
  {
    component: lazy(() => import('pages/Leaderboard')),
    label: 'leaderboard',
    path: '/leaderboard',
    title: 'Leaderboard',
  },
  {
    component: lazy(() => import('pages/Subscriptions')),
    label: 'subscriptions',
    path: '/subscriptions/:role',
    title: 'subscriptions',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyREO')),
    label: 'my-REO',
    path: '/my-REO',
    title: 'My REO',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyMessages')),
    label: 'my-Messages',
    path: '/my-messages',
    title: 'My Messages',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Events/form')),
    label: 'events',
    path: '/my-events/new',
    title: 'Events',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/Events/form')),
    label: 'events',
    path: 'my-events/:id',
    title: 'Events',
  },
  {
    component: lazy(() => import('components/closed-deals')),
    exact: true,
    label: 'closed-deals',
    path: '/closed-deals',
    title: 'Closed Deals',
  },
  {
    adminLayout: true,
    component: lazy(() => import('pages/MyEvents')),
    label: 'my-events',
    path: '/my-events',
    title: 'My events',
  },
  {
    component: lazy(() => import('pages/QualifiedBorrower')),
    label: 'qualified-borrower',
    path: '/qualified-borrower',
    title: 'Qualified Borrower',
  },
  {
    // authRequired: true,
    component: lazy(() => import('pages/Checkout')),
    disableFooter: true,
    disableNavbar: true,
    label: 'checkout',
    path: '/checkout',
    title: 'checkout',
  },
];

export default routes;
