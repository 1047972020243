import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

export const Wrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 0rem;
`;

export const ProfilePic = styled.img`
  border-radius: 50%;
  height: 1.9rem;
  object-fit: cover;
  width: 1.9rem;
  ${mq.desktop`
    height: 2.2rem;
    width:2.2rem;
`};
`;

export const LabelName = styled.p`
  font-size: 0.8rem;
  margin: 0 0.03rem 0 0.2rem;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  ${mq.desktop`
    margin: 0 0.05rem 0 0.45rem;
    font-size: 0.85rem;
  `};
`;

export const CompanyName = styled.p`
  color: ${colors.gray};
  display: inline-block;
  font-size: 0.7rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  ${mq.desktop`
    font-size: 0.85rem;
  `};
`;

export const Parenthesis = styled.p`
  color: ${colors.gray};
  font-size: 0.7rem;
  padding: 0;
  ${mq.desktop`
    font-size: 0.85rem;
  `};
`;
