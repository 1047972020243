import agentImage from 'assets/agent-image.png';
import { ReactComponent as BadgesIcon } from 'assets/badgesIcon.svg';
import { ReactComponent as NotificationBill } from 'assets/icons/bell.svg';
import { ReactComponent as Message } from 'assets/icons/messaging.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as InvitationIcon } from 'assets/icons/InvitationIcon.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock-icon.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
// import { ReactComponent as MessagesIcon } from 'assets/icons/message.svg';
import { ReactComponent as MyClientsIcon } from 'assets/icons/my-clients.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/profile-name.svg';
import { ReactComponent as MyReferralsIcon } from 'assets/icons/refer.svg';
import { ReactComponent as Saved } from 'assets/icons/saved.svg';
import { ReactComponent as MyRolesIcon } from 'assets/icons/user-roles.svg';
import { ReactComponent as DashboardIcon } from 'assets/my-dashboard.svg';
import { ReactComponent as NotificationIcon } from 'assets/notification.svg';
import { ReactComponent as SignOut } from 'assets/sign-out.svg';
import HamburgerMenu from 'components/shared/HamburgerMenu';
import LoadingSkeleton from 'components/shared/LoadingSkeleton';
import Menu from 'components/shared/Menu';
import { useAchievementsNotifications } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import { useIsAgent } from 'contexts/IsAgentContext';
import { useMessages } from 'contexts/MessagesContext';
import { useNotification } from 'contexts/NotificationSocketContext';
import { useUser } from 'contexts/UserContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';

import {
  getNotification,
  getUnreadNotificationCount,
  markAllNotificationsAsRead,
  updateNotificationStatus,
} from 'services/notification';
import { getAllUserAchievements } from 'services/points-service';
import { listingPaths } from 'utils/appPaths';
import { NOTIFICATIONS_STATUS } from 'utils/constants';
import {
  isBuyerSeller,
  isCreditSpecialistOrFinacialAdvisor,
  isVip,
} from 'utils/helpers';
import NotificationBody from '../NotificationBody';
import { StyledOfferIcon } from '../Sidebar/sidebar.styles';
import { LineSeparator } from '../Tabs/tabs.styles';
import {
  BroadNavContainer,
  CloseMenuIcon,
  Container,
  ExpandableContainer,
  ExpandableMenuItem,
  ExpandableMenuLink,
  HiddenUnreadNotificationCount,
  IconContainer,
  Logo,
  LogoContainer,
  MarkAsReadTypography,
  MenuIcon,
  MenuItem,
  MenuTopWrapper,
  MenuWrapper,
  // MessagesCounter,
  NarrowNavContainer,
  Nav,
  NavItemsContainer,
  NavLink,
  NavLinksContainer,
  NewNotification,
  NotificationControlWrapper,
  NotificationCounter,
  NotificationHeader,
  NotificationItem,
  NotificationItemBody,
  NotificationsBody,
  NotificationsImage,
  NotificationText,
  NotificationTextSection,
  NotificationTime,
  NotificationTypography,
  NotificationWrapper,
  PointsWrapper,
  SignInButton,
  SignUpButton,
  UserControlSectionWrapper,
  UserImage,
  UserNavControlContainer,
  UserNavRegContainer,
  WhiteLockIcon,
  UserPoints,
  ExpandedMenuItem,
  ProfileImage,
  ProfileImageWrapper,
  DisplayMenu,
  HoverOnLink,
  HoveringWrapper,
  LogoCenterContainer,
  UserWrapper,
  UserNameAndPoints,
  DownArrow,
  UserInfoWrapper,
  OldLogo,
  UserPointsWrapper,
  UserName,
  UserNameContainer,
  MessageWrapper,
} from './navbar.styles';

/**
 * Home page header section.
 *
 * @param {Boolean} changeRoute changing route from '/notifications'
 *
 * @return {JSX.Element}
 */
function Navbar({ setIsExpanded, isExpanded, changeRoute }) {
  const navigate = useNavigate();
  const {
    setUserInfo,
    userInfo,
    isLoggedIn,
    logout,
    isSocialRegistered,
    loading,
  } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState();
  const [hiddenUnreadNotificationCount, setHiddenUnreadNotificationCount] =
    useState(0);
  const [notifications, setNotifications] = useState([]);
  const [noMoreNotification, setNoMoreNotification] = useState(false);
  const [Badges, setBadges] = useState(0);
  const { socket } = useNotification();
  const [brokenImage, setBrokenImage] = useState(false);
  const [page, setPage] = useState(1);
  const clickRef = useRef(null);
  const clickRefNotification = useRef(null);
  const clickRefREPros = useRef(null);
  const { isRoleChanges } = useAchievementsNotifications();
  const { checkIsAgent, isAgent, setIsAgent } = useIsAgent();
  const NavContainer = isLoggedIn ? BroadNavContainer : NarrowNavContainer;
  const [isPrivatePage, setIsPrivatePage] = useState(false);
  const {
    getUnreadMessages,
    unreadMessagesCount,
    setOpenedChatId,
    setIsChatBotOpen,
    setIsRedirect,
  } = useMessages();
  const [isOpenHamburgerMenu, setIsOpenHamburgerMenu] = useState(false);
  const [expandREPros, setExpandREPros] = useState(false);
  const [isREProsActive, setIsREProsActive] = useState(false);
  const [isUserVip, setIsUserVip] = useState(false);
  const { activePlansId } = userInfo || {};

  /**
   * check Agent Permissions function
   */
  const checkAgentPermissions = async () => {
    if (userInfo?.id) {
      const agent = await checkIsAgent(userInfo?.id, true);
      setIsAgent(agent);
    }
  };
  const pathName = window.location.pathname;
  const searchObject = routes.find((route) => route.path === pathName);

  useEffect(() => {
    if (isLoggedIn) {
      checkAgentPermissions();
      getUnreadMessages();
    }
    if (searchObject?.adminLayout) setIsPrivatePage(true);

    if (!activePlansId?.length) {
      return;
    }

    setIsUserVip(isVip(activePlansId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isRoleChanges, getUnreadMessages, isLoggedIn]);

  useEffect(() => {
    if (pathName !== '/my-messages') {
      setOpenedChatId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  /**
   * Handle open menu.
   */
  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  /**
   * Handle close menu.
   */
  const handelCloseMenu = () => {
    setIsOpen(false);
  };

  /**
   * Handle close notification.
   */
  const handleCloseNotification = async () => {
    setPage(1);
    setNoMoreNotification(false);
    setIsNotificationOpen(false);
    const count = await getUnreadNotificationCount(userInfo.id);
    setHiddenUnreadNotificationCount(hiddenUnreadNotificationCount - 5);
    setUnreadNotification(!!count);
  };

  /**
   * Handle close notification.
   */
  const handleOpenNotification = async () => {
    if (unreadNotificationCount > 5) {
      setUnreadNotificationCount(unreadNotificationCount - 5);
    } else {
      setUnreadNotification(false);
    }

    const newNotificationUnread = await getNotification(
      userInfo.id,
      5,
      page,
      true
    );

    if (unreadNotificationCount > 5) {
      setHiddenUnreadNotificationCount(unreadNotificationCount - 5);
    }
    const newNotificationRead = await getNotification(
      userInfo.id,
      5 - newNotificationUnread?.length,
      page
    );
    newNotificationUnread.forEach(async (n) => {
      if (n.status !== NOTIFICATIONS_STATUS.read)
        await updateNotificationStatus(n.id, NOTIFICATIONS_STATUS.read);
    });
    setNotifications([...newNotificationUnread, ...newNotificationRead]);
    if (newNotificationRead.length + newNotificationUnread < 5)
      setNoMoreNotification(true);
    setIsNotificationOpen(true);
  };

  /**
   * Handle Mark all as read
   */
  const handleMarkAllAsRead = async () => {
    await markAllNotificationsAsRead(userInfo.id);
    setNotifications(
      notifications.map((n) => ({ ...n, status: NOTIFICATIONS_STATUS.read }))
    );
    setUnreadNotification(false);
  };

  /**
   * get time interval
   */
  const getTimeInterval = (date) =>
    `${moment
      .duration(moment().diff(moment(Date.parse(date))))
      .humanize()} ago`;

  useEffect(() => {
    if (isLoggedIn) getUnreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  useEffect(
    () =>
      socket?.on('new_notification', async () => {
        const count = await getUnreadNotificationCount(userInfo?.id);
        setUnreadNotificationCount(count);
        setHiddenUnreadNotificationCount(hiddenUnreadNotificationCount - 5);
        setUnreadNotification(true);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socket]
  );

  useEffect(() => {
    const getUnreadCount = async () => {
      const count = await getUnreadNotificationCount(userInfo.id);
      setUnreadNotificationCount(count);
      setHiddenUnreadNotificationCount(hiddenUnreadNotificationCount - 5);
      setUnreadNotification(!!count);
    };
    const fetchUserBadges = async () => {
      const data = await getAllUserAchievements();
      setBadges(data?.userAchievements?.length || 0);
    };

    if (userInfo?.id) {
      getUnreadCount();
      fetchUserBadges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const getUnreadCount = async () => {
      const count = await getUnreadNotificationCount(userInfo.id);
      setUnreadNotificationCount(count);
      setUnreadNotification(!!count);
    };
    if (userInfo?.id) {
      getUnreadCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRoute]);

  useEffect(() => {
    if (userInfo?.points === 0) {
      setUserInfo({
        ...userInfo,
        points: userInfo?.points + 30,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onError = () => {
    setBrokenImage(true);
  };

  return (
    <Nav>
      <NavContainer>
        <IconContainer>
          {!isExpanded ? (
            <MenuIcon onClick={() => setIsExpanded(true)} />
          ) : (
            <CloseMenuIcon onClick={() => setIsExpanded(false)} />
          )}
        </IconContainer>

        <LogoContainer>
          {!isPrivatePage && isLoggedIn && (
            <HamburgerMenu
              open={isOpenHamburgerMenu}
              setOpen={setIsOpenHamburgerMenu}
              setIsOpenUserMenu={setIsOpen}
              isUserLoggedIn={isLoggedIn}
            />
          )}

          {!isPrivatePage && !isLoggedIn && (
            <HamburgerMenu
              open={isOpenHamburgerMenu}
              setOpen={setIsOpenHamburgerMenu}
              setIsOpenUserMenu={setIsOpen}
              isUserLoggedIn={isLoggedIn}
            />
          )}
          <Logo onClick={() => navigate('/')} isLoggedIn={isLoggedIn} />
        </LogoContainer>

        {isLoggedIn && loading ? (
          <div />
        ) : (
          <NavItemsContainer>
            <NavLinksContainer>
              <NavLink
                onClick={() =>
                  window.location.pathname.includes('/member-directory')
                    ? window.location.reload()
                    : navigate('/member-directory?page=1')
                }
              >
                Member Directory
              </NavLink>
              <NavLink
                onClick={() => {
                  if (window.location.pathname.includes('/listing/search')) {
                    navigate(`${listingPaths?.search}?key=NJ`);
                    window.location.reload();
                  } else navigate(`${listingPaths?.search}?key=NJ`);
                }}
              >
                Find Listing
              </NavLink>
              <NavLink onClick={() => navigate('/knowledge-center')}>
                Knowledge Center
              </NavLink>

              <LogoCenterContainer>
                <Logo onClick={() => navigate('/')} isLoggedIn={isLoggedIn} />
              </LogoCenterContainer>

              <HoverOnLink>
                <NavLink ref={clickRefREPros}>
                  <HoveringWrapper>Pros</HoveringWrapper>
                </NavLink>
                <DisplayMenu>
                  <Menu
                    isOpen
                    overlayOnOpen={false}
                    anchorRef={clickRefREPros}
                    spacingToRight={180}
                    isPros
                    isSticky
                  >
                    <MenuWrapper>
                      <MenuTopWrapper>
                        <MenuItem
                          stroke="true"
                          onClick={() => {
                            if (
                              window.location.pathname.includes(
                                '/subscriptions/agent'
                              )
                            ) {
                              window.location.reload();
                            } else {
                              navigate('/subscriptions/agent');
                              window.location.reload();
                            }
                          }}
                        >
                          Real Estate Agent
                        </MenuItem>
                        <MenuItem
                          stroke="true"
                          onClick={() => {
                            if (
                              window.location.pathname.includes(
                                '/subscriptions/lender'
                              )
                            ) {
                              window.location.reload();
                            } else {
                              navigate('/subscriptions/lender');
                              window.location.reload();
                            }
                          }}
                        >
                          Lender
                        </MenuItem>
                      </MenuTopWrapper>
                    </MenuWrapper>
                  </Menu>
                </DisplayMenu>
              </HoverOnLink>
              <NavLink onClick={() => navigate('/events')}>Events</NavLink>
              <NavLink onClick={() => navigate('/leaderboard')}>
                Leaderboard
              </NavLink>
            </NavLinksContainer>
            {isLoggedIn ? (
              <UserNavControlContainer>
                <MessageWrapper
                  onClick={() => {
                    setIsRedirect(false);
                    setIsChatBotOpen(true);
                  }}
                >
                  {unreadMessagesCount > 0 && (
                    <NotificationCounter messageCount={unreadMessagesCount}>
                      {unreadMessagesCount > 99 ? '+99' : unreadMessagesCount}
                    </NotificationCounter>
                  )}
                  <Message />
                </MessageWrapper>
                <NotificationControlWrapper
                  ref={clickRefNotification}
                  onClick={handleOpenNotification}
                >
                  {unreadNotification && (
                    <NotificationCounter
                      notificationsCount={unreadNotificationCount}
                    >
                      {unreadNotificationCount > 99
                        ? '+99'
                        : unreadNotificationCount}
                    </NotificationCounter>
                  )}
                  <NotificationBill />
                </NotificationControlWrapper>
                <UserInfoWrapper>
                  <UserControlSectionWrapper
                    ref={clickRef}
                    onClick={handleOpenMenu}
                  >
                    {loading ? (
                      <LoadingSkeleton height="50" width="50" circle />
                    ) : (
                      <UserImage
                        onError={onError}
                        src={
                          !brokenImage
                            ? userInfo?.thumbnailImage ||
                              userInfo?.image ||
                              agentImage
                            : agentImage
                        }
                        defaultImage={!userInfo?.thumbnailImage}
                      />
                    )}
                    {loading ? (
                      <LoadingSkeleton width="60" />
                    ) : (
                      <UserWrapper>
                        <UserNameAndPoints>
                          {userInfo?.name?.split(' ')[0]}
                          <UserPointsWrapper>
                            <UserPoints>{userInfo?.points} points</UserPoints>
                            {isUserVip && <OldLogo />}
                          </UserPointsWrapper>
                        </UserNameAndPoints>
                        <DownArrow />
                      </UserWrapper>
                    )}
                  </UserControlSectionWrapper>
                </UserInfoWrapper>
                <Menu
                  isOpen={isOpen}
                  anchorRef={clickRef}
                  onClose={handelCloseMenu}
                  isSticky
                >
                  <MenuWrapper>
                    <PointsWrapper onClick={() => navigate('/achievements')}>
                      <div>
                        <NotificationIcon />
                        <span>
                          {userInfo?.points} <span>Points</span>
                        </span>
                      </div>
                      <div>
                        <BadgesIcon />
                        <span>
                          {Badges} <span>Badges</span>
                        </span>
                      </div>
                    </PointsWrapper>
                    <MenuTopWrapper>
                      <MenuItem
                        stroke="true"
                        onClick={() => {
                          navigate('/dashboard');
                          setIsOpen(false);
                        }}
                      >
                        <DashboardIcon />
                        My Dashboard
                      </MenuItem>
                      <MenuItem
                        stroke="true"
                        onClick={() => {
                          navigate('/my-profile');
                          setIsOpen(false);
                        }}
                      >
                        <SettingIcon />
                        My Profile
                      </MenuItem>
                      <MenuItem
                        stroke="true"
                        onClick={() => {
                          navigate('/my-roles');
                          setIsOpen(false);
                        }}
                      >
                        <MyRolesIcon />
                        My Roles
                      </MenuItem>
                      {!isSocialRegistered && (
                        <MenuItem
                          stroke="true"
                          onClick={() => {
                            navigate('/change-password');
                            setIsOpen(false);
                          }}
                        >
                          <LockIcon />
                          Change Password
                        </MenuItem>
                      )}
                      <MenuItem
                        stroke="true"
                        type="button"
                        onClick={() => {
                          logout();
                          setIsOpen(false);
                          navigate('/');
                        }}
                      >
                        <SignOut />
                        Log Out
                      </MenuItem>
                    </MenuTopWrapper>
                  </MenuWrapper>
                </Menu>
                <Menu
                  isOpen={isNotificationOpen}
                  anchorRef={clickRefNotification}
                  onClose={handleCloseNotification}
                  isSticky
                >
                  <NotificationWrapper>
                    <NotificationHeader>
                      <NotificationTypography>
                        Notifications
                      </NotificationTypography>
                      {unreadNotification && (
                        <MarkAsReadTypography onClick={handleMarkAllAsRead}>
                          Mark All As Read
                        </MarkAsReadTypography>
                      )}
                    </NotificationHeader>
                    <NotificationsBody>
                      {notifications.map((n) => (
                        <NotificationItem
                          key={n?.id}
                          unReadNotification={
                            n?.status !== NOTIFICATIONS_STATUS.read
                          }
                        >
                          {n?.status !== NOTIFICATIONS_STATUS.read && (
                            <NewNotification />
                          )}
                          <NotificationItemBody
                            isClickable={n?.content?.redirectPath}
                            onClick={() => {
                              if (n?.content?.redirectPath) {
                                navigate(n?.content?.redirectPath);
                              }
                            }}
                          >
                            <NotificationsImage />
                            <NotificationTextSection>
                              <NotificationText>
                                <NotificationBody
                                  body={n?.body}
                                  content={n?.content}
                                  type={
                                    n?.notificationChannel?.notificationType
                                      ?.description
                                  }
                                  setIsNotificationOpen={setIsNotificationOpen}
                                />
                              </NotificationText>
                              <NotificationTime>
                                {getTimeInterval(n?.createdAt)}
                              </NotificationTime>
                            </NotificationTextSection>
                          </NotificationItemBody>
                        </NotificationItem>
                      ))}
                    </NotificationsBody>
                    {!noMoreNotification && (
                      <>
                        <MarkAsReadTypography
                          onClick={() => {
                            handleCloseNotification();
                            navigate('/notifications');
                          }}
                        >
                          Show All
                        </MarkAsReadTypography>
                        <HiddenUnreadNotificationCount>
                          {hiddenUnreadNotificationCount &&
                          unreadNotification &&
                          hiddenUnreadNotificationCount > 0
                            ? hiddenUnreadNotificationCount > 99
                              ? '+99'
                              : `(${hiddenUnreadNotificationCount})`
                            : ''}
                        </HiddenUnreadNotificationCount>
                      </>
                    )}
                  </NotificationWrapper>
                </Menu>
              </UserNavControlContainer>
            ) : (
              <UserNavRegContainer>
                <SignInButton onClick={() => navigate('/login')}>
                  Sign In
                </SignInButton>
                <SignUpButton onClick={() => navigate('/sign-up')}>
                  Join Now
                </SignUpButton>
              </UserNavRegContainer>
            )}
          </NavItemsContainer>
        )}
      </NavContainer>
      <ExpandableContainer isExpanded={isExpanded}>
        {isLoggedIn && (
          <>
            <ProfileImageWrapper>
              <a href="/my-profile">
                <ProfileImage
                  onError={onError}
                  src={
                    !brokenImage ? userInfo?.image || agentImage : agentImage
                  }
                  defaultImage={!userInfo?.image}
                />
              </a>
              <div>
                <UserNameContainer>
                  <UserName>{userInfo?.name}</UserName>
                  {isUserVip && <OldLogo />}
                </UserNameContainer>
                <UserPoints>
                  <span>{userInfo?.points} points</span>
                </UserPoints>
              </div>
            </ProfileImageWrapper>

            {!isSocialRegistered && (
              <ExpandableMenuLink
                isCollapsed={isExpanded}
                to="/change-password"
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem type="button">
                  <WhiteLockIcon />
                  <h4>Change Password</h4>
                </ExpandableMenuItem>
              </ExpandableMenuLink>
            )}
            <Container>
              <ExpandableMenuItem
                onClick={() => {
                  logout();
                  setIsExpanded(false);
                  navigate('/');
                }}
                padding
              >
                <Logout />
                <h4>Log Out</h4>
              </ExpandableMenuItem>
            </Container>
            <LineSeparator />
            <ExpandableMenuLink
              isCollapsed={isExpanded}
              to="/dashboard"
              onClick={() => setIsExpanded(false)}
            >
              <ExpandableMenuItem>
                <Dashboard />
                <h4>My Dashboard</h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>

            {/* <ExpandableMenuLink
              isCollapsed={isExpanded}
              to="/my-messages"
              onClick={() => setIsExpanded(false)}
            >
              <ExpandableMenuItem>
                <MessagesIcon />
                <h4>My Messages</h4>
                {unreadMessagesCount > 0 && (
                  <MessagesCounter messageCount={unreadMessagesCount}>
                    {unreadMessagesCount > 99 ? '+99' : unreadMessagesCount}
                  </MessagesCounter>
                )}
              </ExpandableMenuItem>
            </ExpandableMenuLink> */}
            <ExpandableMenuLink
              isCollapsed={isExpanded}
              to="/my-invitations"
              onClick={() => setIsExpanded(false)}
            >
              <ExpandableMenuItem>
                <InvitationIcon />
                <h4>My Invitations</h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>

            {!isCreditSpecialistOrFinacialAdvisor(userInfo?.roles) && (
              <ExpandableMenuLink
                isCollapsed={isExpanded}
                to="/my-offers"
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem>
                  <StyledOfferIcon />
                  <h4>My Offers</h4>
                </ExpandableMenuItem>
              </ExpandableMenuLink>
            )}

            {!isBuyerSeller(userInfo?.roles) && (
              <ExpandableMenuLink
                isCollapsed={isExpanded}
                to="/my-clients"
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem>
                  <MyClientsIcon />
                  <h4>My Clients</h4>
                </ExpandableMenuItem>
              </ExpandableMenuLink>
            )}
            <ExpandableMenuLink
              isCollapsed={isExpanded}
              to="/my-referals"
              onClick={() => setIsExpanded(false)}
            >
              <ExpandableMenuItem>
                <MyReferralsIcon />
                <h4>My Referrals</h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>

            <ExpandableMenuLink
              isCollapsed={isExpanded}
              to="/my-favorites"
              onClick={() => setIsExpanded(false)}
            >
              <ExpandableMenuItem>
                <Saved />
                <h4>My Favorites</h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>
            {isAgent && (
              <ExpandableMenuLink
                isCollapsed={isExpanded}
                to="/my-listings"
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem>
                  <List />
                  <h4>My Listings</h4>
                </ExpandableMenuItem>
              </ExpandableMenuLink>
            )}

            <LineSeparator />
          </>
        )}

        <ExpandableMenuLink
          isCollapsed={isExpanded && !isREProsActive}
          to="/member-directory"
          onClick={() => {
            setIsExpanded(false);
            setExpandREPros(false);
            setIsREProsActive(false);
          }}
        >
          <ExpandableMenuItem type="button">
            <h4>
              <NavLink>Member Directory</NavLink>
            </h4>
          </ExpandableMenuItem>
        </ExpandableMenuLink>

        <ExpandableMenuLink
          isCollapsed={isExpanded && !isREProsActive}
          to={listingPaths.search}
          onClick={() => {
            setIsExpanded(false);
            setExpandREPros(false);
            setIsREProsActive(false);
          }}
        >
          <ExpandableMenuItem type="button">
            <h4>
              <NavLink>Find Listing</NavLink>
            </h4>
          </ExpandableMenuItem>
        </ExpandableMenuLink>

        <ExpandableMenuLink
          isCollapsed={isExpanded && !isREProsActive}
          to="/knowledge-center"
          onClick={() => {
            setIsExpanded(false);
            setExpandREPros(false);
            setIsREProsActive(false);
          }}
        >
          <ExpandableMenuItem type="button">
            <h4>
              <NavLink>Knowledge Center</NavLink>
            </h4>
          </ExpandableMenuItem>
        </ExpandableMenuLink>

        <ExpandableMenuLink
          isCollapsed={isExpanded && !isREProsActive}
          to="/events"
          onClick={() => {
            setIsExpanded(false);
            setExpandREPros(false);
            setIsREProsActive(false);
          }}
        >
          <ExpandableMenuItem type="button">
            <h4>
              <NavLink>Events</NavLink>
            </h4>
          </ExpandableMenuItem>
        </ExpandableMenuLink>
        <ExpandableMenuLink
          isCollapsed={expandREPros}
          to="#"
          onClick={() => {
            setIsExpanded(true);
            setExpandREPros(!expandREPros);
            setIsREProsActive(true);
          }}
        >
          <ExpandedMenuItem type="button">Pros</ExpandedMenuItem>
        </ExpandableMenuLink>

        {expandREPros === true && (
          <div>
            <ExpandableMenuLink
              isCollapsedSubMenu={isExpanded}
              to="/subscriptions/agent"
              onClick={() => {
                setIsExpanded(false);
                setExpandREPros(true);
              }}
            >
              <ExpandableMenuItem type="button">
                <h4>
                  <NavLink>
                    <span>Real Estate Agent</span>
                  </NavLink>
                </h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>

            <ExpandableMenuLink
              isCollapsedSubMenu={isExpanded}
              to="/subscriptions/lender"
              onClick={() => {
                setIsExpanded(false);
                setExpandREPros(true);
              }}
            >
              <ExpandableMenuItem type="button">
                <h4>
                  <NavLink>
                    <span>Lender</span>
                  </NavLink>
                </h4>
              </ExpandableMenuItem>
            </ExpandableMenuLink>
          </div>
        )}

        <ExpandableMenuLink
          isCollapsed={isExpanded && !isREProsActive}
          to="/leaderboard"
          onClick={() => {
            setIsExpanded(false);
            setExpandREPros(false);
            setIsREProsActive(false);
          }}
        >
          <ExpandableMenuItem type="button">
            <h4>
              <NavLink>Leaderboard</NavLink>
            </h4>
          </ExpandableMenuItem>
        </ExpandableMenuLink>
      </ExpandableContainer>
    </Nav>
  );
}

Navbar.propTypes = {
  changeRoute: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  changeRoute: false,
};

export default Navbar;
