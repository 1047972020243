import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * get user achievements.
 *
 * @param {string} userId User id.
 *
 * @return {Promise<Object>} Property types response.
 */
// eslint-disable-next-line import/prefer-default-export
export const getUserAchievements = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-achievements`);

  return data;
};
