import React, { useState } from 'react';
import { getUsersWithLimit, getUserData } from 'services/user';
import FormInput from 'components/shared/FormInput';
import { useMessages } from 'contexts/MessagesContext';
import { Container, ListedUsersWrapper } from './search-all.style';

/**
 * Add New Message component
 *
 * @return {JSX.Element}
 */
function AddNewMessage() {
  const [namesList, setNamesList] = useState(false);

  const { setNewMessageReceiverId } = useMessages();

  const handleInputChange = async (e) => {
    const userName = e.target.value;
    if (userName) {
      const users = await getUsersWithLimit(4, userName);
      setNamesList(users);
    } else {
      setNamesList([]);
    }
  };

  const onSelect = async (id) => {
    const userData = await getUserData(id);
    setNewMessageReceiverId(userData?.user?.id);
  };

  return (
    <Container>
      <FormInput
        name="userName"
        placeholder="Type a name"
        onChange={handleInputChange}
      />
      {namesList &&
        namesList?.map(({ id, name }) => (
          <ListedUsersWrapper key={id} onClick={() => onSelect(id)}>
            {name}
          </ListedUsersWrapper>
        ))}
    </Container>
  );
}

export default AddNewMessage;
