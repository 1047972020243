import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NotificationLink } from './notifications-body.style';

/**
 * Notification body component.
 * @param {object} props - Component props.
 * @param {string} props.type - Notification type.
 * @param {object} props.content - Notification content.
 * @param {string} props.body - Notification body.
 * @param {function} props.setIsNotificationOpen - Set notification open function.
 *
 * @return {JSX.Element}
 */
function NotificationBody({ type, content, body, setIsNotificationOpen }) {
  const navigate = useNavigate();

  switch (type) {
    case 'Invite Lender':
      return (
        <span>
          You have been invited by{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.agentId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.agentName}
          </NotificationLink>{' '}
          to win an ad placement on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.listingAddress}
          </NotificationLink>
        </span>
      );
    case 'Start bidding':
      return (
        <span>
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.listingAddress}
          </NotificationLink>{' '}
          is up for bidding! Hurry up and win the ad placement!
        </span>
      );
    case 'Lender Won Bid For Agent':
      return (
        <span>
          Congratulations!{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has won the bid on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
        </span>
      );
    case 'Lender Won Bid For Lender':
      return (
        <span>
          Congratulations! You have won exclusive ad placement on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
        </span>
      );
    case 'Lender Won Place Ad For Lender':
      return (
        <span>
          Congratulations! you have won an ad placement on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
        </span>
      );
    case 'Lender Won Place Ad For Agent':
      return (
        <span>
          Congratulations!{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has won an exclusive ad placement on your{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
        </span>
      );
    case 'Lender Won Bid For Other Lenders':
      return (
        <span>
          Bidding duration has ended for{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          . Unfortunately, another lender has outbid you.
        </span>
      );
    case 'Lender Accepted Invitation':
      return (
        <span>
          Congratulations!{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has accepted your paid invite for exclusive ad space on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          .
        </span>
      );
    case 'Lender Reject Invitation':
      return (
        <span>
          Unfortunately,{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has declined your invite for exclusive ad space on your{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          .
        </span>
      );
    case 'Invitation Timeout':
      return (
        <span>
          Ad Placement Invite duration for{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          is over, and{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          did not accept your invite.
        </span>
      );
    case 'Bid Timeout':
      return (
        <span>
          Bid duration on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>{' '}
          is over and no bids were placed
        </span>
      );
    case 'VIP Agent Sold Ad Placement':
      return (
        <span>
          Lender{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has accepted the invite for ad placement on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          .
        </span>
      );
    case 'Non VIP Agent Sold Ad Placement':
      return (
        <span>
          Lender{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/member-details/${content?.lenderId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.lenderName}
          </NotificationLink>{' '}
          has accepted the invite for ad placement on{' '}
          <NotificationLink
            onClick={() => {
              navigate(`/listing/${content?.listingId}`);
              setIsNotificationOpen(false);
            }}
            className="link"
          >
            {content?.address}
          </NotificationLink>
          .
        </span>
      );
    default:
      return <span>{body}</span>;
  }
}

NotificationBody.propTypes = {
  body: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsNotificationOpen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default NotificationBody;
