import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Service that get notifications types
 *
 * @param {String} type  notification type(registration, review ....)
 *
 * @return {Array} user reviews
 */
export const getNotificationTypes = async (type) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/notification-types/${type}`);

  return data;
};

/**
 * Service that send notification
 *
 * @param {String} type  notification type(registration, review ....)
 * @param {Object} content data to send with notification
 *
 */
export const sendNotification = async (type, content) => {
  const notificationType = await getNotificationTypes(type);

  content.notificationTypeId = notificationType?.id;

  const {
    data: { data },
  } = await http.post(`${apiUrl}/notifications`, content);

  return data;
};

/**
 * Service that send notification to admins for contact
 *
 * @param {Object} content data to send with notification
 * 
 * @return {Array} notifications
 */
export const notifyAdminsContact = async (content) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/notifications/notify-admin/contact`, content);

  return data;
};
