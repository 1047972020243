import io from 'socket.io-client';
import AchievementsNotifications from 'components/achievements-notifications/achievements-notifications';
import AchievementModal from 'components/achievements/AchievementModal';
import PointsNotifications from 'components/points-notifications/points-notifications';
import AdminPanelLayout from 'components/shared/AdminPanelLayout';
import MainLayout from 'components/shared/MainLayout';
import Toast from 'components/shared/Toast';
import VerifyPhoneBanner from 'components/verify-phone/VerifyPhoneBanner';
import RedemptionBanner from 'components/redemption-banner';
import { useAchievementsNotifications } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import { useConfetti } from 'contexts/ConfettiContext';
import { useNotification } from 'contexts/NotificationSocketContext';
import { useMessages } from 'contexts/MessagesContext';
import { usePointsNotifications } from 'contexts/PointsNotificationContext/PointsNotificationContext';
import { useShowToast } from 'contexts/showToastContext';
import { useUser } from 'contexts/UserContext';
import useAddAction from 'hooks/use-add-action';
import useShowToastBar from 'hooks/use-show-toast-bar';
import React, { useEffect, useMemo } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes';
import { getUserAchievements } from 'services/achievements-notifications-service';
import { getUserRoles } from 'services/user';
import { serverUrl } from 'config/config';
import canvasStyles from 'styles/canvas-styles';
import { achievementsData, actionTypes } from 'utils/constants';
import ChatBot from 'components/chat-bot';

/**
 * App entry point component.
 *
 * @return {JSX.Element}
 */
function App() {
  const { setSocket, socket } = useNotification();
  const { isLoggedIn, userInfo } = useUser();
  const { addNotification, isRoleChanges } = useAchievementsNotifications();
  const { achievementItem, setAchievementItem } = usePointsNotifications();
  const { showToast, setShowToast } = useShowToast();
  const verifyPhonePage = useLocation();
  const { refConfetti } = useConfetti();
  const [add] = useAddAction();
  const { isChatBotOpen, isRedirect } = useMessages();

  const { roles = [] } = userInfo || {};
  const activeUserRoles = useMemo(
    () => roles?.map(({ role }) => role),
    [roles]
  );

  useShowToastBar(showToast, setShowToast);

  const fetchUserAchievementsData = async () => {
    const userRoles = await getUserRoles();
    const isAgent = !!userRoles.filter(
      (item) => item?.role?.toLowerCase() === 'real estate agent'
    ).length;
    const achievements = [];
    if (userRoles.length <= 0) {
      const data = await getUserAchievements();

      if (data.indexOf('setMyRoles') !== -1) {
        achievements.push(achievementsData.setMyRoles);
      }

      addNotification(achievements);
    }
    if (isLoggedIn && isAgent) {
      const data = await getUserAchievements();

      if (data.indexOf('completeProfile') !== -1) {
        achievements.push(achievementsData.completeProfile);
      }

      if (data.indexOf('newListing') !== -1) {
        achievements.push(achievementsData.newListing);
      }

      addNotification(achievements);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchUserAchievementsData();
    };

    if (isLoggedIn) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isRoleChanges]);

  useEffect(() => {
    if (userInfo?.email) add(actionTypes.dailyLogin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.email]);

  useEffect(() => {
    if (userInfo?.token && !socket) {
      const socketData = io(serverUrl, {
        auth: {
          token: userInfo?.token,
        },
        path: '/api/socket.io',
      });
      setSocket(socketData);
      socketData?.emit('user_login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.token]);

  return (
    <>
      <React.Suspense fallback={<span />}>
        <Routes>
          {routes.map(
            ({
              label,
              exact,
              path,
              component: Component,
              adminLayout,
              disableNavbar,
              disableFooter,
            }) => (
              <Route
                key={label}
                path={path}
                exact={exact}
                element={
                  !adminLayout ? (
                    <>
                      {userInfo?.phone &&
                        !['/verify-phone', '/checkout'].includes(
                          verifyPhonePage.pathname
                        ) &&
                        !userInfo?.isVerified && <VerifyPhoneBanner />}

                      {activeUserRoles?.some(
                        (item) =>
                          item === 'Mortgage Lender/Broker' ||
                          item === 'Real Estate Agent'
                      ) && (
                        <RedemptionBanner
                          roles={activeUserRoles}
                          points={userInfo?.points}
                          activePlans={userInfo?.activePlansId}
                        />
                      )}

                      <MainLayout
                        disableNavbar={disableNavbar}
                        disableFooter={disableFooter}
                      >
                        <Component />
                        <AchievementsNotifications />
                        {isChatBotOpen && !isRedirect && <ChatBot />}
                      </MainLayout>
                    </>
                  ) : (
                    <>
                      {userInfo?.phone &&
                        verifyPhonePage.pathname !== '/verify-phone' &&
                        !userInfo?.isVerified && <VerifyPhoneBanner />}

                      {activeUserRoles?.some(
                        (item) =>
                          item === 'Mortgage Lender/Broker' ||
                          item === 'Real Estate Agent'
                      ) && (
                        <RedemptionBanner
                          roles={activeUserRoles}
                          points={userInfo?.points}
                          activePlans={userInfo?.activePlansId}
                        />
                      )}

                      <AdminPanelLayout>
                        <Component />
                        {isChatBotOpen && !isRedirect && <ChatBot />}
                      </AdminPanelLayout>
                    </>
                  )
                }
              />
            )
          )}
        </Routes>
      </React.Suspense>
      {showToast?.isActive && (
        <Toast
          isPositionFixed
          status={showToast.status}
          message={showToast.text}
        />
      )}
      <PointsNotifications
        isVerifyPhoneBanner={
          userInfo?.phone &&
          verifyPhonePage.pathname !== '/verify-phone' &&
          !userInfo?.isVerified
        }
      />
      <AchievementModal
        showModal={achievementItem}
        setShowModal={setAchievementItem}
      />
      <ReactCanvasConfetti refConfetti={refConfetti} style={canvasStyles} />
    </>
  );
}

export default App;
