import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as InvitationIcon } from 'assets/icons/InvitationIcon.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
// import { ReactComponent as MessagesIcon } from 'assets/icons/message.svg';
import { ReactComponent as MyClientsIcon } from 'assets/icons/my-clients.svg';
import { ReactComponent as MyReferralsIcon } from 'assets/icons/refer.svg';
import { ReactComponent as Saved } from 'assets/icons/saved.svg';
import LoadingSkeleton from 'components/shared/LoadingSkeleton';
import { useAchievementsNotifications } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import { useIsAgent } from 'contexts/IsAgentContext';
import { useMessages } from 'contexts/MessagesContext';
import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  isBuyerSeller,
  isCreditSpecialistOrFinacialAdvisor,
} from 'utils/helpers';
import {
  LeftArrowIcon,
  LinkTitle,
  LogoutLink,
  MenuArrow,
  MenuIconContainer,
  MenuItem,
  MenuItemsContainer,
  MenuLink,
  // MessagesContainer,
  // MessagesCounter,
  MyClientsWrapper,
  // MyMessagesWrapper,
  RightArrowIcon,
  Skeleton,
  StyledOfferIcon,
} from './sidebar.styles';

/**
 * Side menu.
 *
 * @param {String} openedChatId opened chat id in my messages route
 *
 * @return {JSX.Element}
 */
function Sidebar({ openedChatId }) {
  const { isLoggedIn, logout, userInfo } = useUser();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(' ');
  const { isRoleChanges } = useAchievementsNotifications();
  const { checkIsAgent, isAgent, setIsAgent } = useIsAgent();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const {
    //  unreadMessagesCount,
    setPath,
  } = useMessages();
  /**
   * check Agent Permissions function
   */
  const checkAgentPermissions = async () => {
    if (userInfo?.id) {
      const agent = await checkIsAgent(userInfo?.id, true);
      setIsAgent(agent);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPath(location?.pathname);
  }, [location, openedChatId, setPath]);

  useEffect(() => {
    checkAgentPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isRoleChanges]);

  return (
    <MenuItemsContainer isCollapsed={isCollapsed} isLoggedIn={isLoggedIn}>
      <MenuArrow onClick={() => setIsCollapsed(!isCollapsed)}>
        <MenuIconContainer isCollapsed={isCollapsed}>
          {isCollapsed ? <LeftArrowIcon /> : <RightArrowIcon />}
        </MenuIconContainer>
      </MenuArrow>
      {isLoading ? (
        <Skeleton>
          <LoadingSkeleton height="20" count={14} />
        </Skeleton>
      ) : (
        <>
          <MenuLink isCollapsed={isCollapsed} to="/dashboard">
            <MenuItem type="button">
              <Dashboard />
            </MenuItem>
            <LinkTitle>My Dashboard</LinkTitle>
          </MenuLink>

          {/* <MenuLink isCollapsed={isCollapsed} to="/my-messages">
            <MyMessagesWrapper type="button">
              <MessagesIcon />
            </MyMessagesWrapper>
            <MessagesContainer>
              <LinkTitle>My Messages</LinkTitle>
              {unreadMessagesCount > 0 && isCollapsed && (
                <MessagesCounter messageCount={unreadMessagesCount}>
                  {unreadMessagesCount > 99 ? '+99' : unreadMessagesCount}
                </MessagesCounter>
              )}
            </MessagesContainer>
          </MenuLink> */}
          <MenuLink isCollapsed={isCollapsed} to="/my-invitations">
            <MenuItem type="button">
              <InvitationIcon />
            </MenuItem>
            <LinkTitle>My Invitations</LinkTitle>
          </MenuLink>

          {!isCreditSpecialistOrFinacialAdvisor(userInfo?.roles) && (
            <MenuLink isCollapsed={isCollapsed} to="/my-offers">
              <MenuItem type="button">
                <StyledOfferIcon />
              </MenuItem>
              <LinkTitle>My Offers</LinkTitle>
            </MenuLink>
          )}

          {!isBuyerSeller(userInfo?.roles) && (
            <MenuLink isCollapsed={isCollapsed} to="/my-clients">
              <MyClientsWrapper type="button">
                <MyClientsIcon />
              </MyClientsWrapper>
              <LinkTitle>My Clients</LinkTitle>
            </MenuLink>
          )}

          <MenuLink isCollapsed={isCollapsed} to="/my-referals">
            <MyClientsWrapper type="button">
              <MyReferralsIcon />
            </MyClientsWrapper>
            <LinkTitle>My Referrals</LinkTitle>
          </MenuLink>

          <MenuLink isCollapsed={isCollapsed} to="/my-favorites">
            <MenuItem type="button">
              <Saved />
            </MenuItem>
            <LinkTitle>My Favorites</LinkTitle>
          </MenuLink>

          {isAgent && (
            <MenuLink isCollapsed={isCollapsed} to="/my-listings">
              <MenuItem type="button">
                <List />
              </MenuItem>
              <LinkTitle>My Listings</LinkTitle>
            </MenuLink>
          )}

          <LogoutLink
            onClick={() => {
              logout();
              navigate('/');
            }}
            isCollapsed={isCollapsed}
          >
            <MenuItem type="button">
              <Logout />
            </MenuItem>
            <LinkTitle>Log Out</LinkTitle>
          </LogoutLink>
        </>
      )}
    </MenuItemsContainer>
  );
}

Sidebar.propTypes = {
  openedChatId: PropTypes.string,
};

Sidebar.defaultProps = {
  openedChatId: null,
};

export default Sidebar;
