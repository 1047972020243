import {
  Container,
  Error,
  Label,
} from 'components/shared/FormInput/form-input.styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Input,
  Labels,
  LimitMessage,
  SubTitleMobileText,
  SubTitleText,
} from './form-text-area.styles';

/**
 * Render form text-area input.
 *
 * @param {Object}   props                         The component props.
 * @param {string}   [props.error=null]            Error message.
 * @param {string}   [props.id=null]               Input ID.
 * @param {string}   [props.label=null]            Label text.
 * @param {node}     [props.labelIconElement=null] Label icon element.
 * @param {string}   props.name                    Input name.
 * @param {Function} [props.onChange=null]         The on change event.
 * @param {Function} [props.onKeyDown=null]     The on Enter key press event.
 * @param {string}   [props.placeholder='']        Input placeholder.
 * @param {Function} [props.register=null]         The react-hook-form register function.
 * @param {boolean}  [props.rounded=true]          Is input borderers rounded.
 * @param {string}   [props.value='']              Textarea input value.
 * @param {string}   [props.subTitle=null]         subTitle text.
 * @param {boolean}  [disabled]                    Is input disabled.
 *
 * @return {JSX.Element}
 */
function TextAreaInput({
  characterLength,
  charactersCounts,
  defaultValue,
  error,
  id,
  isGetFromDB,
  label,
  labelIconElement,
  limit,
  name,
  onChange,
  placeholder,
  register,
  rounded,
  required,
  subTitle,
  disabled,
  onKeyDown,
}) {
  const [charactersCount, setCharactersCount] = useState(0);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onKeyDown(event.target.value);
    }
  };

  return (
    <Container>
      <Labels hasLabel={label}>
        {label && (
          <Label htmlFor={id || name}>
            {labelIconElement}
            {label}
            {required && <span>*</span>}
            {subTitle && <SubTitleText>({subTitle})</SubTitleText>}
          </Label>
        )}

        {!isGetFromDB ? (
          <LimitMessage color={limit === characterLength ? 'red' : null}>
            {charactersCounts >= 0
              ? `(${charactersCounts || characterLength}/${limit} chars)`
              : `(${limit} chars)`}
          </LimitMessage>
        ) : (
          <LimitMessage color={limit === characterLength ? 'red' : null}>
            {!!limit && charactersCount >= 0
              ? `(${charactersCount || characterLength}/${limit} chars)`
              : !!limit && `(${limit} chars)`}
          </LimitMessage>
        )}
      </Labels>
      {subTitle && <SubTitleMobileText>({subTitle})</SubTitleMobileText>}
      <Input
        {...(register ? register(name) : {})}
        id={id || name}
        placeholder={placeholder}
        rounded={rounded}
        defaultValue={defaultValue}
        disabled={disabled}
        maxLength={limit}
        onKeyDown={handleKeyDown}
        onChange={
          isGetFromDB
            ? (event) => {
                onChange();
                setCharactersCount(event.target.value.length);
              }
            : onChange
        }
        onFocus={(event) => {
          setCharactersCount(event.target.value.length);
        }}
        error={error}
      />
      {error && !error.includes('required') && <Error>{error}</Error>}
    </Container>
  );
}

TextAreaInput.propTypes = {
  characterLength: PropTypes.number,
  charactersCounts: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  isGetFromDB: PropTypes.bool,
  label: PropTypes.string,
  labelIconElement: PropTypes.node,
  limit: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  rounded: PropTypes.bool,
  subTitle: PropTypes.string,
};

TextAreaInput.defaultProps = {
  characterLength: 0,
  charactersCounts: 0,
  defaultValue: null,
  disabled: false,
  error: null,
  id: null,
  isGetFromDB: true,
  label: null,
  labelIconElement: null,
  limit: null,
  onChange: () => {},
  onKeyDown: () => {},
  placeholder: '',
  register: null,
  required: false,
  rounded: true,
  subTitle: '',
};

export default TextAreaInput;
