import { apiUrl } from 'config/config';
import { DEFAULT_PAGE_LIMIT } from 'utils/constants';
import http from 'utils/http';

/**
 * Service that create listing bid.
 *
 * @param {String} id listing id.
 * @param {Object} body body to send with the request
 *
 * @return {Object} create listing bid
 */
export const createListingBidding = async (id, body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/bids/listing/${id}`, body);

  return data;
};

/**
 * Service that create user bid.
 *
 * @param {String} id bid id.
 * @param {Object} body body to send with the request
 *
 * @return {Object} create user bid
 */
export const createUserBid = async (id, body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/bids/${id}`, body);

  return data;
};

/**
 * Service that approve highest user bid.
 *
 * @param {String} id bid id.
 *
 * @return {Object} approve highest user bid
 */
export const approveHighestBid = async (id) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/bids/approve/${id}`);

  return data;
};

/**
 * Service that get user bid.
 *
 * @param {String} id user-bid id.
 *
 * @return {Object} user bid
 */
export const getUserBid = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/bids/user-bid/${id}`);

  return data;
};

/**
 * Service that cancel current bid.
 *
 * @param {String} id bid id.
 *
 * @return {Object} cancel current bid
 */
export const cancelBid = async (id) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/bids/cancel/${id}`);

  return data;
};

/**
 * Get lenders bids.
 *
 *
 * @param {String} statusIds statusIds.
 * @param {Number} allBidsPage allBidsPage.
 * @param {Number} conditionalBidsPage conditionalBidsPage.
 * @param {Number} conditionalBidsLimit conditionalBidsLimit.
 * @param {Number} allBidsLimit allBidsLimit.
 *
 * @return {Object} cancel current bid
 */
export const lenderBids = async (
  statusIds,
  allBidsPage = 1,
  conditionalBidsPage = 1,
  conditionalBidsLimit = DEFAULT_PAGE_LIMIT,
  allBidsLimit = DEFAULT_PAGE_LIMIT
) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/bids/lender-bids?statusIds=${statusIds}&allBidsPage=${allBidsPage}&allBidsLimit=${allBidsLimit}&conditionalBidsPage=${conditionalBidsPage}&conditionalBidsLimit=${conditionalBidsLimit}`
  );

  return data;
};

/**
 * Service that cancel user bid.
 *
 * @param {Object} body userbid.
 *
 * @return {Object} cancel user bid
 */
export const cancelUserBid = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/bids/cancel-user-bid`, body);

  return data;
};

/**
 * Get Agent bids.
 *
 * @param {Number} page page.
 * @param {Number} limit limit.
 *
 * @return {Object} agentBids
 */
export const agentBids = async (page = 1, limit = DEFAULT_PAGE_LIMIT) => {
  const { data } = await http.get(
    `${apiUrl}/bids/agent-bids?page=${page}&limit=${limit}`
  );

  return data;
};
