import * as yup from 'yup';

const newsLetterSchema = yup
  .object({
    email: yup
      .string()
      .label()
      .email()
      .required('Please enter a valid email address.'),
  })
  .required();

export default newsLetterSchema;
