import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

export const Wrapper = styled.div`
  align-items: flex-end;
  bottom: 17rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 5%;
  width: 100%;
  z-index: 99;
  margin-bottom: ${({ name }) => (name === 'setMyRoles' ? '4rem' : '0rem')};

  ${mq.tablet`
    right: 2rem;
    width: unset;
  `}
`;

export const NotificationWrap = styled.div`
  animation: fadeIn 0.5s linear forwards;
  overflow: visible;
  width: 90%;

  ${mq.tablet`
    width: fit-content;
  `}

  &.out {
    animation: fadeOut 0.3s linear forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 4rem;
    }
  }

  @keyframes fadeOut {
    0% {
      max-height: 4rem;
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(0.8);
      max-height: 0;
    }
  }
`;

export const NotificationMessage = styled.div`
  align-items: center;
  background: ${colors.white};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors.lightGray};
  box-shadow: 0rem 0.0625rem 2.125rem rgba(34, 34, 34, 0.32);
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem 2rem 2rem;
  position: relative;
  width: 100%;

  ${mq.tablet`
    width: 32.75rem;
  `}
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 1rem;
  padding: 0;
  position: absolute;
  right: 0.875rem;
  top: 0.875rem;
  width: 1rem;
`;

export const Message = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-align: center;
  padding: 1rem 0;
`;

export const MessageLabel = styled.label`
  color: ${colors.green};
`;

export const PointsLink = styled.p`
  color: ${colors.azure};
  cursor: pointer;
  font-size: 0.8125rem;
  letter-spacing: -0.0063rem;
  line-height: 1.375rem;
  margin: 0 0.5rem;
  text-decoration-line: underline;
`;

export const Title = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  padding: 1rem 0 0;
`;
