import Modal from 'components/shared/Modal';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useConfetti } from 'contexts/ConfettiContext';
import {
  agentAchievementIcons,
  nonAgentAchievementIcons,
} from 'utils/achievement-icons';
import { Wrapper } from './achievement-modal.styles';

/**
 * Close Deal Modal component
 *
 * @param {Object} showModal show modal state
 * @param {Function} setShowModal set show modal state function
 *
 * @returns {JSX.Element}
 */
function AchievementModal({ showModal, setShowModal }) {
  const { fire } = useConfetti();

  useEffect(() => {
    if (showModal) fire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal show={!!showModal} handleClose={() => setShowModal(null)}>
      <Wrapper>
        {showModal?.forAgent
          ? agentAchievementIcons[showModal?.type]
          : nonAgentAchievementIcons[showModal?.type]}
        <h2>Congratulations!</h2>
        <h3>{`You've earned a badge for ${showModal?.type}`}</h3>
      </Wrapper>
    </Modal>
  );
}

AchievementModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  showModal: PropTypes.objectOf(PropTypes.any),
};

AchievementModal.defaultProps = {
  showModal: null,
};

export default AchievementModal;
