import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';
import { ReactComponent as close } from 'assets/close.svg';
import { ReactComponent as verifyText } from 'assets/icons/verify-text.svg';
import { ReactComponent as fire } from 'assets/fire.svg';
import redemptionBanner from 'assets/redemption-banner.svg';
import Button from 'components/shared/Button';

export const Container = styled.div`
  align-items: center;
  display: none;
  gap: 0.5rem;
  justify-content: center;
  position: sticky;
  background-color: ${colors.white};
  padding: 0.5rem 0.5rem;

  button {
    padding: 0 0.5rem;
    min-width: 7.56rem;
  }

  ${({ showBanner }) => (showBanner ? 'display: flex;' : 'display: none;')}
  ${({ redeemColoredBackground }) =>
    redeemColoredBackground &&
    `background-image: url(${redemptionBanner});
    background-repeat: no-repeat;
    background-size: cover;`}
`;

export const Title = styled.h3`
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  min-width: 8rem;

  ${mq.tablet`
    font-size: 1.4rem;
  `}
`;

export const RedeemButton = styled(Button)`
  ${mq.tablet`
    margin-left: 4rem;
  `}

  ${({ redeem }) => redeem && `background-color: ${colors.black};`}
`;

export const Fire = styled(fire)`
  width: 1.5rem;
  height: 2rem;
`;

export const CloseBanner = styled(close)`
  display: flex;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  stroke-width: 0.02rem;
  stroke: ${colors.lightGreen};
  cursor: pointer;
  right: 0.93rem;
  top: 0.6rem;
  stroke: ${colors.coolGrey};
  fill: ${colors.coolGrey};
`;

export const VerifyText = styled(verifyText)`
  display: none;

  ${mq.tablet`
    display: block;
  `}
`;
