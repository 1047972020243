import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Service that add comment on transaction processes
 *
 * @param {Object} body data to be filled to transaction comment.
 *
 * @return {Object} data.
 */
export const addMessage = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/messages/transactions`, body);

  return data;
};

/**
 * Service that add referral comment.
 *
 * @param {Object} body data to be filled to referral comment.
 *
 * @return {Object} data.
 */
export const addReferralMessage = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/messages/referrals`, body);

  return data;
};

/**
 * Service that delete comment
 *
 * @param {String} id comment id.
 *
 * @return {Object} data.
 */
export const deleteMessage = async (id) => {
  const {
    data: { data },
  } = await http.delete(`${apiUrl}/messages/${id}`);

  return data;
};

/**
 * Service that update comment.
 *
 * @param {Object} body data to be filled to comment.
 *
 * @return {Object} data.
 *
 * @return {Object} object for the process.
 */
export const updateMessage = async (body) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/messages`, body);

  return data;
};

/**
 * get Comment by Id.
 *
 * @param {String} id comment id.
 *
 * @returns {Array} array of messages
 */
export const getMessageById = async (id) => {
  const {
    data: { data, allMentions },
  } = await http.get(`${apiUrl}/messages/message/${id}`);

  return { allMentions, data };
};

/**
 * get All Transaction Comments
 *
 * @param {String} transactionId transaction id.
 * @param {String} workflowStepId workflow step id.
 * @param {Boolean} transactionTaskUser came from transaction task modal or not.
 *
 * @returns {Array} array of messages
 */
export const getMessagesById = async (
  transactionId,
  transactionTaskUser = 'false',
  workflowStepId = ''
) => {
  const {
    data: { data, allMentions },
  } = await http.get(
    `${apiUrl}/messages/transaction/${transactionId}?workflowStepId=${workflowStepId}&taskTransaction=${transactionTaskUser}`
  );
  return { allMentions, data };
};

/**
 * get All Referrals Comments.
 *
 * @param {String} referralId referral id.
 *
 * @returns {Array} array of messages
 */
export const getReferralMessagesById = async (referralId) => {
  const {
    data: { data, allMentions },
  } = await http.get(`${apiUrl}/messages/referrals/${referralId}`);
  return { allMentions, data };
};

/**
 * Service that add reply on comment.
 *
 * @param {Object} body data to be filled to the reply.
 *
 * @return {Object} data.
 */
export const addReply = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/messages/reply`, body);

  return data;
};

/**
 * Service that delete reply.
 *
 * @param {String} id comment id.
 *
 * @return {Object} data.
 */
export const deleteReply = async (id) => {
  const {
    data: { data },
  } = await http.delete(`${apiUrl}/messages/reply/${id}`);

  return data;
};

/**
 * Service that update reply.
 *
 * @param {Object} body data to be filled to reply.
 *
 * @return {Object} data.
 */
export const updateReply = async (body) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/messages/reply`, body);

  return data;
};

/**
 * get reply by Id.
 *
 * @param {String} id reply Id.
 *
 * @returns {Array} array of messages
 */
export const getReplyById = async (id) => {
  const {
    data: { data, allMentions },
  } = await http.get(`${apiUrl}/messages/reply/${id}`);

  return { allMentions, data };
};

/**
 * short url
 *
 * @param {String} url to  shorten.
 *
 * @return {String} shorten url.
 */

export const shortUrl = async (url) => {
  const {
    data: { data },
  } = await http.get(
    `https://www.shareaholic.com/v2/share/shorten_link?apikey=8943b7fd64cd8b1770ff5affa9a9437b&url=${url}`
  );

  return data;
};

/**
 * get unread messages count.
 *
 * @returns {Number} count of messages
 */
export const getUnreadMessagesCount = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/messages/unread`);

  return { data };
};
