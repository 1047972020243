import { apiUrl } from 'config/config';
import { DEFAULT_PAGE_LIMIT } from 'utils/constants';
import http from 'utils/http';

/**
 * Service that create listing invitation.
 *
 * @param {String} id listing id.
 * @param {Object} body body to send with the request
 *
 * @return {Object} create listing invitation
 */
export const createListingLenderInvitation = async (id, body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/lender-invitations/listing/${id}`, body);

  return data;
};

/**
 * Service that reply on listing invitation.
 * @param {String} id invitation id.
 * @param {Object} body body to send with the request
 *
 * @return {Object} create reply on listing invitation
 */
export const replyOnInvitation = async (id, body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/lender-invitations/${id}`, body);

  return data;
};

/**
 * Get Lender Invitation by id.
 * @param {String} id lender-invitation id.
 *
 * @return {Object} create reply on listing invitation
 */
export const getLenderInvitationById = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/lender-invitations/${id}`);

  return data;
};

/**
 * Get lender invitation for un-logged user.
 * @param {String} id listing id.
 *
 * @return {Object} get lender invitation
 */
export const getLenderInvitationUnLogged = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/lender-invitations/un-logged/${id}`);

  return data;
};

/**
 * Hide time over Modal
 *
 * @param {String} id lender-invitation id.
 *
 * @return {Object} hide time over modal
 */
export const hideTimeOverModal = async (id) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/lender-invitations/time-over/${id}`);

  return data;
};

/**
 * Get lender invitations.
 *
 * @param {Number} page page.
 * @param {Number} limit limit.
 *
 * @return {Array} lender invitations.
 */
export const getLenderInvitations = async (
  page = 1,
  limit = DEFAULT_PAGE_LIMIT
) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/lender-invitations/invitations?page=${page}&limit=${limit}`
  );

  return data;
};
