import styled from 'styled-components';
import colors from 'styles/colors';

const StyledButton = styled.button`
  background-color: ${colors.green};
  border-radius: 1.75rem;
  border: none;
  color: ${colors.white};
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: 600;
  height: 2.5rem;
  padding: 0 1.69rem;
  transition: 0.1s;
  cursor: pointer;

  &:hover {
    box-shadow: 0rem 0.4rem 1.7rem -1rem ${colors.mineShaft};
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  ${({ light }) =>
    light &&
    `
    background-color: ${colors.white};
    color: ${colors.gray};
    border: 0.063rem solid ${colors.gray};
  `}

  ${({ red }) =>
    red &&
    `
    background-color: ${colors.red};
    color: ${colors.white};
    border: 0.063rem solid ${colors.red};
  `}
`;

export default StyledButton;
