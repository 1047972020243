import Button from 'components/shared/Button';
import styled from 'styled-components';
import mq from 'styles/media-query';
import colors from 'styles/colors';

export const ReferralForm = styled.form`
  margin: 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 88vh;

  ${mq.tablet`
    height: 100%;
  `}
`;

export const EmailAndPhoneWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: auto;

  ${mq.tablet`
    height: 20.95rem;
    margin-bottom: 1.5rem;
  `}
`;

export const FormTitle = styled.h1`
  padding-left: 1rem;
  text-align: left;
  margin: 1.25rem auto 0;
  color: ${colors.coolGrey};
  font-size: 0.75rem;
  font-weight: 700;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ErrorMessage = styled.p`
  color: ${colors.red};
`;

export const AddButton = styled(Button)`
  padding: 0 2.5rem;
  margin-right: 1rem;
  display: block;
`;

export const CheckBoxContainer = styled.div`
  align-self: center;

  > div {
    margin: 1rem auto;

    > label {
      font-size: 0.875rem;
      color: ${colors.gray};
    }
  }
`;

export const SearchInputWrapper = styled(InputWrapper)`
  margin-bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
  width: 100%;
  justify-content: center;
`;
