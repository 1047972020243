import PropTypes from 'prop-types';
import React, { createContext, useContext, useRef } from 'react';

export const ConfettiContext = createContext();

ConfettiContext.displayName = 'ConfettiContext';

/**
 * Confetti context to handle achievements
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function ConfettiProvider({ children }) {
  const refAnimationInstance = useRef(null);

  const refConfetti = (instance) => {
    refAnimationInstance.current = instance;
  };

  const fire = () => {
    const makeShot = (particleRatio, opts) =>
      refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      decay: 0.91,
      scalar: 0.8,
      spread: 100,
    });

    makeShot(0.1, {
      decay: 0.92,
      scalar: 1.2,
      spread: 120,
      startVelocity: 25,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { fire, refConfetti };

  return (
    <ConfettiContext.Provider value={value}>
      {children}
    </ConfettiContext.Provider>
  );
}

ConfettiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useConfetti = () => useContext(ConfettiContext);

export default ConfettiProvider;
