/* eslint-disable sort-keys */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import { ReactComponent as CompletedProfile } from 'assets/achievement/agent/completedProfile.svg';
import { ReactComponent as Created3Listings } from 'assets/achievement/agent/3CreatedListings.svg';
import { ReactComponent as Created7Listings } from 'assets/achievement/agent/7CreatedListings.svg';
import { ReactComponent as Created10Listings } from 'assets/achievement/agent/10CreatedListings.svg';
import { ReactComponent as Shared3 } from 'assets/achievement/agent/3Share.svg';
import { ReactComponent as Shared7 } from 'assets/achievement/agent/7Share.svg';
import { ReactComponent as Shared10 } from 'assets/achievement/agent/10Share.svg';
import { ReactComponent as Saved3 } from 'assets/achievement/agent/3Save.svg';
import { ReactComponent as Saved7 } from 'assets/achievement/agent/7Save.svg';
import { ReactComponent as Saved10 } from 'assets/achievement/agent/10Save.svg';
import { ReactComponent as Requested3Visits } from 'assets/achievement/agent/3RequestedVisits.svg';
import { ReactComponent as Requested7Visits } from 'assets/achievement/agent/7RequestedVisits.svg';
import { ReactComponent as Requested10Visits } from 'assets/achievement/agent/10RequestedVisits.svg';
import { ReactComponent as Accepted3Offers } from 'assets/achievement/agent/3AcceptedOffers.svg';
import { ReactComponent as Accepted7Offers } from 'assets/achievement/agent/7AcceptedOffers.svg';
import { ReactComponent as Accepted10Offers } from 'assets/achievement/agent/10AcceptedOffers.svg';
import { ReactComponent as Counter3Offers } from 'assets/achievement/agent/3CounterOffers.svg';
import { ReactComponent as Counter7Offers } from 'assets/achievement/agent/7CounterOffers.svg';
import { ReactComponent as Counter10Offers } from 'assets/achievement/agent/10CounterOffers.svg';
import { ReactComponent as CompletedProfileNonAgent } from 'assets/achievement/non-agent/CompletedProfile.svg';
import { ReactComponent as ShareNonAgent } from 'assets/achievement/non-agent/Share.svg';
import { ReactComponent as SaveNonAgent } from 'assets/achievement/non-agent/Save.svg';
import { ReactComponent as SubmitOffer } from 'assets/achievement/non-agent/SubmitOffer.svg';
import { ReactComponent as RequestedVisit } from 'assets/achievement/non-agent/RequestedVisit.svg';
import { ReactComponent as NewsletterSignUp } from 'assets/achievement/non-agent/NewsletterSignUp.svg';
import { ReactComponent as SubmitReview } from 'assets/achievement/non-agent/SubmitReview.svg';
import { ReactComponent as AcceptOffer } from 'assets/achievement/non-agent/AcceptOffer.svg';
import { ReactComponent as CounterOffer } from 'assets/achievement/non-agent/CounterOffer.svg';
import { ReactComponent as InvitedUsers } from 'assets/achievement/non-agent/InvitedUsers.svg';
import { ReactComponent as UploadDocuments } from 'assets/achievement/non-agent/UploadDocuments.svg';
import { ReactComponent as CloseDeal } from 'assets/achievement/non-agent/CloseDeal.svg';

export const agentAchievementIcons = {
  'Completed Profile': <CompletedProfile />,
  '3 Created Listings': <Created3Listings />,
  '7 Created Listings': <Created7Listings />,
  '10 Created Listings': <Created10Listings />,
  '3 Shared Listings': <Shared3 />,
  '7 Shared Listings': <Shared7 />,
  '10 Shared Listings': <Shared10 />,
  '3 Saved Listings': <Saved3 />,
  '7 Saved Listings': <Saved7 />,
  '10 Saved Listings': <Saved10 />,
  '3 Submitted Offers': null,
  '7 Submitted Offers': null,
  '10 Submitted Offers': null,
  '3 Requested Visits': <Requested3Visits />,
  '7 Requested Visits': <Requested7Visits />,
  '10 Requested Visits': <Requested10Visits />,
  'Newsletter Signed Up': null,
  '3 Shared Members': <Shared3 />,
  '7 Shared Members': <Shared7 />,
  '10 Shared Members': <Shared10 />,
  '3 Saved Members': <Saved3 />,
  '7 Saved Members': <Saved7 />,
  '10 Saved Members': <Saved10 />,
  '3 Contacted Members': null,
  '7 Contacted Members': null,
  '10 Contacted Members': null,
  '3 Submitted Reviews': null,
  '7 Submitted Reviews': null,
  '10 Submitted Reviews': null,
  '3 Accepted Offers': <Accepted3Offers />,
  '7 Accepted Offers': <Accepted7Offers />,
  '10 Accepted Offers': <Accepted10Offers />,
  '3 Countered Offers': <Counter3Offers />,
  '7 Countered Offers': <Counter7Offers />,
  '10 Countered Offers': <Counter10Offers />,
  '3 Invited Users': null,
  '7 Invited Users': null,
  '10 Invited Users': null,
  '3 Assigned Tasks': null,
  '7 Assigned Tasks': null,
  '10 Assigned Tasks': null,
  '3 Uploaded Documents': null,
  '7 Uploaded Documents': null,
  '10 Uploaded Documents': null,
  '3 Closed Deals': null,
  '7 Closed Deals': null,
  '10 Closed Deals': null,
};

export const nonAgentAchievementIcons = {
  'Completed Profile': <CompletedProfileNonAgent />,
  '10 Shared Listings': <ShareNonAgent />,
  '10 Saved Listings': <SaveNonAgent />,
  '10 Submitted Offers': <SubmitOffer />,
  '10 Requested Visits': <RequestedVisit />,
  'Newsletter Signed Up': <NewsletterSignUp />,
  '10 Shared Members': <ShareNonAgent />,
  '10 Saved Members': <SaveNonAgent />,
  '10 Contacted Members': <NewsletterSignUp />,
  '10 Submitted Reviews': <SubmitReview />,
  '10 Accepted Offers': <AcceptOffer />,
  '10 Countered Offers': <CounterOffer />,
  '10 Invited Users': <InvitedUsers />,
  '10 Assigned Tasks': <InvitedUsers />,
  '10 Uploaded Documents': <UploadDocuments />,
  '10 Closed Deals': <CloseDeal />,
};
