import { ReactComponent as EditIcon } from 'assets/icons/user-image-edit.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Container,
  Error,
  Input,
  InputContainerWithIcon,
  InputWrapper,
  Label,
  PlusSign,
} from './form-input.styles';

/**
 * Render form input.
 *
 * @param {Object}   props                         The component props.
 * @param {string}   [props.error=null]            Error message.
 * @param {string}   [props.id=null]               Input ID.
 * @param {string}   [props.label=null]            Label text.
 * @param {node}     [props.labelIconElement=null] Label Icon.
 * @param {string}   props.name                    Input name.
 * @param {Function} [props.onChange = null]       The on change event.
 * @param {string}   [props.placeholder='']        Input placeholder.
 * @param {Function} [props.register=null]         The react-hook-form register function.
 * @param {boolean}  [props.rounded=true]          Is input borderers rounded.
 * @param {string}   [props.type='text']           Input type.
 * @param {string}   [props.readonly=false]        readonly input
 * @param {string}   [props.onChangeValue=null]    onChangeValue function
 * @param {Boolean} [props.icon] add icon if needed.
 *  @param {boolean} [props.showMore] show Modal if true.
 * @param {Function} [props.setShowMore] set show more value.
 *
 * @return {JSX.Element}
 */
function FormInput({
  error,
  id,
  label,
  name,
  onChange,
  placeholder,
  register,
  rounded,
  type,
  min,
  max,
  maxLength,
  step,
  labelIconElement,
  defaultValue,
  required,
  disabled,
  onClickInputKey,
  withPrefix,
  readonly,
  onChangeValue,
  icon,
  setShowMore,
  showMore,
  ...props
}) {
  return (
    <Container>
      {label && (
        <Label htmlFor={id || name}>
          {labelIconElement}
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <InputWrapper rounded={rounded} error={error} disabled={disabled}>
        {withPrefix && <PlusSign>$</PlusSign>}
        <InputContainerWithIcon>
          <Input
            {...(register ? register(name) : {})}
            id={id || name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            min={min}
            max={max}
            maxLength={maxLength}
            step={step}
            defaultValue={defaultValue}
            disabled={disabled}
            onKeyDown={onClickInputKey}
            withPrefix={withPrefix}
            readOnly={readonly}
            onKeyUp={(e) => onChangeValue(e.target.value)}
            {...props}
          />
          {icon && (
            <EditIcon
              onClick={() => {
                setShowMore(!showMore);
              }}
            />
          )}
        </InputContainerWithIcon>
      </InputWrapper>

      {error && !error.includes('required') && <Error>{error}</Error>}
    </Container>
  );
}

FormInput.propTypes = {
  defaultValue: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  labelIconElement: PropTypes.node,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  onClickInputKey: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  required: PropTypes.bool,
  rounded: PropTypes.bool,
  setShowMore: PropTypes.func,
  showMore: PropTypes.bool,
  step: PropTypes.string,
  type: PropTypes.string,
  withPrefix: PropTypes.bool,
};

FormInput.defaultProps = {
  defaultValue: null,
  disabled: false,
  error: null,
  icon: false,
  id: null,
  label: null,
  labelIconElement: null,
  max: null,
  maxLength: null,
  min: null,
  onChange: () => {},
  onChangeValue: () => {},
  onClickInputKey: () => {},
  placeholder: '',
  readonly: false,
  register: null,
  required: false,
  rounded: true,
  setShowMore: () => {},
  showMore: false,
  step: null,
  type: 'text',
  withPrefix: false,
};

export default FormInput;
