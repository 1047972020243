import { useEffect } from 'react';

/**
 * hook that used to check if user click outside the opened component
 *
 * @param {Ref} ref component reference
 * @return {removeEventListener} to remove event listener before closing component
 */

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
