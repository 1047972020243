import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Service that get user notifications
 *
 * @param {String} id  user id
 *
 * @param {Number} limit notifications per page
 *
 * @param {Number} page  page number
 *
 * @param {String} pending notification status
 * @return {Array} user notifications
 */
export const getNotification = async (id, limit, page, pending = '') =>
  http
    .get(`${apiUrl}/notifications/user/push`, {
      params: {
        limit,
        page,
        pending,
        sortBy: 'date',
      },
    })
    .then((res) => res.data);

/**
 * Service that get user unread notifications count
 *
 * @param {String} id  user id
 *
 * @return {Array} user notifications
 */
export const getUnreadNotificationCount = async () =>
  http.get(`${apiUrl}/notifications/user/unread`).then((res) => res.data.data);

/**
 * Service that get user invitations
 *
 * @param {String} id  user id
 *
 * @param {String} status  new notification status
 */
export const updateNotificationStatus = async (id, status) =>
  http.patch(`${apiUrl}/notifications/${id}`, {
    status,
  });

/**
 * Service that get user invitations
 *
 * @param {String} id  user id
 *
 */
export const markAllNotificationsAsRead = async () =>
  http.post(`${apiUrl}/notifications/user/read`);
