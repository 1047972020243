import { RequiredStar } from 'components/shared/DropdownMenu/dropdown-menu.styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import colors from 'styles/colors';
import { customSelectTheme } from 'utils/helpers';
import CustomTransactionLabel from '../CustomTransactionLabel';
import CustomTransactionSelect from '../CustomTransactionSelect';
import { ErrorMessage } from '../DropdownMenu/dropdown-menu.styles';
import MenuInviteMessage from '../MenuInviteMessage';
import {
  InputLabel,
  LabelText,
  SelectContainer,
} from './transaction-select-input.styles';

const themeColors = {
  dangerLight: colors.alabaster,
  error: colors.red,
  primary: colors.green,
  primary25: colors.alabaster,
};

/**
 * Transaction Select Input component
 *
 * @param {Array} options select options
 * @param {Object} control useForm control object
 * @param {String} label input label
 * @param {node} labelIcon input label icon
 * @param {String} name input name
 * @param {String} placeholder input placeholder
 * @param {Function} handleInputChange handle input change function
 * @param {Function} setValue useForm set value function
 * @param {Function} setModalData modal context function
 * @param {String} error useForm error message
 * @param {Boolean} forInvite is the select have invitation
 * @param {Boolean} rounded is the select input rounded
 * @param {Boolean} valueLabelPair value label pair
 * @param {Boolean} isTransactionInput is the input in transaction process
 * @param {Object} defaultValue default select value
 * @param {Function} onChangeValue onchange function
 * @param {String} menuPosition menuPosition (fixed or absolute)
 * @param {String} inviteModalRole user role that sent to invite modal
 * @param {Boolean} isDisabled is the input disabled
 * @param {String} address transaction address
 *
 * @return {JSX.Element}
 */
function TransactionSelectInput({
  options,
  control,
  label,
  labelIcon,
  name,
  placeholder,
  handleInputChange,
  setValue,
  setModalData,
  error,
  forInvite,
  rounded,
  defaultValue,
  onChangeValue,
  menuPosition,
  inviteModalRole,
  hideInvite,
  isTransactionInput,
  isDisabled,
  address,
  required,
}) {
  return (
    <div>
      <SelectContainer
        noOptions={options.length > 0}
        rounded={rounded}
        required={required}
        error={error}
      >
        {label && (
          <InputLabel>
            {labelIcon}
            <LabelText>
              {label}
              {label.toLowerCase() !== 'name of new referral' &&
                label.toLowerCase() !== 'refer to reverifi member' &&
                ':'}
            </LabelText>
            {required && <RequiredStar>*</RequiredStar>}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              defaultValue={defaultValue}
              components={
                hideInvite
                  ? null
                  : forInvite
                  ? {
                      NoOptionsMessage: MenuInviteMessage,
                      Option: CustomTransactionLabel,
                      SingleValue: CustomTransactionSelect,
                    }
                  : {
                      Option: CustomTransactionLabel,
                      SingleValue: CustomTransactionSelect,
                    }
              }
              menuPortalTarget={document.body}
              menuShouldBlockScroll
              menuPosition={menuPosition}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: isTransactionInput ? 999 : 999999,
                }),
              }}
              hideSelectedOptions={false}
              options={options}
              isDisabled={isDisabled}
              placeholder={placeholder}
              value={value}
              onChange={(val) => {
                onChange(val);
                onChangeValue(val);
              }}
              onInputChange={(val, { action }) => {
                if (forInvite) {
                  handleInputChange(val, name);
                  // prevent clear input when not focused
                  if (action !== 'set-value' && val) {
                    setValue(name, { id: val, name: val });
                    setModalData((prev) => ({
                      ...prev,
                      type: inviteModalRole || label,
                      val,
                    }));
                  }
                }
              }}
              className="transaction-select"
              classNamePrefix="transaction"
              theme={(theme) => customSelectTheme(theme, themeColors, error)}
              isClearable
              isSearchable
              getOptionLabel={(option) =>
                option.name ? option.name : option.label
              }
              getOptionValue={(option) =>
                option.id ? option.id : option.value
              }
              type={inviteModalRole || label}
              roleName={label}
              address={address}
            />
          )}
        />
      </SelectContainer>
      {control && (
        <ErrorMessage>
          {!error?.includes('Required') && !error?.includes('object')
            ? error
            : ''}
        </ErrorMessage>
      )}
    </div>
  );
}

TransactionSelectInput.defaultProps = {
  address: null,
  defaultValue: null,
  error: null,
  forInvite: true,
  handleInputChange: () => {},
  hideInvite: false,
  inviteModalRole: null,
  isDisabled: false,
  isTransactionInput: false,
  label: null,
  labelIcon: null,
  menuPosition: 'absolute',
  onChangeValue: () => {},
  placeholder: null,
  required: false,
  rounded: true,
  setModalData: () => {},
  setValue: () => {},
};

TransactionSelectInput.propTypes = {
  address: PropTypes.string,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.string,
  forInvite: PropTypes.bool,
  handleInputChange: PropTypes.func,
  hideInvite: PropTypes.bool,
  inviteModalRole: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTransactionInput: PropTypes.bool,
  label: PropTypes.string,
  labelIcon: PropTypes.node,
  menuPosition: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rounded: PropTypes.bool,
  setModalData: PropTypes.func,
  setValue: PropTypes.func,
};

export default TransactionSelectInput;
