import React from 'react';
import PropTypes from 'prop-types';
import FilesList from 'components/shared/FilesList';
import UploadInput from 'components/shared/UploadInput';
import {
  UploadInputWrapper,
  Attachment,
  InputInterface,
  UploadIcon,
  BrowseSpan,
} from './upload-one-file.style';

/**
 * upload one file component
 *
 * @param {Array} file array of files
 * @param {Function} handleAddFile add file function
 * @param {Function} handleDeleteImage remove file function
 * @param {Boolean} chatBotStyle is chat bot Style
 *
 * @return {JSX.Element}
 */
function UploadOneFile({
  file,
  handleAddFile,
  handleDeleteImage,
  chatBotStyle,
}) {
  return (
    <UploadInputWrapper>
      <Attachment chatBotStyle={chatBotStyle}>Upload Attachment</Attachment>
      {!file.length && (
        <UploadInput onAddFiles={(newFile) => handleAddFile(newFile)}>
          <InputInterface>
            <UploadIcon />
            <span>Drag and drop here</span>
            <span>or</span>
            <BrowseSpan>browse</BrowseSpan>
          </InputInterface>
        </UploadInput>
      )}
      {file?.length > 0 && (
        <FilesList
          files={file}
          onDelete={handleDeleteImage}
          headerText=""
          filesLimit={1}
          isSubmitOffer
        />
      )}
    </UploadInputWrapper>
  );
}

export default UploadOneFile;

UploadOneFile.propTypes = {
  chatBotStyle: PropTypes.bool,
  file: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleAddFile: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
};

UploadOneFile.defaultProps = {
  chatBotStyle: false,
};
