import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

export const Labels = styled.div`
  display: flex;
  justify-content: ${({ hasLabel }) =>
    hasLabel ? 'space-between' : 'flex-end'};
`;

export const LimitMessage = styled.span`
  color: ${({ color }) => color || colors.gray};
  font-size: 0.75rem;
  align-self: flex-end;
  margin-bottom: 0.18rem;
`;

export const Input = styled.textarea`
  background-color: ${colors.white};
  border-radius: ${({ rounded }) => (rounded ? '1.20rem' : '0.4rem')};
  border: ${({ error }) =>
    error ? `0.06rem solid ${colors.red}` : `0.06rem solid ${colors.mercury}`};
  height: ${({ height }) => height || '10rem'};
  padding: 0.625rem;
  resize: none;
  width: 100%;
  font-family: inherit;

  ::placeholder {
    color: ${colors.gray}B8;
  }

  &:focus {
    outline: ${({ error }) =>
      error ? `0.06rem solid ${colors.red}` : `0.06rem solid ${colors.green}`};
  }
`;

export const SubTitleText = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${colors.gray};
  padding-left: 0.25rem;
  display: none;

  ${mq.desktop`
    display: inline
  `};
`;

export const SubTitleMobileText = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${colors.gray};
  display: inline;
  margin: 0.5rem 0;

  ${mq.desktop`
    display: none
  `};
`;
