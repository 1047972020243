import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const AchievementsNotificationContext = createContext();
AchievementsNotificationContext.displayName = 'AchievementsNotificationContext';

/**
 * App achievements notification context provider component.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
export function AchievementsNotificationProvider({ children }) {
  const [notificationItems, setNotificationItems] = useState([]);
  const [isRoleChanges, setIsRoleChanges] = useState(false);
  const { isLoggedIn } = useUser();

  const value = useMemo(() => {
    const changeRole = () => {
      setNotificationItems([]);
      setIsRoleChanges(!isRoleChanges);
    };

    const addNotification = (items) => {
      setNotificationItems(items);
    };

    const removeNotification = (itemId) => {
      setNotificationItems(notificationItems.filter(({ id }) => id !== itemId));
    };

    return {
      addNotification,
      changeRole,
      isRoleChanges,
      notificationItems,
      removeNotification,
    };
  }, [isRoleChanges, notificationItems]);

  useEffect(() => {
    if (!isLoggedIn) setNotificationItems([]);
  }, [isLoggedIn]);

  return (
    <AchievementsNotificationContext.Provider value={value}>
      {children}
    </AchievementsNotificationContext.Provider>
  );
}

AchievementsNotificationProvider.propTypes = {
  children: PropTypes.node,
};

AchievementsNotificationProvider.defaultProps = {
  children: null,
};

export const useAchievementsNotifications = () =>
  useContext(AchievementsNotificationContext);
