const { REACT_APP_API_URL, REACT_APP_GOOGLE_MAP } = process.env;

/**
 * The backend API base URL.
 */
export const apiUrl = `${REACT_APP_API_URL}/api`;

/**
 * The backend server URL.
 */
export const serverUrl = REACT_APP_API_URL;

/**
 * Google map API key
 */
export const googleMapApiKey = REACT_APP_GOOGLE_MAP;

export const claimAddressBar = true;

export const environment = process.env.REACT_APP_ENVIRONMENT;
