import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.2rem;
  justify-content: center;
  width: 100%;
`;

export const ListedUsersWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: 0.0625rem solid ${colors.mercury};
  cursor: pointer;
  height: 3.5rem;
  border-radius: 0.94rem;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  font-size: 0.85rem;
  font-weight: 600;

  &:hover {
    background-color: ${colors.veryLightGreen};
  }
`;

export const SearchWrapper = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 2.06rem;
  border-radius: 2.19rem;
  background: ${colors.midGray};
  margin-top: 1rem;
  gap: 0.7rem;
  padding-left: 1rem;
  cursor: text;
`;
