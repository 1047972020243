import avatar from 'assets/listing-agent-image.png';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { components } from 'react-select';
import {
  CompanyName,
  LabelName,
  Parenthesis,
  ProfilePic,
  Wrapper,
} from './custom-transaction-label.style';

/**
 * Custom label for transaction select input.
 *
 * @param {Object} props
 * @param {Object} data Label data.
 *
 * @return {JSX.Element}
 */
function CustomTransactionLabel({ data, ...props }) {
  const [brokenImage, setBrokenImage] = useState(false);
  const { Option } = components;
  const { name, company, image } = data;

  const onError = () => {
    setBrokenImage(true);
  };

  return (
    <Option {...props}>
      <Wrapper>
        {'image' in data && (
          <ProfilePic
            onError={onError}
            src={!brokenImage ? image || avatar : avatar}
          />
        )}
        <LabelName>{name || data.label}</LabelName>
        {company && (
          <>
            <Parenthesis>(</Parenthesis>
            <CompanyName>{company}</CompanyName>
            <Parenthesis>)</Parenthesis>
          </>
        )}
      </Wrapper>
    </Option>
  );
}

CustomTransactionLabel.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any),
};

CustomTransactionLabel.defaultProps = {
  data: {},
  props: {},
};

export default CustomTransactionLabel;
