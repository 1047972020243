import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { getUserData } from 'services/user';
import { fetchUserRolesAndListings } from 'utils/helpers';

export const IsAgentContext = createContext();

IsAgentContext.displayName = 'IsAgentContext';

/**
 * is agent context to check if user agent or buy a listing
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function IsAgentProvider({ children }) {
  const [isAgent, setIsAgent] = useState(true);

  const checkIsAgent = async (id, checkAgent = false) => {
    if (id) {
      const { user } = await getUserData(id);
      const agent = fetchUserRolesAndListings(user?.roles, checkAgent);
      return agent;
    }

    return true;
  };

  const value = useMemo(
    () => ({ checkIsAgent, isAgent, setIsAgent }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAgent]
  );

  return (
    <IsAgentContext.Provider value={value}>{children}</IsAgentContext.Provider>
  );
}

IsAgentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useIsAgent = () => useContext(IsAgentContext);

export default IsAgentProvider;
