import { useEffect, useState } from 'react';

/**
 * hook that used to choose the screen size.
 *
 * @param {Ref} ref component reference
 * @return {removeEventListener} to remove event listener before closing component
 */

const useIsMobileView = () => {
  const [isMobile, setIsMobile] = useState(false);

  /**
   * function choose the screen size
   *
   * @return {Boolean}
   */ const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  return isMobile;
};

export default useIsMobileView;
