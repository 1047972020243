/**
 * Get path and append truthy query parameters.
 *
 * @param {string} path The base path for the URL.
 * @param {Object} queryParams An object containing query parameters as key-value pairs.
 * @returns {string} URL string.
 */
export const getPathWithParams = (path, queryParams) => {
  const url = new URL(window.location.origin + path);

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (value) {
      url.searchParams.set(key, value);
    }
  });

  return url.pathname + url.search;
};

export default getPathWithParams;
