import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import debounce from 'utils/debounce';
import { getScrollbarWidth } from 'utils/window';
import { OverLay, StyledMenu } from './menu.styles';

/**
 * Renders anchored Menu.
 *
 * @param {Object} anchorRef Anchor element reference.
 * @param {boolean} isOpen State of the menu.
 * @param {JSX.Element} children Children element.
 * @param {Function} onClose On close handler.
 * @param {boolean} [isSticky=false] Is anchor sticky.
 * @param {boolean} overlayOnOpen Is Overlay.
 * @param {boolean} isPros Is pros.
 *
 * @return {JSX.Element}
 */
function Menu({
  anchorRef,
  isOpen,
  children,
  onClose,
  spacingToRight,
  isPros,
  overlayOnOpen,
}) {
  const [anchorCoords, setAnchorCoords] = useState({});

  useLayoutEffect(() => {
    const updateCoords = () =>
      setAnchorCoords(anchorRef?.current?.getBoundingClientRect());

    updateCoords();
    const debouncedUpdateCoords = debounce(updateCoords, 50);

    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateCoords();
    });

    if (isOpen) {
      resizeObserver.observe(document.documentElement);
    }
    return () => resizeObserver.disconnect();
  }, [isOpen, anchorRef]);

  return (
    <>
      <StyledMenu
        right={anchorCoords.right + getScrollbarWidth() + spacingToRight}
        withoutUpperSpace={isPros}
        isOpen={isOpen}
      >
        {children}
      </StyledMenu>
      <OverLay
        isOpen={isOpen}
        onClick={onClose}
        overlayOnOpen={overlayOnOpen}
      />
    </>
  );
}

Menu.propTypes = {
  anchorRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPros: PropTypes.bool,
  onClose: PropTypes.func,
  overlayOnOpen: PropTypes.bool,
  spacingToRight: PropTypes.number,
};

Menu.defaultProps = {
  isPros: false,
  onClose: () => {},
  overlayOnOpen: true,
  spacingToRight: 0,
};

export default Menu;
