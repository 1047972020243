import * as yup from 'yup';

const nameValidator = yup.object().required('Required');

const referralFormSchema = yup.object({
  email: yup.string().label('Email').email().nullable(true),
  isPreApproved: yup.boolean().label('Pre-Approval Letter').nullable(true),
  phone: yup
    .string()
    .label('Phone')
    .matches(/^(\S+)/, 'Phone number is required')
    .nullable(true)
    .test('len', 'Phone number must be at least 10 digits', (val) =>
      val ? val.toString()?.length === 16 : true
    )
    .transform((v) => (v === '' ? null : v)),
  referee: nameValidator,
  username: nameValidator,
});

export default referralFormSchema;
