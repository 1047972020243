/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { getUnreadMessagesCount } from 'services/messages';
import { useUser } from 'contexts/UserContext';
import { useNotification } from 'contexts/NotificationSocketContext';

export const MessagesContext = createContext();

MessagesContext.displayName = 'MessagesContext';

/**
 * Used to share data between messages component.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function Messages({ children }) {
  const { isLoggedIn } = useUser();
  const { socket } = useNotification();
  const [openedChatId, setOpenedChatId] = useState('');
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isMessageUpdated, setIsMessageUpdated] = useState(false);
  const [isNewMessageSent, setIsNewMessageSent] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [path, setPath] = useState();
  const [newMessageReceiverId, setNewMessageReceiverId] = useState();
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const getUnreadMessages = async () => {
    if (!isLoggedIn) return;
    const data = await getUnreadMessagesCount();
    setUnreadMessagesCount(data.data);
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    socket?.on(`new_message`, () => {
      getUnreadMessages();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const value = useMemo(
    () => ({
      getUnreadMessages,
      isChatBotOpen,
      isMessageOpen,
      isMessageUpdated,
      isNewMessageSent,
      isRedirect,
      newMessageReceiverId,
      openedChatId,
      path,
      setIsChatBotOpen,
      setIsMessageOpen,
      setIsMessageUpdated,
      setIsNewMessageSent,
      setIsRedirect,
      setNewMessageReceiverId,
      setOpenedChatId,
      setPath,
      setUnreadMessagesCount,
      unreadMessagesCount,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isMessageOpen,
      isMessageUpdated,
      isNewMessageSent,
      newMessageReceiverId,
      isChatBotOpen,
      isRedirect,
      openedChatId,
      unreadMessagesCount,
      path,
    ]
  );

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
}

Messages.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMessages = () => useContext(MessagesContext);

export default Messages;
