import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AgentPhoto from 'assets/icons/agent-list-avatar-placeholder.svg';
import { ReactComponent as GreenCloseIcon } from 'assets/icons/green-close-icon.svg';
import { ReactComponent as SendMessageIcon } from 'assets/send-message.svg';
import TextAreaInput from 'components/shared/FormTextArea';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useMessages } from 'contexts/MessagesContext';
import { useNotification } from 'contexts/NotificationSocketContext';
import { useUser } from 'contexts/UserContext';
import { getMessagesById, sendPrivetMessage } from 'services/private-messages';
import { getUserData } from 'services/user';
import { getTimeAndDateInterval, toUpperCaseFirstLetter } from 'utils/helpers';
import {
  ArrowsWrapper,
  DownArrow,
  UpArrow,
} from 'components/chat-bot/chat-bot.style';
import {
  CloseIconWrapper,
  CompanyName,
  Content,
  DetailsWrapper,
  Header,
  Message,
  MessagesWrapper,
  MessageTime,
  MessageWrapper,
  NoMessages,
  SendForm,
  StyledImg,
  Username,
  Wrapper,
  ChatBotMessagesWrapper,
  ChatBotSendButton,
} from './opened-chat.style';
/**
 * Opened Chats component.
 *
 * @param {Boolean} isCollapsed is collapsed
 *
 * @return {JSX.Element}
 */
function OpenedChats({ isCollapsed }) {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newUserName, setNewUserName] = useState();
  const [newUserPhoto, setNewUserPhoto] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const { userInfo, isLoggedIn } = useUser();
  const { socket } = useNotification();
  const [minimize, setMinimize] = useState(isCollapsed);
  const messagesRef = useRef(null);

  const {
    openedChatId,
    setIsMessageOpen,
    setIsMessageUpdated,
    isMessageUpdated,
    newMessageReceiverId,
    setNewMessageReceiverId,
    isRedirect,
    setIsRedirect,
  } = useMessages();

  const stateRef = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const submit = async ({ message }) => {
    if (!message?.trim()) return;
    setIsMessageUpdated(false);
    await sendPrivetMessage({
      content: message,
      receiverId: newMessageReceiverId || openedChatId,
    });

    reset();
    setIsMessageSent(!isMessageSent);
    setIsMessageUpdated(true);
  };

  const submitOnEnter = async (message) => {
    if (!message?.trim()) return;
    setIsMessageUpdated(false);
    await sendPrivetMessage({
      content: message,
      receiverId: newMessageReceiverId || openedChatId,
    });

    reset();
    setIsMessageSent(!isMessageSent);
    setIsMessageUpdated(true);
  };

  const handleKeyDown = (event) => {
    handleSubmit(submitOnEnter(event));
  };

  stateRef.current = newMessageReceiverId || openedChatId;
  const fetchMessages = async () => {
    if (stateRef.current) {
      try {
        const data = await getMessagesById(stateRef.current);
        setMessages(data);
        setNewUserName();
        setNewUserPhoto();
      } catch (err) {
        const userData = await getUserData(stateRef.current);
        setMessages([]);
        setNewUserName(userData?.user?.name);
        setNewUserPhoto(userData?.user?.userInfo?.image);
      }
      setIsMounted(!isMounted);
      setIsLoading(false);
      if (messagesRef.current) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }
  };

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageReceiverId, openedChatId, isMessageUpdated, isRedirect]);

  useEffect(() => {
    if (!isLoggedIn) return;
    socket?.on(`new_message`, () => {
      fetchMessages();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const isSeen = () => {
    let resultIndex;
    messages?.messages.forEach((message, index) => {
      if (message?.createdBy === userInfo?.id && message?.isRead) {
        resultIndex = index;
      }
    });
    return resultIndex;
  };

  const renderMessageContent = (content) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;

    const splitMessage = content.split(linkRegex);
    return splitMessage.map((part, index) => {
      if (part.match(linkRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    (newMessageReceiverId || openedChatId) &&
    (isLoading ? (
      <LoadingSpinner
        size={80}
        loading={isLoading}
        margin="7.5rem auto"
        align="center"
      />
    ) : (
      <Wrapper isCollapsed={minimize}>
        <Header>
          <StyledImg
            onClick={() => {
              navigate(`/member-details/${messages.receiver?.id}`);
              setIsRedirect(true);
              setIsMessageOpen(false);
              setNewMessageReceiverId(false);
            }}
            defaultImg={
              !messages.receiver?.userInfo?.thumbnailImage ||
              !messages.receiver?.userInfo?.image
            }
            src={
              newUserPhoto ||
              messages.receiver?.userInfo?.thumbnailImage ||
              messages.receiver?.userInfo?.image ||
              AgentPhoto
            }
          />
          <DetailsWrapper>
            <Username
              isClickable
              onClick={() => {
                navigate(`/member-details/${messages.receiver?.id}`);
                setIsRedirect(true);
                setIsMessageOpen(false);
                setNewMessageReceiverId(false);
              }}
            >
              {toUpperCaseFirstLetter(newUserName || messages.receiver?.name)}
            </Username>
            {!minimize && messages.receiver?.userInfo?.company?.name && (
              <CompanyName>
                {messages.receiver?.userInfo?.company?.name}
              </CompanyName>
            )}
          </DetailsWrapper>
          <ArrowsWrapper
            onClick={() => {
              setMinimize(!minimize);
            }}
          >
            <DownArrow isCollapsed={minimize} />
            <UpArrow isCollapsed={minimize} />
          </ArrowsWrapper>

          <CloseIconWrapper
            onClick={() => {
              setIsMessageOpen(false);
              setNewMessageReceiverId();
            }}
          >
            <GreenCloseIcon />
          </CloseIconWrapper>
        </Header>

        <Content ref={messagesRef}>
          <MessagesWrapper>
            {messages?.messages?.length > 0 ? (
              messages?.messages.map((message, index) => (
                <>
                  <ChatBotMessagesWrapper
                    alignStart={message?.createdByUser?.id === openedChatId}
                  >
                    <MessageWrapper
                      alignStart={message?.createdByUser?.id === openedChatId}
                      key={message?.id}
                    >
                      <DetailsWrapper>
                        <Message>
                          <span>{renderMessageContent(message?.content)}</span>
                        </Message>
                      </DetailsWrapper>
                    </MessageWrapper>
                  </ChatBotMessagesWrapper>
                  <MessageTime
                    alignStart={message?.createdByUser?.id === openedChatId}
                  >
                    {getTimeAndDateInterval(message?.createdAt)}
                    {isSeen() === index && <div>Seen</div>}
                  </MessageTime>
                </>
              ))
            ) : (
              <NoMessages>No Messages</NoMessages>
            )}
          </MessagesWrapper>
        </Content>

        <SendForm onSubmit={handleSubmit(submit)}>
          <TextAreaInput
            name="message"
            placeholder="Type a message..."
            register={register}
            error={errors.message?.message}
            required
            onKeyDown={handleKeyDown}
          />

          <ChatBotSendButton ariaLabel="Submit" type="submit">
            <SendMessageIcon />
          </ChatBotSendButton>
        </SendForm>
      </Wrapper>
    ))
  );
}

OpenedChats.defaultProps = {
  isCollapsed: false,
};

OpenedChats.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default OpenedChats;
