import Footer from 'components/shared/Footer';
import Navbar from 'components/shared/Navbar';
import Sidebar from 'components/shared/Sidebar';
import { useMessages } from 'contexts/MessagesContext';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LayoutContainer from './admin-panel-layout.styles';

/**
 * HOC to wrap component with the side menu.
 *
 * @param {Object} props The component props.
 * @param {JSX.Element} props.children The child component to wrap.
 *
 * @return {JSX.Element}
 */
function AdminPanelLayout({ children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { openedChatId } = useMessages();

  return (
    <>
      <Navbar setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
      {!isExpanded && (
        <>
          <LayoutContainer>
            <Sidebar openedChatId={openedChatId} />
            {children}
          </LayoutContainer>
          <Footer />
        </>
      )}
    </>
  );
}

AdminPanelLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdminPanelLayout;
