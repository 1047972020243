import React, { useState } from 'react';
import { useUser } from 'contexts/UserContext';
import { useMessages } from 'contexts/MessagesContext';
import { ReactComponent as NewChat } from 'assets/write-message.svg';
import { ReactComponent as ChatsIcon } from 'assets/Chats.svg';
import { ReactComponent as ReferralsIcon } from 'assets/Referrals.svg';
import { ReactComponent as LeftArrow } from 'assets/left-arrow.svg';
import { ReactComponent as GreenCloseIcon } from 'assets/icons/green-close-icon.svg';
import useIsMobileView from 'hooks/use-is-mobile-view';
import OpenedChats from './opened-chat';
import ChatList from './chat-list';
import NewReferral from './referrals';
import AddNewMessage from './search-all';
import {
  Wrapper,
  UpperWrapper,
  Title,
  DownArrow,
  UpArrow,
  ArrowsWrapper,
  NewChatAndArrowWrapper,
  ChatsAndReferralsWrapper,
  Chats,
  Referrals,
  BodyWrapper,
  MessagesContainer,
  NewMessageWrapper,
  NewMessageIconsWrapper,
  CloseIconsWrapper,
} from './chat-bot.style';

/**
 * Chat bot component
 *
 * @return {JSX.Element}
 */
function ChatBot() {
  const { isLoggedIn } = useUser();
  const {
    isMessageOpen,
    newMessageReceiverId,
    setIsChatBotOpen,
    setIsMessageOpen,
  } = useMessages();
  const [minimize, setMinimize] = useState(false);
  const [chatsActiveTab, setChatsActiveTab] = useState(true);
  const [referralsActiveTab, setReferralsActiveTab] = useState(false);
  const [showSearchList, setShowSearchList] = useState(false);
  const [updatedSentReferrals, setUpdatedSentReferrals] = useState(false);
  const [updatedReceivedReferrals, setUpdatedReceivedReferrals] =
    useState(false);
  const isMobile = useIsMobileView();

  if (isMobile) {
    document.body.style.overflow = 'hidden';
    if (minimize) {
      document.body.style.overflow = 'auto';
    }
  }

  return (
    <>
      <Wrapper isCollapsed={minimize}>
        {isLoggedIn && (
          <>
            <UpperWrapper>
              <Title>
                {showSearchList && (
                  <NewMessageIconsWrapper>
                    <LeftArrow
                      onClick={() => {
                        setShowSearchList(false);
                      }}
                    />
                  </NewMessageIconsWrapper>
                )}
                Chat
              </Title>
              <NewChatAndArrowWrapper>
                <NewMessageIconsWrapper>
                  <NewChat
                    onClick={() => {
                      setShowSearchList(true);
                    }}
                  />
                </NewMessageIconsWrapper>
                <ArrowsWrapper
                  onClick={() => {
                    setMinimize(!minimize);
                  }}
                >
                  <DownArrow isCollapsed={minimize} />
                  <UpArrow isCollapsed={minimize} />
                </ArrowsWrapper>
                <CloseIconsWrapper
                  onClick={() => {
                    setIsChatBotOpen(false);
                    document.body.style.overflow = 'auto';
                  }}
                >
                  <GreenCloseIcon />
                </CloseIconsWrapper>
              </NewChatAndArrowWrapper>
            </UpperWrapper>
            {showSearchList ? (
              <NewMessageWrapper>
                <AddNewMessage />
              </NewMessageWrapper>
            ) : (
              <>
                <ChatsAndReferralsWrapper>
                  <Chats
                    isActive={chatsActiveTab}
                    onClick={() => {
                      setChatsActiveTab(true);
                      setReferralsActiveTab(false);
                    }}
                  >
                    <ChatsIcon />
                    Chats
                  </Chats>
                  <Referrals
                    isActive={referralsActiveTab}
                    onClick={() => {
                      setReferralsActiveTab(true);
                      setChatsActiveTab(false);
                      setIsMessageOpen(false);
                    }}
                  >
                    <ReferralsIcon />
                    Referrals
                  </Referrals>
                </ChatsAndReferralsWrapper>
                <BodyWrapper>
                  {chatsActiveTab && (
                    <MessagesContainer>
                      <ChatList />
                    </MessagesContainer>
                  )}
                  {referralsActiveTab && (
                    <NewReferral
                      updatedSentReferrals={updatedSentReferrals}
                      setUpdatedSentReferrals={setUpdatedSentReferrals}
                      setUpdatedReceivedReferrals={setUpdatedReceivedReferrals}
                      updatedReceivedReferrals={updatedReceivedReferrals}
                    />
                  )}
                </BodyWrapper>
              </>
            )}
          </>
        )}
      </Wrapper>

      {isLoggedIn && (isMessageOpen || newMessageReceiverId) && (
        <OpenedChats isCollapsed={minimize} />
      )}
    </>
  );
}

export default ChatBot;
