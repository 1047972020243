import PropTypes from 'prop-types';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import colors from 'styles/colors';
/**
 * Loading Skeleton component.
 *
 * @param {Object}  props component properties.
 * @param {String} props.height  loader height
 * @param {String} props.width loader width
 * @param {Boolean} props.circle is skeleton circle margin
 * @param {Number} props.count number of lines for skeleton.
 *
 * @returns {JSX.Element}
 */
function LoadingSkeleton({ height, width, circle, count }) {
  return (
    <SkeletonTheme
      baseColor={colors.charcoal}
      highlightColor={colors.dustyGray}
    >
      <Skeleton
        height={height}
        width={width}
        circle={circle || null}
        count={count}
      />
    </SkeletonTheme>
  );
}
LoadingSkeleton.defaultProps = {
  circle: false,
  count: 1,
  height: '100%',
  width: '100%',
};

LoadingSkeleton.propTypes = {
  circle: PropTypes.bool,
  count: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
};
export default LoadingSkeleton;
