import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import { SelectedValueName } from './custom-transaction-select.style';

/**
 * Custom selected value for transaction select input.
 *
 * @param {Object} props
 * @param {Object} data Selected value data.
 *s
 * @return {JSX.Element}
 */
function CustomTransactionSelect({ data, ...props }) {
  const { SingleValue } = components;
  const { name, value } = data;

  return (
    <SingleValue {...props}>
      <SelectedValueName>{name || value}</SelectedValueName>
    </SingleValue>
  );
}

CustomTransactionSelect.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  props: PropTypes.objectOf(PropTypes.any),
};

CustomTransactionSelect.defaultProps = {
  data: {},
  props: {},
};
export default CustomTransactionSelect;
