import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

export const NotificationSocketContext = createContext();

NotificationSocketContext.displayName = 'NotificationSocketContext';

/**
 * Used to share data from search in header to the search in the bottom of the page.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function NotificationSocket({ children }) {
  const [socket, setSocket] = useState(null);

  const value = useMemo(
    () => ({
      setSocket,
      socket,
    }),
    [socket]
  );

  return (
    <NotificationSocketContext.Provider value={value}>
      {children}
    </NotificationSocketContext.Provider>
  );
}

NotificationSocket.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotification = () => useContext(NotificationSocketContext);

export default NotificationSocket;
