import Button from 'components/shared/Button';
import { useAchievementsNotifications } from 'contexts/AchievementsNotificationContext/AchievementsNotificationContext';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Close from 'react-feather/dist/icons/x';
import { useNavigate } from 'react-router-dom';
import { achievementsData } from 'utils/constants';
import {
  CloseButton,
  Message,
  MessageLabel,
  NotificationMessage,
  NotificationWrap,
  Title,
} from './styles';
/**
 * function for AchievementsNotification component.
 *
 * @param {Object} props props passed to achievements notification.
 * @param {string} props.link navigation link.
 * @param {string} props.text message to display.
 * @param {string} props.points points to add.
 * @param {string} props.id achievement id.
 * @param {string} props.buttonText text to put on button.
 * @param {string} props.fontSize text font size.
 *
 * @return {JSX.Element}
 */
export function AchievementsNotification({
  link,
  text,
  points,
  id,
  buttonText,
  fontSize,
  name,
}) {
  const { removeNotification } = useAchievementsNotifications();
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const hide = () => {
    setShow(false);
  };

  const onAnimationEnd = () => {
    if (!show) {
      removeNotification(id);
    }
  };

  return (
    <NotificationWrap
      tabIndex="0"
      onAnimationEnd={onAnimationEnd}
      className={show ? 'in' : 'out'}
      role="alert"
    >
      <NotificationMessage>
        {(name && achievementsData[name].NotificationIcon) || (
          <Title>{achievementsData[name]?.title}</Title>
        )}
        <Message fontSize={fontSize}>
          {text} <MessageLabel>{points}</MessageLabel> Points!!
        </Message>
        <Button onClick={() => navigate(link)}>
          {buttonText || 'Click Here'}
        </Button>
        <CloseButton onClick={hide}>
          <Close size="16" strokeWidth="2" />
        </CloseButton>
      </NotificationMessage>
    </NotificationWrap>
  );
}

AchievementsNotification.propTypes = {
  buttonText: PropTypes.string,
  fontSize: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string,
  name: PropTypes.string,
  points: PropTypes.string,
  text: PropTypes.string,
};

AchievementsNotification.defaultProps = {
  buttonText: null,
  fontSize: null,
  id: null,
  link: null,
  name: null,
  points: null,
  text: null,
};

export default AchievementsNotification;
