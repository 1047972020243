import { apiUrl } from 'config/config';
import { DEFAULT_PAGE_LIMIT } from 'utils/constants';
import http from 'utils/http';

/**
 * get Featured Listings
 *
 * @param {String} typeId type id
 * @param {Number} page page number
 * @param {Number} limit limit
 *
 * @return {Promise<Object>} The featured listings response.
 */
export const getFeaturedListings = async (
  typeId,
  featuredListings = '',
  page = 1,
  limit = 100
) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/listings/featured?page=${page}&limit=${limit}&listingTypeId=${typeId}&featuredListings=${featuredListings}`
  );
  return data;
};

/**
 * get Listings By Search Key
 *
 * @param {string} keyWord the word to search for .
 * @param {Boolean} isLoggedIn is logged in
 * @param {Number} filter.page page number
 *
 * @return {Promise<Object>} listing data response.
 */
export const getListingsBySearchKey = async (
  keyWord,
  filter = {
    bedrooms: '',
    fullBathrooms: '',
    listingTypeId: '',
    max: '',
    min: '',
    page: 1,
    propertyTypeId: '',
  },
  searchKey = '',
  isLoggedIn = false
) => {
  const {
    min = '',
    max = '',
    propertyTypeId = '',
    bedrooms = '',
    fullBathrooms = '',
    listingTypeId = '',
    page = 1,
  } = filter;
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/listings/search${
      isLoggedIn ? '/auth' : ''
    }?key=${keyWord}&propertyTypeId=${propertyTypeId}&bedrooms=${bedrooms}&fullBathrooms=${fullBathrooms}&listingTypeId=${listingTypeId}&min=${min}&max=${max}&searchKey=${searchKey}&page=${page}`
  );

  return data;
};

/**
 * get listings by id
 *
 * @param {uuid} listing id.
 *
 * @return {Promise<Object>} listing data response.
 */
export const getListingsById = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/listings/listing/${id}`);

  return data;
};

/**
 * get user listings
 *
 * @param {String} listing id.
 * @param {Number} limit number of fetched listings
 * @param {Number} limit number of fetched listings
 * @param {Number} page page number
 *
 * @return {Promise<Object>} listing data response.
 */
export const getUserListings = async (
  id,
  limit,
  page = 1,
  order = 'DESC',
  transactionFilter = '',
  listingStatusFilter = '',
  orderExecluserLender = '',
  isLender = ''
) => {
  const {
    data: { data, count },
  } = await http.get(
    `${apiUrl}/listings/${id}?limit=${limit}&page=${page}&order=${order}&transactionFilter=${transactionFilter}&listingStatusFilter=${listingStatusFilter}&orderExecluserLender=${orderExecluserLender}&isLender=${isLender}`
  );

  return { count, data };
};

/* @param {uuid} listing id.
 *
 * @return {Promise<Object>} delete listing .
 */
export const deleteListingById = async (id) => {
  const {
    data: { data },
  } = await http.delete(`${apiUrl}/listings/${id}`);

  return data;
};

/**
 * Service that get all transactions.
 * @param {object} values transaction update data
 *
 * @return {Object[]} Array of processes.
 */
export const updateTransactionListing = async (values) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/listings/transaction/close`, values);

  return data;
};

/**
 * Service that add or update listing images
 *
 * @param {object} values transaction update data
 *
 * @return {Object[]} Array of processes.
 */
export const addOrUpdateListingImages = async (id, images) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/listings/images/${id}`, images);

  return data;
};

/**
 * Service that add or update listing claim
 *
 * @param {object} values transaction update data
 *
 * @return {Object[]} Array of processes.
 */
export const addOrUpdateListingClaim = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/claim-address`, body);

  return data;
};

/**
 * Service that get listing claim
 *
 * @param {String} id      listing id
 * @param {String} address address
 *
 * @return {Object[]} Array of claims.
 */
export const getListingClaim = async (id, address) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/claim-address?listingId=${id}&address=${encodeURIComponent(
      address
    )}`
  );

  return data;
};

/**
 * Service that get listing claim
 *
 * @param {String} id listing id
 *
 * @return {Object[]} Array of claims.
 */
export const getAllListingCities = async () => {
  const cities = [];
  const {
    data: { data },
  } = await http.get(`${apiUrl}/listings`);

  data?.forEach((city) => {
    if (city.address) cities.push(city.address);
  });
  return cities;
};

/**
 * Get All Listings Grouped By State
 *x
 * @return {Promise<Object>} All listings Grouped By State response.
 */
export const getAllListingsGroupedByState = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/listings/grouped-by-state`);
  return data;
};

/**
 * Service that get listing claim by id.
 *
 * @param {String} id listing claim id.
 *
 * @return {Object} claim data.
 */
export const getClaimById = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/claim-address/${id}`);

  return data;
};

/**
 * Service that get listings lender.
 *
 * @param {String} type user role.
 * @param {Number} page page.
 * @param {Number} limit limit.
 *
 * @return {Object} listing lenders data.
 */
export const getListingsLender = async (
  type,
  page = 1,
  limit = DEFAULT_PAGE_LIMIT
) => {
  const { data } = await http.get(
    `${apiUrl}/listings/lender/${type}?page=${page}&limit=${limit}`
  );

  return data;
};
