import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!window.location.href.includes('events?'))
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      }, 0);
  }, [pathname, searchParams]);
  return null;
}

export default ScrollToTop;
