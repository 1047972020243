import useEffectOnce from 'hooks/use-effect-once';
import { useEffect, useState } from 'react';
import { getPathWithParams } from 'utils/url';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getPlanById } from 'services/plans';
import { useUser } from 'contexts/UserContext';
import { removeLastSegment } from 'utils/helpers';
import { getLenderInvitationById } from 'services/lender-invitation';
import { getUserBid } from 'services/bid';
import { getRedeemProduct } from 'services/redeem-products';

// Custome hook returns array of the product id based on the queryParams
export const UseProductsParams = () => {
  const [searchParams] = useSearchParams();
  const planId = searchParams.get('pid');
  const bidId = searchParams.get('bid');
  const invitationId = searchParams.get('iid');
  const redeemProductId = searchParams.get('rid');
  return { bidId, invitationId, planId, redeemProductId };
};

// Custome hook returns product data based on the queryParams
export const UseProduct = () => {
  const [product, setProduct] = useState();
  const { planId, bidId, invitationId, redeemProductId } = UseProductsParams();
  const { userInfo } = useUser();
  const [checkCompleted, setCheckCompleted] = useState();
  const { activePlansId = [] } = userInfo || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getProductData = async () => {
    const notFound = '/404';
    try {
      if (!planId && !bidId && !invitationId && !redeemProductId) {
        return;
      }
      if (planId && bidId && invitationId && redeemProductId) {
        return;
      }

      if (
        (planId === '6' && activePlansId.includes(3)) ||
        (planId === '7' && activePlansId.includes(5))
      ) {
        navigate(notFound);
      }

      let productData;
      if (planId) {
        productData = await getPlanById(planId);
        const path = removeLastSegment(pathname);
        if (redeemProductId) {
          const redeemProduct = await getRedeemProduct({
            planId,
            redeemProductId,
          });
          productData = { ...productData, redeemProduct };
        }

        if (path === '/checkout' && (!productData || !productData.price)) {
          navigate(notFound);
        }
      }
      if (bidId) {
        productData = await getUserBid(bidId);
      }

      if (invitationId) {
        productData = await getLenderInvitationById(invitationId);
      }

      setProduct(productData);
    } catch (error) {
      navigate(notFound);
    }
  };

  useEffect(() => {
    if (!userInfo || checkCompleted) {
      return;
    }

    if (parseInt(planId, 10) === 2 && activePlansId.includes(3)) {
      navigate('/404');
    }

    if (!activePlansId.includes(parseInt(planId, 10))) {
      getProductData();
      setCheckCompleted(true);
      return;
    }

    navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlansId]);

  useEffectOnce(async () => {
    if (!userInfo) {
      await getProductData();
    }

    return () => {
      setCheckCompleted(false);
    };
  });
  return product;
};

// Custome hook return array of redirect function to navigate with product queryParams and check if the product params exist
export const UseProductUrl = () => {
  const { planId, bidId } = UseProductsParams();
  const { isLoggedIn } = useUser();

  const productsParmsExist = !!(planId || bidId);

  return [
    (path) => {
      const productsQueryParams = { bid: bidId, pid: planId };
      const url = getPathWithParams(path, productsQueryParams);
      window.location.assign(url);
    },
    productsParmsExist,
    (productId, free) => {
      const path = isLoggedIn ? '/checkout' : '/sign-up';
      const url = !free ? getPathWithParams(path, productId) : path;
      window.location.assign(url);
    },
  ];
};

export default UseProduct;
