import { apiUrl } from 'config/config';
import { toUpperCaseFirstLetter } from 'utils/helpers';
import http from 'utils/http';

/**
 * Service that update user roles
 *
 * @param {Array(String)} rolesIds roles ids array
 *
 * @return {Object[]} Array of roles.
 */
export const updateUserRoles = async (rolesIds) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/users/roles`, { roles: rolesIds });

  return data;
};

/**
 * Service that update user roles
 *
 * @param {Array(String)} rolesIds roles ids array
 *
 * @return {Object[]} Array of roles.
 */
export const getUserData = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/${id}`);
  return data;
};

/**
 * Service that update user info
 *
 * @param {Object} body data to be send as request body
 *
 * @return {Object[]} Array of info data.
 */
export const updateUserInfo = async (body) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/user-info`, body);

  return data;
};

/**
 * Service that get agent users
 *
 * @param {String} type agent type
 * @param {String} location agent location
 * @param {String} name agent name
 * @param {number} [page=0]
 *
 * @return {Object[]} Array of agents.
 */
export const getUsersByType = async (type, location, name, page = 0) => {
  const userType = type ? toUpperCaseFirstLetter(type) : 'All';

  const {
    data: { data, count },
  } = await http.get(
    `${apiUrl}/users/agents/${encodeURIComponent(
      userType
    )}?page=${page}&location=${location}&name=${name}`
  );

  return { count, data };
};

/**
 * Service that get agent users for logged in users
 *
 * @param {String} type agent type
 * @param {String} location agent location
 * @param {String} name agent name
 * @param {number} [page=0]
 *
 * @return {Object[]} Array of agents.
 */
export const getUsersByTypeAuth = async (type, location, name, page = 0) => {
  const userType = type ? toUpperCaseFirstLetter(type) : 'All';

  const {
    data: { data, count },
  } = await http.get(
    `${apiUrl}/users/agents/auth/${encodeURIComponent(
      userType
    )}?page=${page}&location=${location}&name=${name}`
  );

  return { count, data };
};

/**
 * Service that get users with limit
 *
 * @param {Number} limit number of users
 * @param {String} name agent name
 *
 * @return {Object[]} Array of users.
 */
export const getUsersWithLimit = async (limit, name) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/all/${limit}?name=${name}`);

  return data;
};

/**
 * Service that get users with roles and limit
 *
 * @param {Number} limit number of users
 * @param {String} name agent name
 * @param {String} role role type
 *
 * @return {Object[]} Array of users.
 */
export const getUsersWithNameAndRole = async (limit, name, role) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/users/name/role?limit=${limit}&name=${name}&role=${role}`
  );

  return data;
};

/**
 * Service that get user roles
 *
 * @return {Object[]} Array of roles.
 */
export const getUserRoles = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/roles`);

  return data;
};

/**
 * Service that get single user info
 *
 * @return {Object[]} Array of info data.
 */
export const getUserInfo = async (id) => {
  const path = id ? `/${id}` : '';

  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-info${path}`);

  return data;
};

/**
 * Service that get user details
 * @param {String} id user id
 *
 * @return {Object} Object of info data.
 */
export const getUserDetails = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-info/details/${id}`);

  return data;
};

/**
 * Service that get user details for logged in user
 * @param {String} id user id
 *
 * @return {Object} Object of info data.
 */
export const getUserDetailsAuth = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-info/details/auth/${id}`);

  return data;
};

/**
 * Service that add invited user
 *
 * @return {Object} invited user
 */
export const addInvitedUser = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/users/invited`, body);

  return data;
};

/**
 * Service that add invited user
 *
 * @return {Object} invited user
 */
export const updatePhoneNumber = async (body) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/users/phone`, body);

  return data;
};

/**
 * Get All Admins Data.
 *
 * @return {Promise<Object>} The get all events response.
 */
export const getAllAdminsData = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/admin/users`);
  return data;
};

export const getUserByEmail = async (email) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/email/${email}`);
  return data;
};

/**
 * Get user subscriptions.
 *
 * @returns {Promise<Array>} An array of user subscriptions.
 */
export const getUserSubscriptions = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-info/subscriptions`);
  return data;
};

/**
 * Update auto renewal for user subscription.
 *
 * @returns {Promise<Array>} An array of user subscriptions.
 */
export const updateAutoRenewal = async (body) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/users/subscription/auto-renewal`, body);
  return data;
};
