import { UseProductUrl } from 'components/payment-hooks';
import useEffectOnce from 'hooks/use-effect-once';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import auth from 'services/auth';
import { getUserListings } from 'services/listing';
import { getUserData, getUserInfo } from 'services/user';
import tokenUtil from 'utils/token';

export const UserContext = createContext({
  /**
   * Is user logged in.
   *
   * @type {string}
   */
  isLoggedIn: false,

  /**
   * Is user social registered.
   *
   * @type {string}
   */
  isSocialRegistered: false,

  /**
   * is user verified.
   *
   * @type {Object}
   */
  isVerified: undefined,

  /**
   * Login the user.
   *
   * @type {Function}
   */
  login: undefined,

  /**
   * Logout the user.
   *
   * @type {Function}
   */
  logout: undefined,

  /**
   * Sign up the user.
   *
   * @type {Function}
   */
  signUp: undefined,

  /**
   * User data.
   *
   * @type {Object}
   */
  userInfo: undefined,
});

UserContext.displayName = 'UserContext';

/**
 * User context provider to manage user auth operations.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
export function UserProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userInfo, setUserInfo] = useState();
  const [isSocialRegistered, setIsSocialRegistered] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isPhoneVerified, setIsPhoneVerified] = useState({});
  const [productRedirect, productParamsExist] = UseProductUrl();

  /**
   * Logout user.
   */
  const logout = useCallback(() => {
    tokenUtil.removeToken();
    setIsLoggedIn(false);
    setUserInfo(undefined);
  }, []);

  /**
   * redirect handler.
   *
   * @param {string} token User token.
   */
  const redirectHandler = useCallback(
    async (token, isSignUp) => {
      const { email, name, id } = tokenUtil.decodeToken(token) || {};

      // if token not decoded correctly consider the token is invalid and logout the user
      if (!(email && name)) {
        logout();
        return;
      }

      const { user } = await getUserData(id);

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const pageQuery = params.get('page');
      if (pageQuery === 'QB') {
        navigate('/qualified-borrower');
      } else if (user?.roles.length <= 0) {
        if (productParamsExist) {
          productRedirect('/checkout');
        } else {
          navigate('/my-roles');
        }
      } else if (!user?.phone || !user?.userInfo) {
        if (productParamsExist) {
          productRedirect('/checkout');
        } else {
          navigate('/my-profile');
        }
      } else {
        if (isSignUp && user?.phone) {
          productRedirect('/verify-phone');
          return;
        }

        navigate('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout]
  );

  /**
   * Update the state based on user token.
   *
   * @param {string} token User token.
   */
  const setTokenData = useCallback(
    async (token) => {
      const tokens = tokenUtil.decodeToken(token) || {};
      const {
        email,
        id,
        name,
        phone,
        points,
        createdAt,
        isSocialUser,
        isVerified,
        notifyPrimaryEmail,
        notifySecondEmail,
        secondEmail,
        userSubscriptions,
        activePlansId,
      } = tokens;
      // if token not decoded correctly consider the token is invalid and logout the user
      if (!(email && name)) {
        logout();
        return;
      }

      tokenUtil.setToken(token);
      setIsLoggedIn(true);
      setIsSocialRegistered(isSocialUser);
      try {
        const { user } = await getUserData(id);
        const listings = await getUserListings(id);

        setUserInfo({
          activePlansId,
          createdAt: user?.createdAt,
          email: user?.email,
          id: user?.id,
          isVerified: user?.isVerified,
          listings,
          name: user?.name,
          notifyPrimaryEmail: user?.notifyPrimaryEmail,
          notifySecondEmail: user?.notifySecondEmail,
          phone: user?.phone,
          points: user?.points,
          roles: user?.roles,
          secondEmail: user?.secondEmail,
          token,
          userSubscriptions,
        });
      } catch (err) {
        setUserInfo({
          activePlansId,
          createdAt,
          email,
          id,
          isVerified,
          name,
          notifyPrimaryEmail,
          notifySecondEmail,
          phone,
          points,
          secondEmail,
          token,
          userSubscriptions,
        });
      }

      const { image, thumbnailImage, nmls } = await getUserInfo();
      if (image)
        setUserInfo((prev) => ({ ...prev, image, nmls, thumbnailImage }));

      setUserInfo((prev) => ({ ...prev, ...isPhoneVerified }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout]
  );

  useEffect(() => {
    if (userInfo) {
      setLoading(false);
    }
  }, [userInfo, isPhoneVerified]);

  useEffect(() => {
    const saveProfile = async () => {
      if (isPhoneVerified) {
        const token = tokenUtil.getToken();
        setTokenData(token);
      }
    };
    saveProfile();
  }, [isPhoneVerified, setTokenData]);
  /**
   * Login or sign up Google user.
   *
   * @param {string} idToken Google user id token retrived from `react-social-login`.
   * @param {string} invitedBy User invited by id.
   * @param {string} eventId User event id.
   *
   * @return {boolean} isSocialSignUp
   */
  const googleLogin = useCallback(
    async (idToken, invitedBy, eventId) => {
      const { token } = await auth.googleLogin(idToken, invitedBy, eventId);
      setTokenData(token);
      redirectHandler(token);
      const { isSocialSignUp } = tokenUtil.decodeToken(token) || {};
      return isSocialSignUp;
    },
    [setTokenData, redirectHandler]
  );

  /**
   * Login or sign up Facebook user.
   *
   * @param {string} accessToken Facebook user access token retrived from `react-social-login`.
   * @param {string} invitedBy User invited by id.
   * @param {string} eventId User event id.
   *
   * @return {boolean} isSocialSignUp
   */
  const facebookLogin = useCallback(
    async (accessToken, invitedBy, eventId) => {
      const { token } = await auth.facebookLogin(
        accessToken,
        invitedBy,
        eventId
      );
      setTokenData(token);
      redirectHandler(token);

      const { isSocialSignUp } = tokenUtil.decodeToken(token) || {};
      return isSocialSignUp;
    },
    [setTokenData, redirectHandler]
  );

  /**
   * Login user.
   *
   * @param {string} email User email.
   * @param {string} password User password.
   */
  const login = useCallback(
    async (email, password) => {
      const { token } = await auth.login(email, password);
      setTokenData(token);
      redirectHandler(token);
    },
    [setTokenData, redirectHandler]
  );

  /**
   * Sign up new user.
   *
   * @param {string} name User name.
   * @param {string} email User email.
   * @param {string} password User password.
   * @param {string} phone User phone number.
   * @param {string} invitedBy User invited by id.
   * @param {string} eventId User event id.
   *
   */
  const signUp = useCallback(
    async (name, email, password, phone, invitedBy, eventId) => {
      const { token } = await auth.signUp(
        name,
        email,
        password,
        phone,
        invitedBy,
        eventId
      );

      setTokenData(token);
    },
    [setTokenData]
  );

  const updateToken = useCallback(async () => {
    if (!tokenUtil.getToken()) {
      return;
    }
    const { token } = await auth.updateToken();
    if (!token) {
      logout();
    }

    setTokenData(token);
  }, [setTokenData, logout]);

  useEffectOnce(async () => {
    updateToken();
  });

  const value = useMemo(
    () => ({
      facebookLogin,
      googleLogin,
      isLoggedIn,
      isPhoneVerified,
      isSocialRegistered,
      loading,
      login,
      logout,
      setIsPhoneVerified,
      setLoading,
      setUserInfo,
      signUp,
      updateToken,
      userInfo,
    }),
    [
      facebookLogin,
      googleLogin,
      isLoggedIn,
      isPhoneVerified,
      isSocialRegistered,
      loading,
      login,
      logout,
      signUp,
      userInfo,
      updateToken,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: null,
};

export const useUser = () => useContext(UserContext);
