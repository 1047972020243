/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable sort-keys */
import { ReactComponent as ListingsIcon } from 'assets/icons/create-listing-icon.svg';
import { ReactComponent as BecomeRealtor } from 'assets/icons/knowledgeCenter/become-realtor.svg';
import { ReactComponent as Construction } from 'assets/icons/knowledgeCenter/construction.svg';
import { ReactComponent as HomeBuying } from 'assets/icons/knowledgeCenter/home-buying.svg';
import { ReactComponent as HomeInsurance } from 'assets/icons/knowledgeCenter/home-insurance.svg';
import { ReactComponent as HomeSelling } from 'assets/icons/knowledgeCenter/home-selling.svg';
import { ReactComponent as Investing } from 'assets/icons/knowledgeCenter/investing.svg';
import { ReactComponent as Mortgage } from 'assets/icons/knowledgeCenter/mortgage.svg';
import { ReactComponent as PersonalDevelopment } from 'assets/icons/knowledgeCenter/personal-development.svg';
import { ReactComponent as RealEstateAgent } from 'assets/icons/knowledgeCenter/real-estate-agent.svg';
import { ReactComponent as RealEstateLaw } from 'assets/icons/knowledgeCenter/real-estate-law.svg';
import { ReactComponent as TitleInsurance } from 'assets/icons/knowledgeCenter/title-insurance.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profiles-icon.svg';
import { serverUrl } from 'config/config';

export const DEFAULT_PAGE_LIMIT = 8;
export const MEMBER_DIRECTORY_PAGE_LIMIT = 9;
export const timerMinutes = 120;

export const transactionStepsNames = {
  addParties: { name: 'Invite Participants', route: 'add-parties' },
  assignTasks: { name: 'Manage & Assign Tasks', route: 'assign-tasks' },
  closeDeal: { name: 'Finalize and Close Deal', route: 'close-deal' },
  uploadDocuments: { name: 'Upload Documents', route: 'upload-documents' },
};

export const roles = {
  lender: 'Mortgage Lender/Broker',
  agent: 'Real Estate Agent',
};

export const invitationStatus = {
  accepted: 'Accepted',
  declined: 'Declined',
  pending: 'Pending',
};

export const searchOptions = {
  componentRestrictions: { country: ['us'] },
};

export const transactionStatus = {
  canceled: 'canceled',
  closed: 'closed',
  inProgress: 'in progress',
};

// DON NOT change order of this object
export const transactionTaskStatus = Object.freeze({
  todo: 'To do',
  inProgress: 'In progress',
  completed: 'Completed',
});

export const transactionRoles = {
  buyer: 'Buyer',
  buyerAgent: 'Buyer Agent',
  buyerAttorney: 'Buyer Attorney',
  coordinator: 'Transaction Coordinator',
  homeInsurance: 'Home Insurance',
  lender: 'Lender',
  seller: 'Seller',
  listingAgent: 'Listing Agent',
  sellerAgent: 'Seller Agent',
  sellerAttorney: 'Seller Attorney',
  titleInsurance: 'Title Insurance',
  creator: 'Creator',
  AppraisalCo: 'Appraisal Management Co',
};

export const transactionRolesLabel = {
  Buyer: 'Buyer',
  'Buyer Agent': 'Buyer Agent',
  'Buyer Attorney': 'Buyer Real Estate Attorney',
  'Transaction Coordinator': 'Transaction Coordinator',
  'Home Insurance': 'Home Insurance',
  Lender: 'Mortgage Lender/Broker',
  Seller: 'Seller',
  'Listing Agent': 'Listing Agent',
  'Seller Agent': 'Seller Agent',
  'Seller Attorney': 'Seller Real Estate Attorney',
  'Title Insurance': 'Title Insurance Rep/Co',
  Creator: 'Creator',
  'Appraisal Management Co': 'Appraisal Management Co',
};

export const allAgentRoles = {
  seller: 'Seller',
  buyer: 'Buyer',
  homeInspector: 'Home Inspector',
  transactionCoordinator: 'Transaction Coordinator',
  homeInsurance: 'Home Insurance',
  realEstateAgent: 'Real Estate Agent',
  titleInsuranceRepCo: 'Title Insurance Rep/Co',
  mortgageLenderBroker: 'Mortgage Lender/Broker',
  realEstateAttorney: 'Real Estate Attorney',
  financialAdvisor: 'Financial Advisor',
  creditRepairSpecialist: 'Credit Repair Specialist',
  appraisalManagementCo: 'Appraisal Management Co',
  photographer: 'Photographer',
  nplBuyer: 'NPL Buyer',
  nplSeller: 'NPL Seller',
};

/**
 * Enum for action types.
 */
export const actionTypes = Object.freeze({
  closeDeal: 'Close deal',
  completeProfile: 'Complete profile',
  completeRegistration: 'Registration',
  createNewListing: 'New listing',
  dailyLogin: 'Daily login',
  shareListing: 'Share a listing',
  saveListing: 'Save a listing',
  newsletterSignUp: 'Newsletter signup',
  shareMember: 'Share member',
  saveMember: 'Save member',
  contactMember: 'Contact member',
  submitOffer: 'Submit an offer',
  requestVisit: 'Request a visit',
  acceptOffer: 'Accept an offer',
  counterOffer: 'Counter offer',
  inviteParticipant: 'Invite participants to transaction',
  assignTask: 'Assign tasks',
  uploadDocuments: 'Upload documents',
  submitRate: 'Submit a rate & review',
  attendAnEvent: 'Attend an event',
  shareAnEvent: 'Share an event',
  saveAnEvent: 'Save an event',
  knowledgeCenterContribution: 'Knowledge center contribution',
  addNewClient: 'Add new client',
  sendReferral: 'Send a referral',
  inviteNewUser: 'Invite new user to reverifi',
  vipMembership: 'VIP membership',
});

export const stageUrl = serverUrl;

export const achievementsData = {
  setMyRoles: {
    name: 'setMyRoles',
    id: 1,
    link: '/my-roles',
    text: 'Please choose your Role on the platform to update your professional profile and enjoy the full functionality of reverifi',
    buttonText: 'Set up your role',
    fontSize: '1rem',
    NotificationIcon: null,
    title: 'Your Roles',
  },
  completeProfile: {
    name: 'completeProfile',
    id: 2,
    link: '/my-profile',
    points: '40',
    text: 'Complete your profile to get',
    NotificationIcon: <ProfileIcon />,
  },
  newListing: {
    name: 'newListing',
    id: 3,
    link: '/my-listings',
    points: '80',
    text: 'Add your first listing to get',
    NotificationIcon: <ListingsIcon />,
  },
};

export const notificationTypes = {
  cancelTransaction: 'Cancel Transaction',
  claimAddress: 'Claim Address',
  closeDeal: 'Close Deal',
  contactAgent: 'Contact Agent',
  contactAgentListing: 'Contact Agent Listing',
  forgotPassword: 'Forgot Password',
  newsletterSubscribe: 'Newsletter Subscribe',
  registration: 'Registration',
  replayCounterOffer: 'Replay Counter Offer',
  replayCounterOfferSender: 'Replay Counter Offer Sender',
  review: 'Review',
  scheduleVisit: 'Schedule Visit',
  submitCounterOffer: 'Submit Counter Offer',
  submitCounterOfferSender: 'Submit Counter Offer Sender',
  submitOffer: 'Submit Offer',
  submitOfferSender: 'Submit Offer Sender',
  transactionInvitation: 'Transaction Invitation',
  transactionUserInvitation: 'Transaction User Invitation',
  transactionProcessAssign: 'Transaction Process Assign',
  transactionProcessCompleteTask: 'Transaction Process Complete Task',
  transactionStart: 'Transaction Start',
  verifyPhone: 'Verify Phone',
  clientInvitation: 'Client Invitation',
  eventRegistration: 'Event Registration',
  receiveReferral: 'Receive Referral',
  changeListingStatus: 'Change Listing Status',
  adminEventRequest: 'Admin Event Request',
  eventRequest: 'Event Request',
  replayEventRequest: 'Replay Event Request',
  presenterInvite: 'Presenter Invitation',
  presenterUserInvite: 'Presenter User Invitation',
  requestPost: 'Request Post',
  adminRequestPost: 'Admin Post Request',
  reverifiInvitation: 'Reverifi Invitation',
  adminClaimAddress: 'Admin Claim Address',
  cancelDealRequest: 'Cancel Deal Request',
  closeDealRequest: 'Close Deal Request',
  transactionMention: 'Transaction Mention',
  ownershipMovement: 'Ownership Movement',
  requestClaimAddress: 'Request Claim Address',
  acceptClaimAddress: 'Accept Claim Address',
  rejectClaimAddress: 'Reject Claim Address',
  listClaimAddress: 'List Claim Address',
  resendReverifiInvitation: 'Resend Reverifi Invitation',
  resendTransactionInvitation: 'Resend Transaction Invitation',
  adminEditClaimAddress: 'Admin Edit Claim Address',
  userEditClaimAddress: 'User Edit Claim Address',
  messages: 'Messages',
  changePassword: 'Change Password',
  changePrimaryEmail: 'Change Primary Email',
  addSecondaryEmail: 'Add Secondary Email',
  transactionMessage: 'Transaction Message',
};

export const OFFER_STATUS = {
  accepted: 'accepted',
  pending: 'pending',
  rejected: 'rejected',
};

export const listingStatus = {
  acceptedOffer: 'Accepted Offer',
  clear: 'clear',
  sold: 'Sold',
  underContract: 'Under Contract',
  attorneyReview: 'Attorney Review',
  pending: 'Pending',
};

export const claimOptions = [
  {
    value: 'Primary Residence',
  },
  { value: 'Investment' },
];

export default {
  DEFAULT_PAGE_LIMIT,
  achievementsData,
  actionTypes,
  invitationStatus,
  transactionStepsNames,
};

export const VISIT_TYPES = {
  received: 'received',
  sent: 'sent',
};

export const NOTIFICATIONS_STATUS = {
  pending: 'pending',
  sent: 'sent',
  read: 'read',
};

export const NOT_APPLICABLE = 'N/A';

export const ALL = 'all';

export const financingTypes = {
  allCash: 'All cash',
  cryptoCurrency: 'Crypto currency',
  financed: 'Financed',
};

export const underContractProcess = 'signed Initial Contract';

export const clipboardTypes = {
  email: 'email',
  text: 'text',
  phone: 'phone',
};

export const savedTypes = {
  listing: 'listing',
  event: 'event',
};

export const isAttend = {
  Attend: 'Attend',
  UnAttend: 'Unattend',
};

export const shareTypes = {
  event: 'event-details',
  listing: 'listing',
  member: 'member-details',
};

export const shareTitles = {
  event: 'Check out this event I found on reverifi.',
  listing: 'Check out this listing I found on reverifi',
  member: 'Check out this member I found on reverifi.',
};

export const clientsStatus = {
  guest: 'guest',
  registered: 'registered',
};

export const Event = {
  Upcoming: 'Upcoming',
  Past: 'Past',
  All: 'All',
  upcomingEvents: 'upcomingevents',
};

export const transactionStatusTooltip = {
  canceled: 'Canceled deal',
  closed: 'Closed deal',
  'in progress': 'In progress transaction',
};

export const categoriesIcons = {
  'Home Buying': <HomeBuying />,
  'Home Selling': <HomeSelling />,
  Investing: <Investing />,
  'Real Estate Law': <RealEstateLaw />,
  'Home Insurance': <HomeInsurance />,
  'Title Insurance': <TitleInsurance />,
  'Becoming a Real Estate Agent': <BecomeRealtor />,
  'Real Estate Agent': <RealEstateAgent />,
  'Personal Development': <PersonalDevelopment />,
  'Residential Mortgages': <Mortgage />,
  Construction: <Construction />,
};

export const eventsStatus = {
  archive: 'User Archive',
  draft: 'User Draft',
  published: 'Published',
  rejected: 'Rejected',
  pending: 'Pending',
};

export const adminEventStatus = {
  draft: 'User Draft',
  pending: 'Pending',
  published: 'Published',
  rejected: 'Rejected',
  archived: 'Archived',
};

export const processRequiredField = [
  'initial Contract',
  'signed Initial Contract',
  'proposed Closing Date',
  'confirm Closing Date',
];

export const usStates = {
  alabama: true,
  alaska: true,
  'american samoa': true,
  arizona: true,
  arkansas: true,
  california: true,
  colorado: true,
  connecticut: true,
  delaware: true,
  'district of columbia': true,
  'federated states of micronesia': true,
  florida: true,
  georgia: true,
  guam: true,
  hawaii: true,
  idaho: true,
  illinois: true,
  indiana: true,
  iowa: true,
  kansas: true,
  kentucky: true,
  louisiana: true,
  maine: true,
  'marshall islands': true,
  maryland: true,
  massachusetts: true,
  michigan: true,
  minnesota: true,
  mississippi: true,
  missouri: true,
  montana: true,
  nebraska: true,
  nevada: true,
  'new hampshire': true,
  'new jersey': true,
  'new mexico': true,
  'new york': true,
  'north carolina': true,
  'north dakota': true,
  'northern mariana islands': true,
  ohio: true,
  oklahoma: true,
  oregon: true,
  palau: true,
  pennsylvania: true,
  'puerto rico': true,
  'rhode island': true,
  'south carolina': true,
  'south dakota': true,
  tennessee: true,
  texas: true,
  utah: true,
  vermont: true,
  'virgin island': true,
  virginia: true,
  washington: true,
  'west virginia': true,
  wisconsin: true,
  wyoming: true,
};

export const statesPostal = {
  al: true,
  ak: true,
  as: true,
  az: true,
  ar: true,
  ca: true,
  co: true,
  ct: true,
  de: true,
  dc: true,
  fm: true,
  fl: true,
  ga: true,
  gu: true,
  hi: true,
  id: true,
  il: true,
  in: true,
  ia: true,
  ks: true,
  ky: true,
  la: true,
  me: true,
  mh: true,
  md: true,
  ma: true,
  mi: true,
  mn: true,
  ms: true,
  mo: true,
  mt: true,
  ne: true,
  nv: true,
  nh: true,
  nj: true,
  nm: true,
  ny: true,
  nc: true,
  nd: true,
  mp: true,
  oh: true,
  ok: true,
  or: true,
  pw: true,
  pa: true,
  pr: true,
  ri: true,
  sc: true,
  sd: true,
  tn: true,
  tx: true,
  ut: true,
  vt: true,
  vi: true,
  va: true,
  wa: true,
  wv: true,
  wi: true,
  wy: true,
};

export const newTransactionId = 'new';

export const claimAddressStatus = {
  accepted: 'Accepted',
  rejected: 'Rejected',
  pending: 'Pending',
};

export const transactionRequestType = {
  cancel: 'cancel',
  close: 'close',
};

export const transactionRequest = {
  cancel: {
    Buyer: 'buyerCancel',
    Seller: 'sellerCancel',
    'Buyer Agent': 'buyerAgentCancel',
    'Transaction Coordinator': 'coordinatorCancel',
  },
  close: {
    Buyer: 'buyerClose',
    Seller: 'sellerClose',
    'Buyer Agent': 'buyerAgentClose',
    'Transaction Coordinator': 'coordinatorClose',
  },
};

export const mentionTypes = {
  transaction: 'Transaction',
  referral: 'referral',
};
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const isListingsShown = false;

export const listingSearchVisibility = true;

export const slideVisibility = false;

export const allowedRoles = [
  transactionRoles.seller,
  transactionRoles.sellerAgent,
  transactionRoles.listingAgent,
  transactionRoles.buyer,
  transactionRoles.buyerAgent,
  transactionRoles.creator,
  transactionRoles.coordinator,
];

export const hideScheduleVisit = true;
