import DunbarLowBold from 'fonts/DunbarLow-Bold.woff';
import DunbarLowBold2 from 'fonts/DunbarLow-Bold.woff2';
import DunbarLowLight from 'fonts/DunbarLow-Light.woff';
import DunbarLowLight2 from 'fonts/DunbarLow-Light.woff2';
import DunbarText from 'fonts/DunbarText-Regular.woff';
import DunbarText2 from 'fonts/DunbarText-Regular.woff2';
import { createGlobalStyle } from 'styled-components';
import colors from './colors';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

  @font-face {
    font-family: 'Dunbar';
    src:url(${DunbarLowLight2}) format('woff2'),
    url(${DunbarLowLight}) format('woff'); 
  }

  @font-face {
    font-family: 'Dunbar-bold';
    src:url(${DunbarLowBold2}) format('woff2'),
    url(${DunbarLowBold}) format('woff'); 
  }
  
  @font-face {
    font-family: 'Dunbar-text';
    src:url(${DunbarText2}) format('woff2'),
    url(${DunbarText}) format('woff'); 
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: "Montserrat", Helvetica, sans-serif;
    color: ${colors.mineShaft};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 62.5rem ${colors.white} inset;
    -moz-box-shadow: 0 0 0 6.25rem ${colors.white} inset;
    box-shadow: 0 0 0 6.25rem ${colors.white} inset;
  }

  option{
      background-color:${colors.white} !important;
  }
`;

export default GlobalStyles;
