import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';
import { ReactComponent as arrowDown } from 'assets/green-down-arrow.svg';

export const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  background-color: ${colors.alabaster};
  box-shadow: 0rem 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'translateY(92%)' : 'translateY(0)'};
  transition: transform 0.3s ease-in-out;
  height: 100%;
  width: 100%;

  ${mq.tablet`
    right: 2rem;
    width: 22rem;
    height: auto;
    bottom: 1rem;
    border-radius: 2.25rem;

    transform: ${({ isCollapsed }) =>
      isCollapsed ? 'translateY(89%)' : 'translateY(0)'};
  `}
`;

export const UpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  min-height: 4rem;
  width: 80%;

  svg path {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: ${colors.black};
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
`;

export const DownArrow = styled(arrowDown)`
  width: 0.91rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
  stroke-width: 0.2rem;

  ${({ isCollapsed }) => isCollapsed && `display: none;`}
`;

export const UpArrow = styled(arrowDown)`
  width: 0.91rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
  transform: rotate(180deg);
  stroke-width: 0.2rem;

  ${({ isCollapsed }) => !isCollapsed && `display: none;`}
`;

export const ArrowsWrapper = styled.div`
  svg path {
    stroke: ${colors.lightGreen};
  }
`;

export const NewChatAndArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  cursor: pointer;
  padding: 0.1rem;
`;

export const NewMessageIconsWrapper = styled.div`
  svg path {
    stroke-width: 0.13rem;
  }
`;

export const CloseIconsWrapper = styled.div`
  svg path {
    stroke-width: 0.15rem;
  }
`;

export const Chats = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.coolGrey};
  font-size: 0.88rem;
  font-weight: 600;
  cursor: pointer;
  width: 50%;
  border-bottom: 0.06rem solid ${colors.midGray};
  padding-bottom: 1rem;
  padding-left: 3rem;
  gap: 0.3rem;

  svg {
    path {
      stroke: ${colors.coolGrey};
      fill: ${colors.coolGrey};
    }
  }

  ${({ isActive }) =>
    isActive &&
    `border-bottom: 0.15rem solid ${colors.lightGreen};color: ${colors.lightGreen};
    svg {
      path {
        stroke: ${colors.lightGreen};
        fill: ${colors.lightGreen};
      }
    }`}

  ${mq.tablet`
    width: 11rem;
  `}
`;

export const Referrals = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.coolGrey};
  font-size: 0.88rem;
  font-weight: 500;
  cursor: pointer;
  width: 50%;
  border-bottom: 0.06rem solid ${colors.midGray};
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  gap: 0.3rem;

  svg {
    path {
      stroke: ${colors.coolGrey};
    }
  }

  ${({ isActive }) =>
    isActive &&
    `border-bottom: 0.15rem solid ${colors.lightGreen};color: ${colors.lightGreen};
    svg {
      path {
        stroke: ${colors.lightGreen};
      }
    }`}

  ${mq.tablet`
    width: 11rem;
  `}
`;

export const ChatsAndReferralsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 3rem;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NewMessageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  border-radius: 1.06rem;
  background-color: ${colors.lightGreen};
  margin-bottom: 1rem;

  ${mq.tablet`
    width: 20rem;
    height: 24.5rem;
  `}
`;

export const ListedUsersWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: 0.0625rem solid ${colors.mercury};
  cursor: pointer;
  height: 3.5rem;
  border-radius: 0.94rem;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  font-size: 0.85rem;
  font-weight: 600;

  &:hover {
    background-color: ${colors.veryLightGreen};
  }
`;

export const MessagesContainer = styled.div`
  margin-top: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;
