import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

export const isSelectedContext = createContext();

isSelectedContext.displayName = 'isSelectedContext';

/**
 * Used to share data from search in header to the search in the bottom of the page.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function IsLocationSelected({ children }) {
  const [isSelected, setIsSelected] = useState(null);

  const value = useMemo(
    () => ({
      isSelected,
      setIsSelected,
    }),
    [isSelected]
  );

  return (
    <isSelectedContext.Provider value={value}>
      {children}
    </isSelectedContext.Provider>
  );
}

IsLocationSelected.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLocationSelect = () => useContext(isSelectedContext);

export default IsLocationSelected;
