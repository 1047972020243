import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Add emil to mail chimp.
 *
 * @param {Object} body  to send with the request
 *
 */
const addEmailToMailChimp = async (body) => {
  const { data } = await http.post(`${apiUrl}/mail-chimp`, body);

  return data;
};

export default addEmailToMailChimp;
