import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * get plans by role
 *
 * @param {string} plans role.
 *
 * @return {Promise<Object>} plans data response.
 */
export const getPlansByRole = async (role) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/plans?role=${role}`);

  return data;
};

/**
 * get plan by id
 *
 * @param {number} id plan_id.
 *
 * @return {Promise<Object>} plan data response.
 */

export const getPlanById = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/plans/${id}`);

  return data;
};
