import PropTypes from 'prop-types';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'styles/colors';
import { SpinnerContainer } from './loading-spinner.styles';

/**
 * Loading Spinner component
 *
 * @param {Number} size  loader size
 * @param {Boolean} loading loading state
 * @param {String} margin loader margin
 * @param {String} align loader alignment
 * @param {String} type loader type (clip, beat)
 *
 * @returns {JSX.Element}
 */
function LoadingSpinner({ size, loading, margin, align, type }) {
  return (
    <SpinnerContainer margin={margin} align={align}>
      {type?.toLowerCase() === 'beat' ? (
        <BeatLoader color={colors.green} loading={loading} size={size} />
      ) : (
        <ClipLoader color={colors.green} loading={loading} size={size} />
      )}
    </SpinnerContainer>
  );
}

LoadingSpinner.defaultProps = {
  align: null,
  margin: null,
  size: 30,
  type: 'clip',
};

LoadingSpinner.propTypes = {
  align: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  margin: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['beat', 'clip']),
};

export default LoadingSpinner;
