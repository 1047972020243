/* eslint-disable import/prefer-default-export */
import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Get redeem product.
 *
 * @param {Object} params contains planId & redeemProductId
 *
 * @return {Promise<Object>} response.
 */
export const getRedeemProduct = async (params) => {
  const { redeemProductId, planId } = params;
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/redeem-products/?redeemProductId=${redeemProductId}&planId=${planId}`
  );
  return data;
};
