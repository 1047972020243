import styled from 'styled-components';
import mq from 'styles/media-query';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  gap: 0.5rem;
  border-radius: 0.94rem;

  ${({ isOpenMessage }) =>
    isOpenMessage &&
    `
    overflow: hidden;
  `};

  ${mq.tablet`
    height: 17.65rem;
    width: 20rem;
  `}
`;

export const UserWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border-bottom: 0.0625rem solid ${colors.mercury};
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.94rem;
  background: rgba(135, 141, 168, 0.09);

  &:hover {
    background-color: rgba(183, 207, 83, 0.15);
  }

  ${({ isChatOpen }) =>
    isChatOpen &&
    `
      background-color: rgba(183, 207, 83, 0.15);
  `};
`;

export const StyledImg = styled.img`
  border-radius: 50%;
  object-fit: ${({ defaultImg }) => (defaultImg ? 'fit-content' : 'cover')};
  height: 2.75rem;
  width: 2.75rem;
  min-height: 2.75rem;
  min-width: 2.75rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const Username = styled.p`
  font-weight: 600;
  font-size: 0.75rem;
  margin: 0;
`;

export const MessageTime = styled.span`
  color: ${colors.gray};
  font-size: 0.7rem;
`;

export const LastMessage = styled.span`
  display: block;
  color: ${colors.mineShaft};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.5rem;
  color: rgba(51, 51, 51, 0.6);
  font-size: 0.8rem;
  font-weight: 400;
`;

export const NoMessages = styled.p`
  color: ${colors.dustyGray};
  font-size: 1.2rem;
  font-weight: 600;
  margin: auto;
  text-align: center;
`;

export const UnReadMessage = styled.span`
  border-radius: 50%;
  background-color: ${colors.green};
  position: absolute;
  right: 0;
  height: 0.5rem;
  width: 0.5rem;
  top: 0;
  margin-top: 1rem;

  ${({ isRead }) => isRead && `display: none`};
`;

export const DateDesign = styled.div`
  border-radius: 50%;
  background-color: ${colors.gray};
  height: 0.3rem;
  width: 0.3rem;
`;

export const DateDesignWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
`;
