import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * get all user action types.
 *
 * @param {string} userId User id.
 *
 * @return {Promise<Object>} Property types response.
 */
export const getUserActionTypes = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-action-types/`);

  return data;
};

/**
 * get action type by id.
 *
 * @param {string} id Action type id.
 *
 * @return {Promise<Object>} Property types response.
 */
export const getActionTypeById = async (id) => {
  const { data } = await http.get(`${apiUrl}/action-types/${id}`);

  return data;
};

/**
 * Add user action type.
 *
 * @return {Promise<Object>} submitted response.
 */
export const addUserActionType = async (actionTypeData) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/user-action-types`, actionTypeData);

  return data;
};

/**
 * get all action types.
 *
 * @return {Promise<Object>} Property types response.
 */
export const getAllActionTypes = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/action-types`);

  return data;
};

/**
 * get all user achievements.
 *
 * @return {Promise<Array>} Property types response.
 */
export const getAllUserAchievements = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/achievements`);

  return data;
};

/**
 * get all leaderboard data.
 *
 * @return {Promise<Object>} Property types response.
 */
export const getLeaderboard = async (month, year) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/leaderboard?month=${month}&year=${year}`);

  return data;
};
