import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';
import { ReactComponent as ChevronDown } from 'assets/images/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/images/chevron-up.svg';

export const DownArrow = styled(ChevronDown)`
  ${({ isCollapsedSubMenu }) => isCollapsedSubMenu && `display: none;`}
`;

export const UpArrow = styled(ChevronUp)`
  ${({ isCollapsedSubMenu }) => !isCollapsedSubMenu && `display: none;`}
`;

export const StyledBurger = styled.div`
  height: ${({ open }) => (open ? '1.6rem' : '1.5rem')};
  z-index: 20;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  position: absolute;
  left: 0;

  ${mq.tablet`
    display: flex;
  `}

  ${mq.desktopWide`
    position:static;
  `}

  div {
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const DivComponent = styled.div`
  width: 1.6rem;
  height: 0.1rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  transform-origin: 0.0625rem;
  transition: all 0.2s linear;

  ${mq.desktopWide`
      ${({ isUserLoggedIn }) => !isUserLoggedIn && `display: none;`}
  `}
`;

export const LineSeparator = styled.hr`
  margin: 0;
  height: 0.125rem;
  min-height: 0.125rem;
  background-color: ${colors.mercury};
  border: none;

  ${({ isUserLoggedIn }) => !isUserLoggedIn && `display: none;`}
`;

export const TopMenu = styled.div`
  ${({ isUserLoggedIn }) => !isUserLoggedIn && `display: none;`}
`;

export const Ul = styled.div`
  list-style: none;
  display: none;
  flex-flow: column nowrap;
  background-color: ${colors.mineShaft};
  position: absolute;
  transform: ${({ open }) =>
    open ? 'translateX(0)' : 'translateX(-18.75rem)'};
  top: 5.15rem;
  left: 0;
  margin-left: -1rem;
  height: 100vh;

  overflow: auto;
  width: 17rem;
  padding: 1rem 0rem 0rem 0.5rem;
  transition: transform 0.3s ease-in-out;

  ${mq.tablet`
    display: flex;
  `}

  ${mq.desktopWide`
    margin-left: unset;
  `}
`;

export const LinkTitle = styled.p`
  margin-left: 1.8rem;
  overflow: hidden;
  font-size: 1rem;

  ${({ isCollapsedSubMenu }) => isCollapsedSubMenu && `color: ${colors.green};`}
`;

export const MenuLink = styled(NavLink)`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 3rem;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${colors.green};
    svg path {
      ${({ needStrokeColor }) => needStrokeColor && `fill: ${colors.green}`};
      ${({ needStrokeColor }) => needStrokeColor && `stroke: ${colors.green}`};
    }
  }

  &.active {
    ${({ isCollapsed }) =>
      !isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ isCollapsed }) => !isCollapsed && `border-radius: 0;`}
    ${({ isCollapsed }) => isCollapsed && `background-color: ${colors.green};`}
    ${({ isCollapsed }) => isCollapsed && `margin-right:1rem;`}
    ${({ isCollapsedSubMenu }) =>
      isCollapsedSubMenu &&
      `border-right: 0.35rem ${colors.mineShaft} solid; color: ${colors.green};`}
    ${({ isCollapsedSubMenu }) =>
      !isCollapsedSubMenu &&
      `border-right: 0.35rem ${colors.mineShaft} solid; color: ${colors.white};`}
  }

  ${mq.tablet`
  svg{ 
    width: 1.25rem;
    height: 1.25rem;
  }
  `}

  ${mq.desktopExtraWide`
  svg{ 
    width: 1.5rem;
    height: 1.5rem;
  }
 `}
`;

export const BottomMenu = styled.div`
  ${LinkTitle} {
    margin-left: 0;
  }

  ${MenuLink} {
    justify-content: space-between;

    ${UpArrow} {
      fill: ${colors.mineShaft};
      stroke: ${colors.white};
    }
  }

  ${mq.desktopWide`
      display: none;
  `}
`;

export const MessagesCounter = styled.span`
  font-size: 0.6rem;
  font-weight: bold;
  padding: ${({ messageCount }) =>
    messageCount > 99
      ? '0.75rem 0.531rem'
      : messageCount >= 10
      ? '0.594rem 0.5625rem'
      : '0.531rem 0.5625rem'};
  border-radius: 50%;
  background-color: ${colors.red};
  color: ${colors.white};
`;

export const MessagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
