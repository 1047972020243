import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Add a file
 *
 * @param {Object} file to send upload
 *
 * @return {Promise<Object>} response.
 */
const singleFileUpload = async ({
  file,
  onError = (er) => er.message,
  onSuccess = (data) => data.data.publicUrl,
  path,
}) => {
  const formData = new FormData();
  formData.append('file', file[0]);

  return http
    .post(`${apiUrl}/upload/file${path || ''}`, formData)
    .then((response) => onSuccess(response))
    .catch((error) => onError(error));
};

export default singleFileUpload;
