import React, { useCallback, useEffect, useState } from 'react';
import AgentPhoto from 'assets/icons/agent-list-avatar-placeholder.svg';
import { getUsersWithLimit } from 'services/user';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useMessages } from 'contexts/MessagesContext';
import { useUser } from 'contexts/UserContext';
import { useNotification } from 'contexts/NotificationSocketContext';
import FormInput from 'components/shared/FormInput';
import useEffectOnce from 'hooks/use-effect-once';
import { getMyPrivateMessages } from 'services/private-messages';
import { toUpperCaseFirstLetter, getTimeInterval } from 'utils/helpers';
import {
  DetailsWrapper,
  LastMessage,
  MessageTime,
  NoMessages,
  StyledImg,
  UnReadMessage,
  Username,
  UserWrapper,
  Wrapper,
  MessageWrapper,
  DateDesign,
  DateDesignWrapper,
  Container,
} from './chat-list.style';

/**
 * Chat List component
 * @return {JSX.Element}
 */
function ChatList() {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [chatOpenedId, setChatOpenId] = useState('');
  const { userInfo, isLoggedIn } = useUser();
  const { socket } = useNotification();
  const {
    setOpenedChatId,
    setIsMessageOpen,
    isMessageOpen,
    isMessageUpdated,
    isNewMessageSent,
    getUnreadMessages,
    setNewMessageReceiverId,
    setIsNewMessageSent,
    unreadMessagesCount,
  } = useMessages();

  const fetchMessages = useCallback(async () => {
    const data = (await getMyPrivateMessages())?.sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) -
        new Date(a?.lastMessage?.createdAt)
    );
    setMessages(data);

    setIsLoading(false);
  }, []);

  const initialMessagesFetch = async () => {
    const data = (await getMyPrivateMessages())?.sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) -
        new Date(a?.lastMessage?.createdAt)
    );

    if (data?.length) {
      setChatOpenId(data?.[0]?.receiver?.id);
      setOpenedChatId(data?.[0]?.receiver?.id);
    }

    setMessages(data);
    getUnreadMessages();
    setIsLoading(false);
  };

  const handleInputChange = async (e) => {
    const userName = e.target.value;
    if (userName) {
      const users = await getUsersWithLimit(10, userName);
      const allUsers = users.map((user) => user?.id);
      const usersMessages = messages.map((user) => user?.receiver?.id);

      const commonUsers = allUsers.filter((item) =>
        usersMessages.includes(item)
      );

      const filteredUsersMessages = messages.filter((message) =>
        commonUsers.includes(message.receiver.id)
      );

      setFilteredMessages(filteredUsersMessages);
    } else {
      setFilteredMessages();
    }
  };

  useEffectOnce(() => {
    initialMessagesFetch();
  });

  useEffect(() => {
    fetchMessages();
    setIsNewMessageSent(false);
  }, [
    isMessageUpdated,
    isNewMessageSent,
    setIsNewMessageSent,
    unreadMessagesCount,
    fetchMessages,
  ]);

  useEffect(() => {
    if (!isLoggedIn) return;
    socket?.on(`new_message`, () => {
      fetchMessages();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return isLoading ? (
    <LoadingSpinner
      size={100}
      loading={isLoading}
      margin="7.5rem auto"
      align="center"
    />
  ) : (
    <>
      <FormInput
        name="userName"
        placeholder="Search"
        onChange={handleInputChange}
      />

      <Wrapper isOpenMessage={isMessageOpen}>
        <Container>
          {(filteredMessages || messages)?.length > 0 ? (
            (filteredMessages || messages).map((user) => (
              <UserWrapper
                isChatOpen={chatOpenedId === user?.receiver?.id}
                key={user?.receiver?.id}
                onClick={() => {
                  setIsMessageOpen(true);
                  setChatOpenId(user?.receiver?.id);
                  setOpenedChatId(user?.receiver?.id);
                  setNewMessageReceiverId();
                  fetchMessages();
                }}
              >
                <StyledImg
                  defaultImg={
                    !user?.receiver?.userInfo?.thumbnailImage ||
                    !user?.receiver?.userInfo?.image
                  }
                  src={
                    user?.receiver?.userInfo?.thumbnailImage ||
                    user?.receiver?.userInfo?.image ||
                    AgentPhoto
                  }
                />
                <DetailsWrapper>
                  <>
                    <Username>
                      {toUpperCaseFirstLetter(user?.receiver?.name)}
                    </Username>
                    <MessageWrapper>
                      {user?.lastMessage && (
                        <LastMessage>{user?.lastMessage?.content}</LastMessage>
                      )}
                      <DateDesignWrapper>
                        <DateDesign />
                        <MessageTime>
                          {getTimeInterval(user?.lastMessage?.createdAt)}
                        </MessageTime>
                      </DateDesignWrapper>
                    </MessageWrapper>
                    {userInfo && (
                      <UnReadMessage
                        isRead={
                          user?.lastMessage?.isRead ||
                          user?.lastMessage?.createdBy === userInfo?.id
                        }
                      />
                    )}
                  </>
                </DetailsWrapper>
              </UserWrapper>
            ))
          ) : (
            <NoMessages>No results found</NoMessages>
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default ChatList;
