import { ReactComponent as Logo } from 'assets/header-logo-R.svg';
import React from 'react';
import { InfoGroup, LogoWrapper } from './footer.styles';

function SupportInfo() {
  return (
    <InfoGroup>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </InfoGroup>
  );
}

export default SupportInfo;
