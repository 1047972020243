/* eslint-disable import/prefer-default-export */
import { apiUrl } from 'config/config';
import http from 'utils/http';

/**
 * Send Private Message
 *
 * @param {Object} body body to send with the request
 *
 * @return {Promise<Object>} The new message sended.
 */
export const sendPrivetMessage = async (body) => {
  const {
    data: { data },
  } = await http.post(`${apiUrl}/messages/private`, body);

  return data;
};

/**
 * Get My Private Messages
 *
 * @return {Promise<Object>} The get all my private messages response.
 */
export const getMyPrivateMessages = async () => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/messages/private`);

  return data;
};

/**
 * Get Messages By Id
 *
 * @param {String}  id  User Id.
 *
 * @return {Promise<Object>} get all private messages by id response.
 */
export const getMessagesById = async (receiverId) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/messages/private/${receiverId}`);

  return data;
};
